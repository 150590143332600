import React, {Fragment, useEffect, useState} from "react";
import HTTPClient from "../../HTTPClient";
import Header from "../Header";
import Loading from "../Loading";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import MeetingTitle from "./MeetingTitle";
import {getMessage} from "../../util";
import Footer from "../Footer";
import * as axios from "axios";
import Container from "@material-ui/core/Container";
import {makeStyles} from "@material-ui/core/styles";


const useStyles = makeStyles(theme => ({
    paper: {
        marginTop: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    paragraph: {
        margin: theme.spacing(2)
    }
}));

const loadMe = async (meeting_id) => {

    try {
        await HTTPClient({
            method: "get",
            url:
                process.env.REACT_APP_API_URL +
                "/meetings/" + meeting_id + "/me"
        })
        window.location = '/meeting/' + meeting_id;
    } catch (error) {
        switch (error.response.status) {
            case 401:
                window.location = '/login/' + meeting_id;
                break;
            case 403:
                return(error.response.data);
            default:
                throw (error);
        }
    }
}

const PendingManualApprove = (props) => {
    const [loading, setLoading] = useState(true);
    const [meeting, setMeeting] = useState(null);
    const meeting_id = props.match.params.meeting_id;
    const [representative, setRepresentative] = useState(null);
    const [messages, setMessages] = useState(null);
    const resetLanguage = (event) => {
        localStorage.setItem('eholder-lang-'+meeting_id, event.target.value);
        messages.lang = event.target.value;
        setMessages({...messages});
    }


    // effect only once
    useEffect( () => {
        (async () => {
            try {

                console.log( process.env.REACT_APP_API_URL +
                    "/meetings/" + meeting_id + "/pending");

                // load the meeting the first time
                const meeting = await HTTPClient({
                    method: "get",
                    url:
                        process.env.REACT_APP_API_URL +
                        "/meetings/" + meeting_id + "/pending"
                });



                // load messages
                if (meeting.data.messages) {
                    const load_messages = await axios({
                        method: "get",
                        url: meeting.data.messages
                    });
                    const messages = load_messages.data;
                    const nav_lang = navigator.language.split('-')[0];

                    messages.lang = messages.languages.length > 1 ? (localStorage.getItem('eholder-lang-' + meeting_id) || nav_lang) : nav_lang;
                    setMessages(messages);                }

                setMeeting(meeting.data);

                setRepresentative(await loadMe(meeting_id));

                setLoading(false);

                const intervalId = setInterval(() => {
                    (async () => {
                        setRepresentative(await loadMe(meeting_id));
                    })();
                }, 3000);
                return (() => {
                    clearInterval(intervalId);
                });


            } catch(error) {
                console.log(error);
                switch (error.response.status) {
                    case 401:
                        window.location = '/login/' + meeting_id;
                        break;
                    default:
                        setLoading(false);
                        break;
                }
            }
        })();
    }, [meeting_id]);

    const classes = useStyles();

    return (
        <Fragment>
            {loading ? (
                <Loading/>
            ) : ((meeting && representative ) ? (
                    <Fragment>
                        <Header messages={messages} user={representative} logo={meeting.logo} meeting={meeting} shares={[]}  resetLanguage={resetLanguage}/>
                        <hr/>

                        <MeetingTitle messages={messages} meeting={meeting}/>




                        {(meeting.video_conf_url || representative.video_conf_url) ? (
                            <Container className={classes.paper}>
                                <Typography>
                                    {getMessage(meeting.meeting_type,'manual_verify_pending_videoconf',messages)}
                                </Typography>
                                <Button variant="contained" href={representative.video_conf_url?representative.video_conf_url:meeting.video_conf_url} color="primary" className={classes.paragraph}  target="_blank" rel="noopener">
                                    {getMessage(meeting.meeting_type,'videoconference_button',messages)}
                                </Button>
                            </Container>                        ):(
                            <Typography>
                                {getMessage(meeting.meeting_type,'manual_verify_pending',messages)}
                            </Typography>
                        )}

                        <Footer messages={messages} meeting={meeting}/>

                    </Fragment>
                ) : (
                    <Typography variant="h5" component="h2">
                        {getMessage('default','meeting_not_found',null)}
                    </Typography>
                )

            )}


        </Fragment>

    );
}

export default PendingManualApprove;