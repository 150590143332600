import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import React, {useState} from "react";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Grid from "@material-ui/core/Grid";
import HTTPClient from "../../HTTPClient";
import {getMessage} from "../../util";

const useStyles = makeStyles((theme) => ({
    root: {
        '& > *': {
            margin: theme.spacing(4),
            width: '25ch',
        },
    },
    paper: {
        margin: theme.spacing(4),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    }
}));


const AskPhone = (props) => {
    const classes = useStyles();
    const [phone, setPhone] = useState('ok');

    const handleInputChange = event => {
        setPhone(event.target.value);
    };

    const sendPhone = (event) => {
        event.preventDefault();


        const connect = (data) => HTTPClient({
            method: "post",
            data: data,
            url:
                process.env.REACT_APP_API_URL +
                "/login/send_phone"
        })
            .then(response => {
                alert(getMessage(props.meeting.meeting_type, 'askphone_sent_ok', props.messages))
                props.handleClose();

            })
            .catch((error) => {
                alert(getMessage(props.meeting.meeting_type, 'askphone_sent_fail', props.messages));
                props.handleClose();

            });

        if (process.env.REACT_APP_RECAPTCHA_ENABLED !== "false") {
            window.grecaptcha.enterprise.ready(async () => {
                window.grecaptcha.enterprise.execute(process.env.REACT_APP_RECAPTCHA_SITE, {action: 'id_password'}).then(token => {
                    const data = {
                        jwt: props.jwt,
                        reason: props.reason,
                        phone: phone,
                        gRecaptchaResponse: token
                    }
                    connect(data);
                })
            })
        } else {
            const data = {
                jwt: props.jwt,
                reason: props.reason,
                phone: phone
            }
            connect(data);
        }
    }

    return (<Dialog
        open={true}
        scroll="body"
        onClose={props.handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
    >
        <DialogContent>
            <Typography id="alert-dialog-description">
                {props.my_mistake ? getMessage(props.meeting.meeting_type, 'askphone_we_cannot', props.messages) : getMessage(props.meeting.meeting_type, 'askphone_user_cannot', props.messages)}
            </Typography>

            <Grid container spacing={1}>
                <Grid item xs={12} sm={6} className={classes.root}>
                    <TextField id="outlined-basic"
                               label={getMessage(props.meeting.meeting_type, 'askphone_label', props.messages)}
                               variant="outlined" onChange={handleInputChange}/>
                </Grid>
                <Grid item xs={12} sm={6} className={classes.root}>
                    <Button onClick={sendPhone} variant="contained" color="primary" autoFocus>
                        {getMessage(props.meeting.meeting_type, 'askphone_button', props.messages)}
                    </Button>
                </Grid>
            </Grid>

            <Typography id="alert-dialog-description">
                {getMessage(props.meeting.meeting_type, 'askphone_alternative', props.messages)}
            </Typography>
        </DialogContent>
        <DialogActions>
            <Button variant="contained" onClick={props.handleClose} color="primary" autoFocus>
                {getMessage(props.meeting.meeting_type, 'close', props.messages)}
            </Button>
        </DialogActions>
    </Dialog>)
}

export default AskPhone;
