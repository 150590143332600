import React, {Fragment} from "react";
import {makeStyles} from "@material-ui/core/styles";

import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";

import List from "@material-ui/core/List";
import QuestionItem from "../question/QuestionItem";
import VideoConference from "./VideoConferece";
import {getMessage} from "../../util";
import ExternalQuestionItem from "../question/ExternalQuestionItem";


const useStyles = makeStyles(theme => ({
        paper: {
            marginTop: theme.spacing(4),
            marginBottom: theme.spacing(2),
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'left',
        },
        paragraph: {
            margin: theme.spacing(2)
        },
        cardGrid: {
            paddingTop: theme.spacing(8),
            paddingBottom: theme.spacing(8),
        },
        card: {
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
        },
        cardActions: {
            justifyContent: 'center',
        },
        logo: {
            width: '100%',
            maxWidth: '200px',
            display: 'block'
        },
        cardContent: {
            flexGrow: 1,
        }
    }
));

const MeetingOpen = (props) => {
    const classes = useStyles();

    return (
        <Fragment>
            <Container>
                <Typography className={classes.paragraph}>
                    {getMessage(props.meeting.meeting_type,'meeting_opened',props.messages)}
                </Typography>
                {(props.meeting.video_conf_url || props.user.video_conf_url) && (
                    <VideoConference messages={props.messages} url={props.user.video_conf_url?props.user.video_conf_url:props.meeting.video_conf_url} meeting={props.meeting} />
                )}
                <Typography variant={"h5"} className={classes.paragraph}>
                    {getMessage(props.meeting.meeting_type,'matters_to_vote',props.messages)}
                </Typography>
                <List>
                    {props.meeting.questions.sort((a,b) => a.order - b.order).map(q => (q.url?(
                            <ExternalQuestionItem messages={props.messages} key={q.slug} question={q} meeting={props.meeting} setQuestion={props.setQuestion}/>

                        ):(
                            <QuestionItem messages={props.messages} key={q.slug} question={q} meeting={props.meeting} setQuestion={props.setQuestion}/>
                        )

                    ))}
                </List>

            </Container>
        </Fragment>)
}
export default MeetingOpen;