import React, {Component, Fragment} from "react";
import {TableCell} from "@material-ui/core";
import TableRow from "@material-ui/core/TableRow";

class NoVotedItem extends Component {
    
    render() {
        const ColSpan = this.props.question.nullable ? 2 : 1;

        return (
            <TableRow>
                <TableCell>
                    {this.props.representative.identity}
                </TableCell>
                <TableCell>
                    {this.props.representative.name ? this.props.representative.name : 'no name'}
                </TableCell>
                <TableCell>
                    {this.props.representative.represented_by.name ? this.props.representative.represented_by.name : 'no name'} {(this.props.representative.represented_by.identity)}
                </TableCell>

                <Fragment>
                    <TableCell colSpan={this.props.question.options.length + ColSpan}>
                        No votó
                    </TableCell>

                </Fragment>


            </TableRow>
        );
    }
}


export default NoVotedItem;