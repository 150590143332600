import React, {useEffect} from "react";
import {makeStyles} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import VotedDistributed from "./VotedDistributed";
import OptionsDistributed from "./OptionsDistributed";
import OptionsSingle from "./OptionsSingle";
import VotedSingle from "./VotedSingle";
import QuestionForbidden from "./QuestionForbidden";
import Button from "@material-ui/core/Button";
import config from "../../config.json";
import Box from "@material-ui/core/Box";
import {getMessage} from "../../util";
import HTTPClient from "../../HTTPClient";

const useStyles = makeStyles(theme => ({
        paper: {
            marginTop: theme.spacing(4),
            marginBottom: theme.spacing(2),
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'left',
        },
        number: {
            fontFamily: "monospace"
        },
        voted: {
            marginTop: theme.spacing(4),
        },
        paragraph: {
            margin: theme.spacing(2)
        },
        cardGrid: {
            paddingTop: theme.spacing(8),
            paddingBottom: theme.spacing(8),
        },
        card: {
            marginTop: theme.spacing(4),
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
        },
        cardActions: {
            justifyContent: 'center',
        },
        cardContent: {
            flexGrow: 1,
        },
        larger: {
            "font-size": "1rem"
        },
        option: {
            margin: theme.spacing(3),
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',

        },
        label: {
            alignItems: 'left',
        },
        textField: {
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(1),
            width: '25ch',
        },

    }
));


const sep = (() => {
    const n = 1.1;
    return (n.toLocaleString(navigator.language).substring(1, 2));
})();


const loadQuestion = async (meeting_id, question_id) => {
    const question = await HTTPClient({
        method: "get",
        url:
            process.env.REACT_APP_API_URL +
            "/meetings/" + meeting_id + "/questions/" + question_id
    });
    return (question.data);
}

const QuestionOpen = (props) => {


    const [question, setQuestion] = React.useState(props.question);
    const question_shares = Object.keys(question.blanks)
    const holders = props.holders ? props.holders.filter(h => {
        return (Object.keys(h.shares).some(s => question_shares.includes(s)));
    }) : [];


    // effect only once
    useEffect(() => {
        // I'll be checking if the meeting changed, i will when questions are opened or closed
        const intervalId = setInterval(() => {
            (async () => {
                try {
                    const question = await loadQuestion(props.meeting.slug, props.question.slug);
                    setQuestion(question);

                } catch (error) {
                    switch (error.response.status) {
                        case 401:
                            window.location = '/login/' + props.meeting.slug;
                            break;
                        case 403:
                            window.location = '/meeting/' + props.meeting.slug + '/pending';
                            break;

                        default:
                            break;
                    }
                }
            })();
        }, 2000);

        return (() => {
            clearInterval(intervalId);
        });

    }, [props.meeting.slug,props.question.slug]);


    const classes = useStyles();

    // const answers = null; // representative ? representative.answers[props.question.slug] : null

    const answers = holders ? holders.filter(h => h.answers[props.question.slug]) : null;

    const turnout = Object.keys(question.turnout).map(k => {
        return (props.meeting.shares[k].rights * question.turnout[k].shares);
    }).reduce((acc, elem) => {
        return (acc + elem);
    }, 0);


    const distributed = (holders.reduce((acc, holder) => {
        return (acc || holder.custodian);
    }, false) || props.question.config.distributed)


    let nines = "99".concat(sep);
    for (let i = 0; i < config.precision; i++) {
        nines = nines.concat("9")
    }
    nines = nines.concat("...")

    return (
        <Container className={classes.voted}>

            <Box mb={2}>
                <Typography variant={"h5"}>
                    {props.question.name}
                </Typography>
                <Typography>
                    {getMessage(props.meeting.meeting_type, 'voting_in_process', props.messages)} {props.user.observer && (props.goDetails ? (
                    <Button size="small" variant="outlined" color={"default"}
                            onClick={props.goDetails}>
                        {getMessage(props.meeting.meeting_type, 'watch_voting_detail', props.messages)}</Button>
                ) : (<Button variant="contained" color={"default"}
                             href={"/meeting/" + props.meeting.slug + "/question/" + props.question.slug + "/answers"}>
                    {getMessage(props.meeting.meeting_type, 'watch_voting_detail', props.messages)}</Button>))}
                </Typography>
            </Box>



            {(holders && holders.length > 0 && answers && answers.length === holders.length) ? (distributed ? (
                    <VotedDistributed messages={props.messages} question={question} answers={answers}
                                      shares={props.shares}
                                      user={props.user}
                                      holders={holders}
                                      meeting={props.meeting}/>

                ) : (
                    <VotedSingle messages={props.messages} question={question} answers={answers}
                                 user={props.user}
                                 meeting={props.meeting}
                                 shares={props.shares}
                                 holders={holders}/>

                )
            ) : ((holders && holders.length > 0) ? (distributed ? (
                    <OptionsDistributed meeting={props.meeting}
                                        messages={props.messages}
                                        question={question}
                                        user={props.user}
                                        holders={holders}
                                        shares={props.shares}
                                        onVote={props.onVote}

                    />
                ) : (<OptionsSingle meeting={props.meeting}
                                    messages={props.messages}
                                    question={question}
                                    user={props.user}
                                    shares={props.shares}
                                    holders={holders}
                                    onVote={props.onVote}
                    />
                )) : (
                    <QuestionForbidden meeting={props.meeting}
                                       messages={props.messages}
                                       question={question}
                                       user={props.user}
                    />
                )
            )}

        </Container>
    )
}

export default QuestionOpen;