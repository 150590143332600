import React, {Component} from "react";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import TableHead from "@material-ui/core/TableHead";
import Table from "@material-ui/core/Table";
import TableContainer from "@material-ui/core/TableContainer";
import TableBody from "@material-ui/core/TableBody";
import AnswerStateItem from "./AnswerStateItem";
import {getMessage} from "../../util";
import NoVotedStateItem from "./NoVotedStateItem";

class AnswersStateList extends Component {


    render() {
        const question_shares = Object.keys(this.props.question.blanks);

        const getRepresentative = (representative) => {
            const common_shares = Object.keys(representative.shares).filter((x) => {
                if (question_shares.indexOf(x) === -1)
                    return false;
                else
                    return true;
            });

            return (<TableRow key={representative.identity}>
                <AnswerStateItem
                    representative={representative}
                    meeting={this.props.meeting}
                    question={this.props.question}
                    common_shares={common_shares}
                />
            </TableRow>);

        }

        return (
            <TableContainer>
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>{getMessage(this.props.meeting.meeting_type, 'search_id', this.props.messages)}</TableCell>
                            <TableCell>{getMessage(this.props.meeting.meeting_type, 'search_name', this.props.messages)}</TableCell>
                            <TableCell>{getMessage(this.props.meeting.meeting_type, 'represented_by', this.props.messages)}</TableCell>
                            <TableCell>{getMessage(this.props.meeting.meeting_type, 'vote_state', this.props.messages)}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {Object.keys(this.props.result.holders).map(identity => (
                            getRepresentative(this.props.result.holders[identity])
                        ))}
                        {this.props.question.state === 'finished' && this.props.question.unvoted_list.map(h => (
                            <NoVotedStateItem
                                key={h.identity}
                                representative={h}
                                meeting={this.props.meeting}
                                question={this.props.question}
                            />
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        )
    }

}

export default AnswersStateList;