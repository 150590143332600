import HTTPClient from "../../HTTPClient";
import React, {Fragment, useEffect, useState} from "react";
import * as axios from "axios";
import Loading from "../Loading";
import Typography from "@material-ui/core/Typography";
import Header from "../Header";
import MeetingTitle from "../meeting/MeetingTitle";
import Footer from "../Footer";
import {getMessage} from "../../util";
import QuestionOpen from "./QuestionOpen";
import QuestionFinishedMultiserie from "./QuestionFinishedMultiserie";
import QuestionReady from "./QuestionReady";
import {Card, Paper, Tab, TableCell, Tabs} from "@material-ui/core";
import PropTypes from "prop-types";
import Chip from "@material-ui/core/Chip";
import {makeStyles} from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import {Check} from "@material-ui/icons";
import CardContent from "@material-ui/core/CardContent";
import SearchAnwers from "./SearchAnswers";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import config from "../../config.json";

const useStyles = makeStyles(theme => ({
    tab: {
        textTransform: 'none'
    },
    number: {
        fontFamily: "monospace"
    },
    voted: {
        marginTop: theme.spacing(4),
    },
}));

const loadMeeting = async (meeting_id) => {
    const meeting = await HTTPClient({
        method: "get",
        url:
            process.env.REACT_APP_API_URL +
            "/meetings/" + meeting_id
    });

    return (meeting.data);
}

const loadHolders = async (meeting_id) => {

    const holders = await HTTPClient({
        method: "get",
        url:
            process.env.REACT_APP_API_URL +
            "/meetings/" + meeting_id + "/me/holders"
    });

    return (holders.data);

}

const loadMe = async (meeting_id) => {
    const me = await HTTPClient({
        method: "get",
        url:
            process.env.REACT_APP_API_URL +
            "/meetings/" + meeting_id + "/me"
    });
    return (me.data);
}

const loadQuestion = async (meeting_id, question_id) => {
    const question = await HTTPClient({
        method: "get",
        url:
            process.env.REACT_APP_API_URL +
            "/meetings/" + meeting_id + "/questions/" + question_id
    });
    return (question.data);
}

const MultipleQuestions = (props) => {
    const classes = useStyles();

    const [loading, setLoading] = useState(true);
    const [meeting, setMeeting] = useState(null);
    const [questions, setQuestions] = useState([]);
    const [question_list, setQuestion_list] = useState([]);
    const meeting_id = '76w8xLOm';
    const [hash, setHash] = useState("none");
    const [representative, setRepresentative] = useState(null);
    const [messages, setMessages] = useState(null);
    const [shares, setShares] = useState(null);
    const [value, setValue] = React.useState(0);
    const [holders, setHolders] = useState([]);
    const [details, setDetails] = useState({});

    const [generatedUrl, setGeneratedUrl] = useState(null);
    const [showDownloadButton, setShowDownloadButton] = useState(false);

    const resetLanguage = (event) => {
        localStorage.setItem('eholder-lang-' + meeting_id, event.target.value);
        messages.lang = event.target.value;
        setMessages({...messages});
    }

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };


    // effect to reload meeting when changed hash
    useEffect(() => {
        (async () => {

            try {
                const meeting = await loadMeeting(meeting_id);
                // I'll add new opened questions

                setQuestion_list(question_list => {
                    // If there were all closed and appears a new open, clean the list
                    if (questions.every(q => q.state === 'finished') && meeting.questions.some(q => q.state === 'open'))
                        return (meeting.questions.filter(q => q.state === 'open'));

                    // If there are new open not included before, add them
                    meeting.questions.forEach(q => {
                        if (!q.url && q.state === 'open' && !question_list.map(q => q.slug).includes(q.slug)) {
                            question_list.push(q);
                        }
                    });
                    return ([...question_list]);
                });


                setMeeting(meeting);

            } catch (error) {
                console.log(error);

                switch (error.response.status) {
                    case 401:
                        window.location = '/login/' + meeting_id;
                        break;
                    case 403:
                        window.location = '/meeting/' + meeting_id + '/pending';
                        break;
                    default:
                        setLoading(false);
                        break;
                }
            }
        })()
    }, [meeting_id, hash]);

    // effect to reload questions when changed list of opened
    useEffect(() => {
        (async () => {
            try {
                const new_list = await Promise.all(question_list.map(async q => {
                    return (await loadQuestion(meeting_id, q.slug));
                }))

                setQuestions(new_list);


            } catch (error) {
                console.log(error);

                switch (error.response.status) {
                    case 401:
                        window.location = '/login/' + meeting_id;
                        break;
                    case 403:
                        window.location = '/meeting/' + meeting_id + '/pending';
                        break;
                    default:
                        setLoading(false);
                        break;
                }
            }
        })()

    }, [meeting_id, question_list]);

    // effect only once
    useEffect(() => {
        (async () => {
            try {
                const got_hash = await HTTPClient({
                    method: "get",
                    url:
                        process.env.REACT_APP_API_URL +
                        "/meetings/" + meeting_id + "/hash"
                });
                setHash(got_hash.data);

                // load the meeting the first time
                const meeting = await loadMeeting(meeting_id);

                // load messages
                if (meeting.messages) {
                    const load_messages = await axios({
                        method: "get",
                        url: meeting.messages
                    });
                    const messages = load_messages.data;
                    const nav_lang = navigator.language.split('-')[0];

                    messages.lang = messages.languages.length > 1 ? (localStorage.getItem('eholder-lang-' + meeting_id) || nav_lang) : nav_lang;
                    setMessages(messages);
                }

                //load user
                const me = await loadMe(meeting_id);

                const shares = await HTTPClient({
                    method: "get",
                    url:
                        process.env.REACT_APP_API_URL +
                        "/meetings/" + meeting_id + "/me/shares"
                });


                const question_list = meeting.questions.filter(q => (!q.url && q.state === 'open'));

                setQuestions(await Promise.all(question_list.map(async q => {
                    return (await loadQuestion(meeting_id, q.slug));
                })));

                const holders = await loadHolders(meeting_id);


                setHolders(holders);
                setShares(shares.data);
                setMeeting(meeting);
                setRepresentative(me);
                setQuestion_list(question_list);

                setLoading(false);

            } catch (error) {
                console.log(error);


                switch (error.response.status) {
                    case 401:
                        window.location = '/login/' + meeting_id;
                        break;
                    case 403:
                        window.location = '/meeting/' + meeting_id + '/pending';
                        break;
                    default:
                        setLoading(false);
                        break;
                }
            }
        })();
        // I'll be checking if the meeting changed, i will when questions are opened or closed
        const intervalId = setInterval(() => {
            (async () => {
                try {
                    const got_hash = await HTTPClient({
                        method: "get",
                        url:
                            process.env.REACT_APP_API_URL +
                            "/meetings/" + meeting_id + "/hash"
                    });
                    setHash(got_hash.data);
                } catch (error) {
                    switch (error.response.status) {
                        case 401:
                            window.location = '/login/' + meeting_id;
                            break;
                        case 403:
                            window.location = '/meeting/' + meeting_id + '/pending';
                            break;

                        default:
                            setLoading(false);
                            break;
                    }
                }
            })();
        }, 3000);

        return (() => {
            clearInterval(intervalId);
        });

    }, [meeting_id]);

    const TabPanel = (props) => {
        const {children, value, index, ...other} = props;

        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`simple-tabpanel-${index}`}
                aria-labelledby={`simple-tab-${index}`}
                {...other}
            >
                {value === index && (
                    children
                )}
            </div>
        );
    }

    TabPanel.propTypes = {
        children: PropTypes.node,
        index: PropTypes.any.isRequired,
        value: PropTypes.any.isRequired,
    };

    const goDetails = (index, shown) => {
        details[index] = shown;
        setDetails({...details});
    }

    const getQuestion = (questions, index, volver) => {

        if (details[index]) {
            return (
                <Container className={classes.voted}>
                    <Box mb={2}>
                        <Typography variant={"h5"}>
                            {questions[index].name}
                        </Typography>

                        <Button size="small" variant="outlined" color={"default"}
                                onClick={() => goDetails(index, false)}>
                            {getMessage(meeting.meeting_type, 'back', messages)}</Button>
                    </Box>
                    <SearchAnwers messages={messages} meeting={meeting} question={questions[index]} shares={shares}/>
                </Container>)
        }

        switch (questions[index].state) {
            case 'open':
                return (
                    <QuestionOpen
                        goDetails={() => goDetails(index, true)}
                        holders={holders}
                        question={questions[index]}
                        user={representative}
                        meeting={meeting}
                        shares={shares}
                        messages={messages}
                        onVote={reloadMe}
                        onTime={() => {
                            (async () => {
                                questions[index] = await loadQuestion(meeting_id, questions[index].slug);
                                setQuestions([...questions]);
                            })()
                        }}
                    />);
            case 'finished':
                return (


                    <QuestionFinishedMultiserie messages={messages} question={questions[index]}
                                                user={representative}
                                                volver={volver}
                                                goDetails={() => goDetails(index, true)}
                                                meeting={meeting}/>


                );
            default:
                return (
                    <QuestionReady messages={messages} question={questions[index]} user={representative}
                                   meeting={meeting}
                                   shares={shares}/>);
        }
    }

    const getState = (state) => {
        switch (state) {
            case 'open':
                return (<Chip label={getMessage(meeting.meeting_type, 'question_open', props.messages)}/>);
            case 'finished':
                return (<Chip
                    label={getMessage(meeting.meeting_type, 'question_finished_results', props.messages)}/>);
            default:
                return (<Chip label={getMessage(meeting.meeting_type, 'question_ready', props.messages)}/>);

        }
    }

    const getQuestions = () => {

        if (!questions)
            return;

        // if there is no question at all send to home
        if (questions.length === 0) {
            window.location = '/meeting/' + meeting_id;
            return (<Typography>No hay votaciones abiertas</Typography>);
        }

        if (questions.length === 1)
            return (<Card variant="outlined">
                <CardContent>
                    {getQuestion(questions, 0,questions[0].state === 'finished')}
                </CardContent>
            </Card>);

        const questionsToMe = representative.observer ? questions : questions.filter(q => {
            const question_shares = Object.keys(q.blanks)
            return (holders.some(h => Object.keys(h.shares).some(s => question_shares.includes(s))));
        });


        if (questionsToMe.length === 0)
            return (
                <Typography variant={"h6"}>
                    {getMessage(meeting.meeting_type, 'questions_forbidden', messages)}
                </Typography>
            )


        if (questionsToMe.length === 1)
            return (
                <Card variant="outlined">
                    <CardContent>
                        {getQuestion(questionsToMe, 0, questionsToMe[0].state === 'finished')}
                    </CardContent>
                </Card>);

        const volver = questionsToMe.every(q => q.state === 'finished')

        return (<Paper square>
            <Tabs
                value={value}
                onChange={handleChange}
            >
                {questionsToMe.sort((a, b) => a.order - b.order).map((q, index) => {

                    const answers = holders ? holders.filter(h => h.answers[q.slug]) : null;


                    return (
                        <Tab icon={(answers && answers.length > 0) && <Check/>} className={classes.tab} key={q.slug}
                             label={<Box>{getMessage(meeting.meeting_type, 'votation', messages)} <br/><Chip
                                 component={"span"}
                                 label={getState(q.state)}/></Box>}/>);
                })}
            </Tabs>
            {questionsToMe.map((q, index) => (
                <TabPanel key={q.slug} value={value} index={index}>
                    {getQuestion(questionsToMe, index, volver)}
                </TabPanel>
            ))}
        </Paper>);

    }

    const reloadMe = async () => {
        setRepresentative(await loadMe(meeting_id));
        setHolders(await loadHolders(meeting_id));
    }

    const number = {
        fontFamily: "Montserrat"
    };

    const generateExcelFormatted = async () => {
        setLoading(true);
        setShowDownloadButton(false);
        try {
            const url = await HTTPClient({
                method: "get",
                url:
                    process.env.REACT_APP_API_URL + "/meetings/" + meeting.slug + "/generateFormattedExcel/" + representative.data.email
            });
            setShowDownloadButton(true);
            setGeneratedUrl(url.data.url);
            console.log("URL a descargar: ", url.data.url);
            setLoading(false);
        } catch (error) {
            alert("Hubo un error generando el excel, intente nuevamente")
            setLoading(false);
            setShowDownloadButton(false);
            setGeneratedUrl(null);
        }
    }

    return (loading ? (
            <Loading/>
        ) : ((meeting && representative && shares) ? (
            <Fragment>
                <Header messages={messages} user={representative} logo={meeting.logo} meeting={meeting}
                        resetLanguage={resetLanguage}
                        shares={shares} hide_logout={questions && questions.length > 0}/>
                <hr/>


                <MeetingTitle messages={messages} meeting={meeting}/>

                <Typography variant={"h6"}>
                    {getMessage(meeting.meeting_type, 'holders', messages)}
                </Typography>
                <TableContainer component={Paper}>
                    <Table aria-label="representados">
                        <TableHead>
                            <TableRow>
                                <TableCell>{getMessage(meeting.meeting_type, 'search_id', messages)}</TableCell>
                                <TableCell>{getMessage(meeting.meeting_type, 'search_name', messages)}</TableCell>
                                <TableCell>{getMessage(meeting.meeting_type, 'shares', messages)}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {holders.sort((a, b) => a.order - b.order).map(h => (
                                <TableRow key={h.identity}>
                                    <TableCell component="th" scope="row">
                                        {h.identity}
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        {h.name}
                                    </TableCell>
                                    <TableCell>
                                        {Object.keys(h.shares).map(s => (
                                            <Fragment key={s}>
                                 <span
                                    style={number}>{h.shares[s].shares.toLocaleString(navigator.language, {
                                    maximumFractionDigits: config.precision
                                })}</span>
                                                <br/>
                                            </Fragment>

                                        ))}
                                    </TableCell>

                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <br/>
                {representative.observer &&
                    <div style={{textAlign: "center"}}>
                        {
                            showDownloadButton ? (
                                <Fragment>
                                    <Button variant="contained" color={"secondary"} onClick={generateExcelFormatted}>
                                        Generar planilla
                                    </Button>
                                    <br/>
                                    <br/>
                                    <a href={generatedUrl}> DESCARGAR </a>
                                </Fragment>
                            ) : (
                                <Button variant="contained" color={"secondary"} onClick={generateExcelFormatted}>
                                    Generar planilla
                                </Button>
                            )
                        }
                    </div>
                }
                <br/>
                {getQuestions()}

                <Footer messages={messages} meeting={meeting}/>

            </Fragment>
        ) : (
            <Typography>
                {getMessage('default', 'meeting_not_found', null)}
            </Typography>
        ))
    );
}

export default MultipleQuestions;