import React from "react";
import Typography from '@material-ui/core/Typography';
import Grid from "@material-ui/core/Grid";
import {makeStyles} from "@material-ui/core/styles";
import {Email, Phone} from "@material-ui/icons";
import {getMessage} from "../util";
import {Link} from "@material-ui/core";

const version = require('../../package.json');

const useStyles = makeStyles(theme => ({
        paper: {
            marginTop: theme.spacing(2),
            padding: theme.spacing(1),
            "background-color":"#111836",
            color: "white"
        },
        option: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',

        },
        link: {
            color: 'white'
        }
    }
));

const Footer = (props) => {
    const classes = useStyles();

    return (

        <Grid container spacing={1} className={classes.paper}>

            <Grid item xs={12} sm={6} className={classes.option}>
                <Typography>
                    <Phone />
                </Typography>
                <Typography>
                    <Link href={"tel:"+getMessage(props.meeting.meeting_type,'phone',props.messages)} className={classes.link}>
                        {getMessage(props.meeting.meeting_type,'phone',props.messages)}
                    </Link>
                </Typography>
            </Grid>
            <Grid item xs={12} sm={6} className={classes.option}>
                <Typography>
                    <Email />
                </Typography>
                <Typography>
                    <Link href={"mailto:"+getMessage(props.meeting.meeting_type,'email',props.messages)} className={classes.link}>
                    {getMessage(props.meeting.meeting_type,'email',props.messages)}
                    </Link>
                </Typography>
            </Grid>
            <Grid item xs={12} className={classes.option}>
                <Typography variant="body2">
                    © E-Voting {new Date().getFullYear()}, {version.name} v. {version.version}
                </Typography>
            </Grid>
        </Grid>


    );
}


export default Footer;
