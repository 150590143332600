import React, {Fragment, useEffect, useState} from "react";
import HTTPClient from "../../HTTPClient";
import Header from "../Header";
import Loading from "../Loading";
import Typography from "@material-ui/core/Typography";
import Search from "./Search";
import MeetingTitle from "./MeetingTitle";
import BackButton from "../BackButton";
import {getMessage} from "../../util";
import Footer from "../Footer";
import * as axios from "axios";

const loadMeeting = async (meeting_id) => {
    const meeting = await HTTPClient({
        method: "get",
        url:
            process.env.REACT_APP_API_URL +
            "/meetings/" + meeting_id
    });

    // check if open question
    const open_questions = meeting.data.questions.filter(q => {
        return (!q.url && q.state === 'open')
    });

    if (open_questions.length > 0) {
        window.location = '/meeting/' + meeting.data.slug + '/questions';
        return;
    }

    return (meeting.data);
}

const MeetingSearch = (props) => {

    const [loading, setLoading] = useState(true);
    const [meeting, setMeeting] = useState(null);
    const meeting_id = '76w8xLOm';
    const [representative, setRepresentative] = useState(null);
    const [messages, setMessages] = useState(null);
    const [hash, setHash] = useState("none");
    const [shares, setShares] = useState(null);
    const resetLanguage = (event) => {
        localStorage.setItem('eholder-lang-'+meeting_id, event.target.value);
        messages.lang = event.target.value;
        setMessages({...messages});
    }

    // effect to reload meeting when changed hash
    useEffect(() => {
        (async () => {
            try {
                const meeting = await loadMeeting(meeting_id);
                setMeeting(meeting);
            } catch (error) {
                switch (error.response.status) {
                    case 401:
                        window.location = '/login/' + meeting_id;
                        break;
                    case 403:
                        window.location = '/meeting/'+meeting_id+'/pending';
                        break;

                    default:
                        setLoading(false);
                        break;
                }
            }
        })()
    }, [meeting_id, hash]);

    // effect only once
    useEffect(() => {
        let intervalId = null;
        (async () => {
            try {
                // load the meeting the first time
                const meeting = await loadMeeting(meeting_id);

                // load messages
                if (meeting.messages) {
                    const load_messages = await axios({
                        method: "get",
                        url: meeting.messages
                    });
                    const messages = load_messages.data;
                    const nav_lang = navigator.language.split('-')[0];

                    messages.lang = messages.languages.length > 1 ? (localStorage.getItem('eholder-lang-' + meeting_id) || nav_lang) : nav_lang;
                    setMessages(messages);
                }


                //load user
                const me = await HTTPClient({
                    method: "get",
                    url:
                        process.env.REACT_APP_API_URL +
                        "/meetings/" + meeting_id + "/me"
                });

                const shares = await HTTPClient({
                    method: "get",
                    url:
                        process.env.REACT_APP_API_URL +
                        "/meetings/" + meeting_id + "/me/shares"
                });

                setShares(shares.data);

                setMeeting(meeting);
                setRepresentative(me.data);
                setLoading(false);


                intervalId = setInterval(() => {
                    (async () => {
                        const got_hash = await HTTPClient({
                            method: "get",
                            url:
                                process.env.REACT_APP_API_URL +
                                "/meetings/" + meeting_id + "/hash"
                        });

                        setHash(got_hash.data);

                    })();
                }, 3000);

            } catch (error) {

                switch (error.response.status) {
                    case 401:
                        window.location = '/login/' + meeting_id;
                        break;
                    case 403:
                        window.location = '/meeting/'+meeting_id+'/pending';
                        break;

                    default:
                        setLoading(false);
                        break;
                }
            }
        })();
        return (() => {
            clearInterval(intervalId);
        });

    }, [meeting_id]);


    return (
        <Fragment>
            {loading ? (
                <Loading/>
            ) : ((meeting && representative && shares) ? (
                    <Fragment>
                        <Header messages={messages} user={representative} logo={meeting.logo} meeting={meeting} shares={shares}  resetLanguage={resetLanguage}/>
                        <hr/>

                        <MeetingTitle messages={messages} meeting={meeting}/>

                        <BackButton meeting={meeting} messages={messages} href={"/meeting/" + meeting.slug}/>

                        <Search messages={messages} meeting={meeting}
                        />

                        <Footer messages={messages} meeting={meeting}/>

                    </Fragment>
                ) : (
                    <Typography variant="h5" component="h2">
                        <hr/>
                        {getMessage('default', 'meeting_not_found', null)}
                    </Typography>
                )
            )}


        </Fragment>
    );
}

export default MeetingSearch;