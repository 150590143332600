import Typography from "@material-ui/core/Typography";
import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import dateFormat from "dateformat";
import {getMessage} from "../../util";
import Chip from "@material-ui/core/Chip";

const useStyles = makeStyles(theme => ({
        chip: {
            marginLeft: theme.spacing(2)
        },
        paragraph: {
            margin: theme.spacing(2)
        },
    }
));


const MeetingTitle = (props) => {
    const classes = useStyles();

    return (
        <div className={classes.paragraph}>
            <Typography variant="h5" >
                {getMessage(props.meeting.meeting_type, 'meeting_name', props.messages).format(props.meeting.title)}
                {props.meeting.quorum &&
                <Chip label={getMessage(props.meeting.meeting_type,'closed_door',props.messages)} className={classes.chip}/>}
            </Typography>
            <Typography variant={"h6"}>
                {dateFormat(props.meeting.start_date,getMessage(props.meeting.meeting_type,'date_format',props.messages) )}
            </Typography>
        </div>
       )
}

export default MeetingTitle;