import React, {Component, Fragment} from "react";
import HTTPClient from "../../HTTPClient";
import Loading from "../Loading";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import AnswersList from "./AnswersList";
import AnswersStateList from "./AnswersStateList";
import {getMessage} from "../../util";

class SearchAnwers extends Component {
    constructor(props) {
        super(props);
        this.state = {
            result: {
                total: 0,
                matches: 0,
                holders: []
            },
            readyState: false,
            disabledState: false,
            votedState: false,
            safeConduct: false,
            page: 0,
            size: 25,
            alert: null,
            loading: false,
            intervalId: 0
        };
    }

    componentDidMount() {
        this.setState({
            loading: true, voter: null, result: {
                total: 0,
                matches: 0,
                holders: []
            }, error: null
        }, () => {
            this.loadData();
            this.setState({intervalId: setInterval(this.loadData, 3000)});
        });
    }

    componentWillUnmount() {
        clearInterval(this.state.intervalId);
    }

    doSearch = (event) => {
        event.preventDefault();
        this.loadData();
    };

    setPage = (page) => {
        this.setState({page: page}, this.loadData);
    };

    loadData = () => {


        const data = {
            page: this.state.page,
            size: this.state.size,
            filter: null,
            safeConduct: this.state.safeConduct,
            logged: false,
            unlogged: false,
            verified: false,
            unverified: false

        };


        HTTPClient({
            method: "post",
            data: data,
            url:
                process.env.REACT_APP_API_URL +
                "/meetings/" +
                this.props.meeting.slug +
                "/questions/" +
                this.props.question.slug + "/answers"
        }).then(response => {
            this.setState({loading: false, result: response.data});
        })
            .catch(error => {

                    this.setState({loading: false, error: getMessage(this.props.meeting.meeting_type,'backend_connect_error',this.props.messages) });

            })
    };

    checkInputChange = event => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value.replace(/-/g, "");
        const name = target.name;


        this.setState({
            [name]: value
        }, () => {
            this.loadData();
        });
    };

    handleInputChange = event => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value.replace(/-/g, "");
        const name = target.name;


        this.setState({
            [name]: value
        });
    };



    render() {

        return (
            <Fragment>

                {this.state.loading ? (
                    <Loading/>
                ) : (
                    <Fragment>
                        {this.state.error && (
                            <div className="alert alert-warning" role="alert">
                                <strong>{this.state.error}</strong>
                            </div>
                        )}

                        <Typography>

                            {getMessage(this.props.meeting.meeting_type, 'total', this.props.messages)}: {this.state.result.total.toLocaleString(navigator.language, {
                            minimumFractionDigits: 0
                        })}, &nbsp;
                            {getMessage(this.props.meeting.meeting_type, 'search_matched', this.props.messages)}: {this.state.result.matches.toLocaleString(navigator.language, {
                            minimumFractionDigits: 0
                        })}, &nbsp;
                            {getMessage(this.props.meeting.meeting_type, 'search_showing', this.props.messages).format(
                                (this.state.page * this.state.size + 1).toLocaleString(navigator.language, {
                                    minimumFractionDigits: 0
                                }),
                                (this.state.page * this.state.size + this.state.result.holders.length).toLocaleString(navigator.language, {
                                    minimumFractionDigits: 0
                                })
                            )}

                            &nbsp; &nbsp;

                            {this.state.page > 0 &&
                            <Button variant="outlined" size="small" color="primary"
                                    onClick={() => this.setPage(this.state.page - 1)}>&lt;&lt; {getMessage(this.props.meeting.meeting_type, 'search_before', this.props.messages)}</Button>
                            }

                            {((this.state.page + 1) * this.state.size) < this.state.result.matches &&
                            <Button variant="outlined" size="small" color="primary"
                                    onClick={() => this.setPage(this.state.page + 1)}>{getMessage(this.props.meeting.meeting_type, 'search_next', this.props.messages)} &gt;&gt;</Button>
                            }

                        </Typography>


                        {this.props.question.state === 'finished' &&  !this.props.question.config.secret ? (
                            <AnswersList
                                roles={this.props.roles}
                                result={this.state.result}
                                meeting={this.props.meeting}
                                messages={this.props.messages}
                                update={this.loadData}
                                question={this.props.question}
                                drop_in_box={this.props.drop_in_box}
                                shares={this.props.shares}

                            />
                        ) : (
                            <AnswersStateList
                                roles={this.props.roles}
                                result={this.state.result}
                                meeting={this.props.meeting}
                                messages={this.props.messages}
                                update={this.loadData}
                                question={this.props.question}
                                shares={this.props.shares}
                                drop_in_box={this.props.drop_in_box}

                            />
                        )}

                    </Fragment>

                )}
            </Fragment>

        );
    }
}

export default SearchAnwers;