import React, {Component} from "react";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import TableHead from "@material-ui/core/TableHead";
import Table from "@material-ui/core/Table";
import TableContainer from "@material-ui/core/TableContainer";
import TableBody from "@material-ui/core/TableBody";
import AnswerItem from "./AnswerItem";
import {getMessage} from "../../util";
import NoVotedItem from "./NoVotedItem";

class AnswersList extends Component {

    render() {


        const question_shares = Object.keys(this.props.question.blanks);

        const getRepresentative = (representative) => {
            const common_shares = Object.keys(representative.shares).filter((x) => {
                if (question_shares.indexOf(x) === -1)
                    return false;
                else
                    return true;
            });

            return (<TableRow key={representative.identity}>
                <AnswerItem
                    representative={representative}
                    meeting={this.props.meeting}
                    question={this.props.question}
                    common_shares={common_shares}
                />
            </TableRow>);

        }

        const head_style = {
            textTransform: "uppercase"
        };

        return (
            <TableContainer>
                <Table aria-label="simple table">
                    <TableHead style={head_style}>
                        <TableRow>

                            <TableCell>{getMessage(this.props.meeting.meeting_type, 'holder', this.props.messages)}</TableCell>
                            {this.props.question.options.sort((a, b) => {
                                return (a.order - b.order);
                            }).map(o => (
                                <TableCell key={o._id}>{o.name}</TableCell>
                            ))}
                            {this.props.question.nullable && <TableCell>{getMessage(this.props.meeting.meeting_type, 'nulls', this.props.messages)}</TableCell>}

                            {this.props.question.max_marks !== this.props.question.min_marks && <TableCell>{getMessage(this.props.meeting.meeting_type, 'blanks', this.props.messages)}</TableCell>}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {Object.keys(this.props.result.holders).map(identity => (
                            getRepresentative(this.props.result.holders[identity])
                        ))}
                        {this.props.question.state === 'finished' && this.props.question.unvoted_list.map(h => (
                            <NoVotedItem key={h.identity}
                                representative={h}
                                meeting={this.props.meeting}
                                question={this.props.question}
                            />
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        )
    }

}

export default AnswersList;