import React, {Component} from "react";
import RepresentativeItem from "./RepresentativeItem";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import TableHead from "@material-ui/core/TableHead";
import Table from "@material-ui/core/Table";
import TableContainer from "@material-ui/core/TableContainer";
import TableBody from "@material-ui/core/TableBody";
import {getMessage} from "../../util";

class RepresentativeList extends Component {

    render() {
        return (

            <TableContainer>
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>{getMessage(this.props.meeting.meeting_type, 'search_id', this.props.messages)}</TableCell>
                            <TableCell>{getMessage(this.props.meeting.meeting_type, 'search_name', this.props.messages)}</TableCell>
                            <TableCell>{getMessage(this.props.meeting.meeting_type, 'shares', this.props.messages)}</TableCell>
                            <TableCell>{getMessage(this.props.meeting.meeting_type, 'represented_by', this.props.messages)}</TableCell>
                            <TableCell>{getMessage(this.props.meeting.meeting_type, 'state', this.props.messages)}</TableCell>
                        </TableRow>

                    </TableHead>
                    <TableBody>
                        {Object.keys(this.props.result.holders).map(identity => (
                            <RepresentativeItem key={identity}
                                                representative={this.props.result.holders[identity]}
                                                meeting={this.props.meeting}
                                                messages={this.props.messages}
                            />
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>


        )
    }

}

export default RepresentativeList;