import React, {Component} from "react";
import {TableCell} from "@material-ui/core";
import Chip from "@material-ui/core/Chip";
import {getMessage} from "../../util";
import TableRow from "@material-ui/core/TableRow";

class NoVotedItem extends Component {

    getState = (state) => {
        switch (state) {
            case 'open':
                return (
                    <Chip label={getMessage(this.props.meeting.meeting_type, 'question_open', this.props.messages)}/>);
            case 'finished':
                return (<Chip
                    label={getMessage(this.props.meeting.meeting_type, 'question_finished', this.props.messages)}/>);
            default:
                return (
                    <Chip label={getMessage(this.props.meeting.meeting_type, 'question_ready', this.props.messages)}/>);

        }
    }


    render() {


        const ColSpan = this.props.question.nullable ? 2 : 1;

        return (
            <TableRow>
                <TableCell>
                    {this.props.representative.name ? this.props.representative.name : 'no name'} ({this.props.representative.identity})
                    <p>
                        {getMessage(this.props.meeting.meeting_type, 'represented_by', this.props.messages)}: {this.props.representative.represented_by.name} ({this.props.representative.represented_by.identity})
                    </p>

                </TableCell>


                <TableCell colSpan={this.props.question.options.length + ColSpan}>
                    No votó
                </TableCell>

            </TableRow>
        );
    }
}


export default NoVotedItem;