import React, {useRef, useState} from "react";
import {getDv, getMessage} from "../util";
import Container from "@material-ui/core/Container";
import Unknown from "./responses/Unknown";
import CssBaseline from "@material-ui/core/CssBaseline";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import {makeStyles} from "@material-ui/core/styles";
import HTTPClient from "../HTTPClient";
import Loading from "./Loading";
import NotInMeetingRoll from "./responses/NotInMeetingRoll";
import AskPhone from "./responses/AskPhone";
import Late from "./responses/Late";
import WrongSC from "./responses/WrongSC";
import AnotherDevice from "./responses/AnotherDevice";
import Recaptcha from "./responses/Recaptcha";

const useStyles = makeStyles(theme => ({
        paper: {
            marginTop: theme.spacing(4),
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        },
        question: {
            marginTop: theme.spacing(4),
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        },
        checkboxLabel: {
            fontSize: '1.5rem'
        },
        grid: {
            alignItems: 'center',
        },

        carnet: {
            width: '100%',
            maxWidth: '200px',
            display: 'block',
            'margin-left':
                'auto',
            'margin-right':
                'auto',
        },
        form: {
            width: '100%', // Fix IE 11 issue.
            marginTop: theme.spacing(1)
        },
        submit: {
            margin: theme.spacing(3, 0, 2),
        },
    }
));

export const IDPassword = (props) => {
    const classes = useStyles();

    const rutRef = useRef();
    const serialRef = useRef();

    const [rutText, setRutText] = useState('');
    const [serialText, setSerialText] = useState('');
    const [status, setStatus] = useState('ok');
    const [loading, setLoading] = useState(false);
    const [jwt, setJwt] = useState(null);
    const [reason, setReason] = useState(null);
    const [myMistake, setMyMistake] = useState(false);
    const [wrongCounter, setWrongCounter] = useState(0);

    const setInputRut = (event) => {

        if (props.meeting.authentication === 'rut_password') {

            const noFormat = rutRef.current.value.replace(/[^0-9kK]/g, "").toUpperCase();
            const dv = noFormat.charAt(noFormat.length - 1);
            let rut = noFormat.substring(0, noFormat.length - 1);
            let withFormat = '';
            while (rut.length > 3) {
                withFormat = "." + rut.substr(rut.length - 3) + withFormat;
                rut = rut.substring(0, rut.length - 3);
            }
            withFormat = rut + withFormat + "-" + dv.toUpperCase();

            rutRef.current.value = (noFormat.length > 1) ? withFormat : noFormat;
            setRutText((dv.toUpperCase() !== getDv(noFormat.substring(0, noFormat.length - 1)).toString().toUpperCase()) ? " " : "")
        }

    };

    const login = (event) => {
        event.preventDefault();


        if (props.meeting.authentication === 'rut_password') {

            const rutNoFormat = rutRef.current.value.replace(/[^0-9kK]/g, "").toUpperCase();
            const dv = rutNoFormat.charAt(rutNoFormat.length - 1)
            const rutInvalid = !rutNoFormat || (dv.toUpperCase() !== getDv(rutNoFormat.substring(0, rutNoFormat.length - 1)).toString().toUpperCase());

            if (rutInvalid) {
                setRutText((!rutNoFormat) ? getMessage(props.meeting.meeting_type, 'must_put_rut', props.messages) : (rutInvalid ? getMessage(props.meeting.meeting_type, 'wrong_input_rut', props.messages) : ""));
                return;
            }

        }


        const serialNoFormat = serialRef.current.value.replace(/[^0-9a-zA-Z]/g, "").toUpperCase();

        const serialInvalid = !(serialNoFormat && (/^(C[A-NP-Z1-9]{5})$/.test(serialNoFormat) || /^([A-NP-Z1-9]{8})$/.test(serialNoFormat)));

        if (serialInvalid) {
            setSerialText((!serialNoFormat) ? getMessage(props.meeting.meeting_type, 'must_put_pass', props.messages) : (serialInvalid ? getMessage(props.meeting.meeting_type, 'wrong_input_pass', props.messages) : ""));
            return;
        }


        const connect = (data) => HTTPClient({
            method: "post",
            data: data,
            url:
                process.env.REACT_APP_API_URL +
                "/login/id_password"
        })
            .then(response => {
                setLoading(false);
                switch (response.data.status) {
                    case 'ok':
                        window.location = '/';

                        return;
                    case 'wrong':
                        if (wrongCounter > 0) {
                            setJwt(response.data.message);
                            setReason("segundo intento con serial malo");
                            setStatus('askphone');
                        } else {
                            setWrongCounter(wrongCounter + 1);
                            setStatus('wrongSerial');
                        }
                        //setStatus('wrongSerial');
                        return;
                    case 'notfound':
                        setStatus('notfound');
                        return;
                    case 'safeconduct':

                        window.location =  '/';

                        return;
                    case 'quorum':
                        setStatus('late');
                        return;
                    case 'recaptcha':
                        setStatus('recaptcha');
                        return;
                    default:
                        setJwt(response.data.message);
                        setReason("razón desconocida");
                        setMyMistake(true);
                        setStatus('askphone');
                        // setStatus('unknown');
                        return;
                }
            })
            .catch((error) => {
                if (error.response)
                    switch (error.response.status) {
                        case 403:
                            setStatus('another');
                            setLoading(false);
                            return;
                        default:
                            break;
                    }
                setStatus('unknown');
                setLoading(false);

            });

        setLoading(true);

        if (process.env.REACT_APP_RECAPTCHA_ENABLED !== "false") {
            window.grecaptcha.enterprise.ready(async () => {
                window.grecaptcha.enterprise.execute(process.env.REACT_APP_RECAPTCHA_SITE, {action: 'id_password'}).then(token => {
                    const data = {
                        identity: props.meeting.authentication === 'rut_password' ? rutRef.current.value.replace(/[^0-9kK\-]/g, "").toUpperCase() : rutRef.current.value,
                        password: serialNoFormat,
                        meeting_id: props.meeting.slug,
                        gRecaptchaResponse: token
                    }
                    connect(data);
                })
            })
        } else {
            const data = {
                identity: props.meeting.authentication === 'rut_password' ? rutRef.current.value.replace(/[^0-9kK\-]/g, "").toUpperCase() : rutRef.current.value,
                password: serialNoFormat,
                meeting_id: props.meeting.slug
            }
            connect(data);
        }

    }

    const switchDevice = () => {
        setLoading(true);
        HTTPClient({
            method: "get",
            url:
                process.env.REACT_APP_API_URL +
                "/switch/" + props.meeting.slug
        })
            .then(response => {
                setStatus('ok');
                window.location = '/';
            })
            .catch((error) => {
                setStatus('unknown');
                setLoading(false);
            });
    }

    const setSerialInput = () => {
        const noFormat = serialRef.current.value.replace(/[^0-9a-zA-Z]/g, "").toUpperCase();
        serialRef.current.value = noFormat;
        setSerialText((/^(C[A-NP-Z1-9]{5})$/.test(noFormat) || /^([A-NP-Z1-9]{8})$/.test(noFormat)) ? "" : " ")

    };


    return (

        <Container component="main" maxWidth="xs">
            {status === 'wrongSerial' &&
            <WrongSC meeting={props.meeting} messages={props.messages} open={status === 'wrongSerial'}
                     handleClose={() => setStatus('ok')}/>}
            {status === 'unknown' &&
            <Unknown meeting={props.meeting} messages={props.messages} open={status === 'unknown'}
                     handleClose={() => setStatus('ok')}/>}
            {status === 'recaptcha' &&
            <Recaptcha meeting={props.meeting} messages={props.messages} open={status === 'recaptcha'}
                       handleClose={() => setStatus('ok')}/>}
            {status === 'notfound' &&
            <NotInMeetingRoll messages={props.messages} open={status === 'notfound'} handleClose={() => setStatus('ok')}
                              meeting={props.meeting}/>}
            {status === 'late' &&
            <Late messages={props.messages} open={true} handleClose={() => setStatus('ok')} meeting={props.meeting}/>}
            {status === 'another' &&
            <AnotherDevice messages={props.messages} open={true} handleClose={() => setStatus('ok')}
                           meeting={props.meeting} switchDevice={switchDevice}/>}
            {status === 'askphone' &&
            <AskPhone messages={props.messages} reason={reason} jwt={jwt} my_mistake={myMistake} meeting={props.meeting}
                      handleClose={() => setStatus('ok')}/>}

            <CssBaseline/>
            <div className={classes.paper}>

                <Typography component="h1" variant="h5">
                    {getMessage(props.meeting.meeting_type, 'welcome', props.messages).format(props.meeting.title)}
                </Typography>
                <form className={classes.form} noValidate>
                    <TextField
                        error={rutText !== ''}
                        helperText={rutText.trim()}
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="identity"
                        label={getMessage(props.meeting.meeting_type, props.meeting.authentication === 'rut_password' ? 'rut_label' : 'id_label', props.messages)}
                        name="identity"
                        autoFocus
                        inputRef={rutRef}
                        onChange={() => setInputRut(rutRef, setRutText)}
                    />
                    <TextField
                        error={serialText !== ''}
                        helperText={serialText.trim()}
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        name="serial"
                        label={getMessage(props.meeting.meeting_type, 'password_label', props.messages)}
                        type="serial"
                        id="serial"
                        inputRef={serialRef}
                        onChange={() => setSerialInput(serialRef, setSerialText)}
                    />

                    {loading ? (
                        <Loading/>
                    ) : (
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                            onClick={login}
                        >
                            {getMessage(props.meeting.meeting_type, 'button_verify_identity', props.messages)}                        </Button>
                    )}
                </form>
            </div>

        </Container>

    );
}

export default IDPassword;