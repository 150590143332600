import Button from "@material-ui/core/Button";
import React, {createRef, Fragment, useRef, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import HTTPClient from "../../HTTPClient";
import Loading from "../Loading";
import {getMessage} from "../../util";
import FormGroup from "@material-ui/core/FormGroup";
import Checkbox from "@material-ui/core/Checkbox";
import {Clear, HowToVote, RadioButtonChecked, RadioButtonUnchecked} from "@material-ui/icons";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import TableContainer from "@material-ui/core/TableContainer";
import {InputAdornment, Paper, TableCell, TextField} from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";

const useStyles = makeStyles(theme => ({
        paper: {
            marginTop: theme.spacing(4),
            marginBottom: theme.spacing(2),
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'left',
        },
        voted: {
            marginTop: theme.spacing(4),
        },
        paragraph: {
            margin: theme.spacing(2)
        },
        cardGrid: {
            paddingTop: theme.spacing(8),
            paddingBottom: theme.spacing(8),
        },
        card: {
            marginTop: theme.spacing(4),
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
        },
        cardActions: {
            justifyContent: 'center',
        },
        cardContent: {
            flexGrow: 1,
        },
        option: {
            margin: theme.spacing(3),
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        },
        option_label: {
            color: 'black'
        },
        center: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
        },
        label: {
            alignItems: 'left',
        },
        textField: {
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(1),
            width: '25ch',
        },
        selector: {
            margin: theme.spacing(2)
        },
        hide: {
            display: "none"
        },
        unhide: {
            display: "inherit"
        }

    }
));

const Ballot = (props) => {

    const holders = props.holders.filter(h => props.value[h.identity][props.option[0]] && (props.value[h.identity][props.option[0]].includes(props.option[1]) || props.value[h.identity][props.option[0]].length < props.question.max_marks))
    const [selected, setSelected] = useState(holders.filter(h => props.value[h.identity][props.option[0]].includes(props.option[1])).map(h => h.identity));
    const value = props.value;

    const classes = useStyles();

    const handleClick = (event) => {
        if (event.target.checked) {
            setSelected([...selected, event.target.value])
            // selected.push(event.target.value);
        } else {
            const index = selected.indexOf(event.target.value);
            if (index > -1) {
                selected.splice(index, 1);
            } else {
                selected.splice(0, 0, event.target.value);
            }
            setSelected([...selected])
        }
    };

    const markAll = () => {
        setSelected(props.holders.map(h => h.identity));
    }

    const unMarkAll = () => {
        setSelected([]);
    }

    const vote = () => {
        holders.map(h => h.identity).forEach(h => {
            if (selected.includes(h) && value[h][props.option[0]].indexOf(props.option[1]) < 0) {
                value[h][props.option[0]].splice(0, 0, props.option[1]);
            }
            if (!selected.includes(h) && value[h][props.option[0]].indexOf(props.option[1]) > -1) {
                value[h][props.option[0]].splice(value[h][props.option[0]].indexOf(props.option[1]), 1);
            }
        });

        props.setValue(value);
        props.handleClose();

    }

    return (
        <Dialog
            open={true}
            scroll="body"
            onClose={props.handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {props.question.name}<br/>
                {getMessage(props.meeting.meeting_type, 'ballot_title', props.messages)}: {props.option[2]}
            </DialogTitle>

            <DialogContent>
                <Button className={classes.selector} variant="outlined"
                        onClick={markAll}>{getMessage(props.meeting.meeting_type, 'all', props.messages)}</Button>
                <Button className={classes.selector} variant="outlined"
                        onClick={unMarkAll}>{getMessage(props.meeting.meeting_type, 'none', props.messages)}</Button>

                {holders.map(h => (
                    <FormGroup row key={h.identity}>
                        <FormControlLabel control={<Checkbox value={h.identity} checked={selected.includes(h.identity)}
                                                             onClick={handleClick}/>} label={h.name}/>
                    </FormGroup>
                ))}

            </DialogContent>


            <DialogActions>
                <Button onClick={vote} className={classes.selector} variant="outlined" autoFocus>
                    {getMessage(props.meeting.meeting_type, 'done', props.messages)}
                </Button>
            </DialogActions>


        </Dialog>
    )
}

const Resume = (props) => {
    const classes = useStyles();
    const [loading, setLoading] = useState(false);

    const vote = () => {

        const data = props.answers.map(answer => {
            return ({
                identity: answer.identity,
                answers: answer.options.map(a => {
                    return ({option_id: a._id, votes: a.votes})
                }),
                nulls: answer.nulls,
                blanks: answer.blanks
            });
        })


        setLoading(true);
        HTTPClient({
            method: "post",
            data: data,
            url:
                process.env.REACT_APP_API_URL +
                "/meetings/" + props.meeting.slug + "/questions/" + props.question.slug + "/vote"
        })
            .then(response => {
                if (response.data) {

                    props.onVote();
                } else {
                    window.alert(getMessage(props.meeting.meeting_type, 'answer_cannot_sent', props.messages));
                    setLoading(false);

                }

//                setLoading(false);

            }).catch((error) => {
            console.log(error);
            window.alert(getMessage(props.meeting.meeting_type, 'answer_cannot_sent', props.messages));
            setLoading(false);

        });


    }

    const options = props.answers.reduce((acc, answer) => {
        const option = answer.options.reduce((acc, o) => {
            Object.keys(o.votes).forEach(k => {
                if (o.votes[k].shares > 0) {
                    if (!acc[k]) {
                        acc[k] = [];
                    }
                    acc[k].push(['option', o.name]);
                }
            });
            return (acc);

        }, Object.keys(answer.blanks).reduce((acc, k) => {
            acc[k] = [];
            return (acc);
        }, {}));

        Object.keys(answer.nulls).forEach(k => {
            if (answer.nulls[k].shares > 0) {
                option[k].push(['null', getMessage(props.meeting.meeting_type, 'null', props.messages)]);
            }
        });

        Object.keys(answer.blanks).filter(k => answer.blanks[k].shares > 0).forEach(k => {
            if (props.question.max_marks - props.question.min_marks > 1) {
              option[k].push(['blank', answer.blanks[k].shares + " " + getMessage(props.meeting.meeting_type, answer.blanks[k].shares > 1 ? 'blanks' : 'blank', props.messages)]);
            } else {
                option[k].push(['blank', getMessage(props.meeting.meeting_type, 'blank', props.messages)]);
            }
        });

        acc[answer.identity] = option;
        return (acc);
    }, {});


    const question_shares = Object.keys(props.question.blanks)

    const representative_question = Object.keys(props.shares).filter((x) => {
        if (question_shares.indexOf(x) === -1)
            return false;
        else
            return true;
    });

    console.log(props.question.blanks)

    return (<Dialog
        open={true}
        scroll="body"
        onClose={props.handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
    >
        <DialogTitle id="alert-dialog-title">
            {getMessage(props.meeting.meeting_type, 'answer_confirm_title', props.messages)}

        </DialogTitle>

        <DialogContent>

            <Typography>{getMessage(props.meeting.meeting_type, 'answer_confirm_title1', props.messages)}</Typography>
            <br/>
            <Typography>{getMessage(props.meeting.meeting_type, 'answer_confirm_title2', props.messages)}</Typography>
            <br/>
            <Typography>{getMessage(props.meeting.meeting_type, 'answer_confirm_title3', props.messages)}</Typography>
            <br/>
            <Typography>{getMessage(props.meeting.meeting_type, 'answer_confirm_title4', props.messages)}</Typography>
            <br/>

            <TableContainer component={Paper}>
                <Table className={classes.table} size="small" aria-label="a dense table">
                    <TableHead>
                        <TableRow>
                            <TableCell>{getMessage(props.meeting.meeting_type, 'holder', props.messages)}</TableCell>
                            {representative_question.map(s => (
                                <TableCell key={s} align="right">{getMessage(props.meeting.meeting_type, 'apoderado', props.messages)}</TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {props.holders.map(h => (
                            <TableRow key={h.identity}>
                                <TableCell component="th" scope="row">
                                    {h.name} ({h.identity})
                                </TableCell>
                                {representative_question.map(s => (
                                    <TableCell key={s} align={'right'}>
                                        {options[h.identity][s] ? (
                                            <span>
                                                {options[h.identity][s].map(m => (
                                                    (m[0] === 'blank' || m[0] === 'null') ? (
                                                        <b key={m[1]}>{m[1]}<br/></b>
                                                    ) : (<Fragment key={m[1]}>{m[1]}<br/></Fragment>)))}
                                            </span>) : "-"}
                                    </TableCell>
                                ))}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

        </DialogContent>

        {loading ? (
            <Loading/>

        ) : (
            <DialogActions>
                <Button onClick={props.handleClose} autoFocus>
                    {getMessage(props.meeting.meeting_type, 'back', props.messages)}
                </Button>
                <Button variant="contained" color={"primary"} onClick={vote}>
                    {getMessage(props.meeting.meeting_type, 'answer_confirm_button', props.messages)}
                </Button>
            </DialogActions>

        )}


    </Dialog>);
}

const UnderFlow = (props) => {

    return (<Dialog
        open={props.underflow.length > 0}
        onClose={props.close}
        scroll="body"
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
    >
        <DialogTitle id="alert-dialog-title">
            {getMessage(props.meeting.meeting_type, 'underflow_title', props.messages).format(props.question.min_marks)}
        </DialogTitle>

        <DialogContent>

            <Typography>
                {getMessage(props.meeting.meeting_type, 'underflow', props.messages).format(props.question.min_marks + props.question.config.explicit_blank ? 1 : 0)}
            </Typography>
            <ul>
                {props.underflow.map(holder => (
                    <li key={holder.identity}>{holder.name} ({holder.identity}) {Object.keys(props.question.blanks).length > 1 &&
                    <span>
                        {getMessage(props.meeting.meeting_type, 'in', props.messages)} {holder.underflows.map(s => props.shares[s].name).join(", ")}
                                    </span>}</li>
                ))}
            </ul>


        </DialogContent>
        <DialogActions>
            <Button onClick={props.close} autoFocus variant="outlined">
                {getMessage(props.meeting.meeting_type, 'close', props.messages)}
            </Button>
        </DialogActions>
    </Dialog>)
}

const OptionsSingle = (props) => {

    const classes = useStyles();
    const [resume, setResume] = useState(false);
    const [option, setOption] = useState(null);
    const [underflow, setUnderflow] = useState([]);
    const [filtro, setFiltro] = useState([]);

    const filtroIn = useRef();

    const question_shares = Object.keys(props.question.blanks)


    const [answers, setAnswers] = useState(props.holders.map(h => {
        const representative_question = Object.keys(h.shares).filter((x) => {
            if (question_shares.indexOf(x) === -1)
                return false;
            else
                return true;
        });
        return ({
            identity: h.identity,
            options: props.question.options.map(o => {
                o.votes = representative_question.reduce((acc, k) => {
                    acc[k] = {
                        name: props.shares[k].name,
                        shares: 0
                    };
                    return (acc);
                }, {});
                return (o)
            }),
            blanks: representative_question.reduce((acc, k) => {
                acc[k] = {
                    name: props.shares[k].name,
                    shares: 0
                };
                return (acc);
            }, {}),
            nulls: representative_question.reduce((acc, k) => {
                acc[k] = {
                    name: props.shares[k].name,
                    shares: 0
                };
                return (acc);
            }, {})
        })

    }));


    const the_value = props.holders.reduce((accH, h) => {

        // get the series that the holder has and that are included in the question
        const representative_question = Object.keys(h.shares).filter((x) => {
            if (question_shares.indexOf(x) === -1)
                return false;
            else
                return true;
        });


        // setup to initial empty array for votes,it is an array since it can have multiplechoice

        const by_share = representative_question.reduce((acc, k) => {
            acc[k] = [];
            return (acc);
        }, {});
        // by_share['blanks'] = [];
        accH[h.identity] = by_share;
        return (accH);
    }, {});

    const [valores, setValue] = React.useState(the_value);


    // TODO: BUG Fix, for some reason value is not well setup
/*
    props.holders.forEach(h => {
        const representative_question = Object.keys(h.shares).filter((x) => {
            if (question_shares.indexOf(x) === -1)
                return false;
            else
                return true;
        });

        representative_question.forEach(k => {
            if (!valores[h.identity][k])
                valores[h.identity][k] = []
        })
    })
*/

    const representative_question = Object.keys(props.shares).filter((x) => {
        if (question_shares.indexOf(x) === -1)
            return false;
        else
            return true;
    });

    const handleOptions = (share, option_id, option_name) => {


        if (props.holders.length === 1) {
            if (!props.question.nullable) {

                if (props.question.max_marks === 1) {
                    // if only one, unselect any old checkbox
                    if ( check_refs[share][option_id].current.checked) {
                        Object.keys(check_refs[share])
                            .filter(o => {
                                return((props.question.config.explicit_blank || o !== 'blanks') && check_refs[share][o].current.checked === true)
                            })
                            .forEach(k => {
                                valores[props.holders[0].identity][share].splice(valores[props.holders[0].identity][share].indexOf(option_id), 1);
                            });
                        check_refs[share][option_id].current.checked = true;
                    }
                } else {
                    // if more than one, unmark before marking
                    const marked = Object.keys(check_refs[share]).filter(o => (props.question.config.explicit_blank || o !== 'blanks') && check_refs[share][o].current.checked === true);
                    if (marked.length > props.question.max_marks) {
                        alert(getMessage(props.meeting.meeting_type, 'overflow', props.messages));
                        check_refs[share][option_id].current.checked = false;
                        return;
                    }
                }
            }

            const index = valores[props.holders[0].identity][share].indexOf(option_id);
            if (index > -1) {
                valores[props.holders[0].identity][share].splice(index, 1);
            } else {
                valores[props.holders[0].identity][share].splice(0, 0, option_id);
            }

            setValue(Object.keys(valores).reduce((acc, k) => {
                acc[k] = valores[k];
                return (acc);
            }, {}));
        } else {
            setOption([share, option_id, option_name]);
        }
    }

    const check_refs = representative_question.reduce((acc, elem) => {
        acc[elem] = props.question.options.reduce((acc, k) => {
            acc[k._id] = createRef();
            return (acc);
        }, {});
        acc[elem]['blanks'] = createRef();
        return (acc);
    }, {});


    const votar = event => {

        // check if voted enough

        const answers = props.holders.map(holder => {

            const representative_question = Object.keys(holder.shares).filter((x) => {
                if (question_shares.indexOf(x) === -1)
                    return false;
                else
                    return true;
            });


            const votes = props.question.options.map(o => {
                const no = {};
                no._id = o._id;
                no.name = o.name;
                no.order = o.order;
                no.votes = representative_question.reduce((acc, k) => {
                    acc[k] = {
                        name: props.shares[k].name,
                        // only if not exceeding (null) max can mark as 1 the options
                        shares: Number((valores[holder.identity][k].length <= props.question.max_marks && valores[holder.identity][k].includes(o._id)) ? 1 : 0)
                    }
                    return (acc);
                }, {})

                return (no)
            });

            const underflow_shares = representative_question.filter(k => {
                return (valores[holder.identity][k].length < props.question.min_marks + props.question.config.explicit_blank ? 1 : 0);
            });

            const nulls = representative_question.reduce((acc, k) => {
                acc[k] = {
                    name: props.shares[k].name,
                    // all exceeding max marks are null
                    shares: Number(valores[holder.identity][k].length > props.question.max_marks ? props.question.max_marks : 0)
                }
                return (acc);
            }, {});


            const blanks = representative_question.reduce((acc, k) => {
                acc[k] = {
                    name: props.shares[k].name,
                    // all exceeding max marks are null
                    shares: (nulls[k].shares > 0) ? 0 : Number(props.question.max_marks - valores[holder.identity][k].filter(o => o !== 'blanks').length)
                }
                return (acc);
            }, {});

            return ({
                identity: holder.identity,
                name: holder.name,
                options: votes,
                nulls: nulls,
                blanks: blanks,
                underflows: underflow_shares
            });


        });

        const the_underflows = answers.filter(a => a.underflows.length > 0);
        if (the_underflows.length > 0) {
            setUnderflow(the_underflows);
        } else {
            setAnswers(answers);
            setResume(true);
        }

    };


    const divide2 = representative_question.length > 2 ? 4 : (12 / representative_question.length);
    const divide1 = representative_question.length > 1 ? 6 : 12
    

    // I'll need marked to count how many representatives voted for an option
    const marked = Object.keys(valores).reduce((acc, h) => {
        Object.keys(valores[h]).forEach(name => {
            valores[h][name].forEach(option => {
                if (!acc[name]) {
                    acc[name] = {}
                }
                if (!acc[name][option]) {
                    acc[name][option] = [h];
                } else acc[name][option] = acc[name][option].concat([h])
            });
        });

        return (acc);
    }, Object.keys(valores).reduce((acc, h) => {
        Object.keys(valores[h]).forEach(name => {
            props.question.options.forEach(o => {
                if (!acc[name]) {
                    acc[name] = {}
                }
                ;
                acc[name][o._id] = [];

            });
            acc[name]['blanks'] = [];
        });

        return (acc);
    }, {}));


    const iconUnchecked = props.holders.length === 1 ? <RadioButtonUnchecked/> : <HowToVote/>;
    const iconChecked = props.holders.length === 1 ? <RadioButtonChecked/> : <HowToVote/>;

    const filtrar = (event) => {
        const value = event.target.value;
        setFiltro(value.trim().split(' ').map(w => w.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase()));
    }

    const clearFiltro = () => {
        setFiltro([]);
        filtroIn.current.value = "";
    }

    const aplicaFiltro = (o) => {
        {
            const words = o.name.split(' ').map(w => w.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase());
            return (filtro.length < 1 || !filtro[0] || filtro.reduce((acc, w) => {
                return (acc && words.reduce((acc, p) => {
                    return (acc || p.startsWith(w));
                }, false));
            }, true));
        }
    }


    return (

        <Fragment>
            {option && <Ballot holders={props.holders} option={option} meeting={props.meeting}
                               setValue={setValue} value={valores} question={props.question}
                               handleClose={() => setOption(null)}/>}
            {resume &&
            <Resume holders={props.holders} answers={answers} question={props.question} meeting={props.meeting}
                    shares={props.shares} user={props.user}
                    handleClose={() => setResume(false)} messages={props.messages} onVote={props.onVote}/>}

            {underflow.length > 0 && <UnderFlow question={props.question} meeting={props.meeting}
                                                shares={props.shares} messages={props.messages}
                                                underflow={underflow}
                                                close={() => setUnderflow([])}
                                                holders={props.holders}/>}

            <Typography className={classes.paragraph} variant={"h6"}>
                {props.question.max_marks === props.question.min_marks ? (
                    getMessage(props.meeting.meeting_type, 'vote_n_to_n_single_instruction', props.messages).format(props.question.min_marks)
                ) : (
                    props.question.max_marks === 1 ? (
                        getMessage(props.meeting.meeting_type, 'vote_1_single_instruction', props.messages)
                    ) : (
                        props.question.min_marks === 0 ? (
                            getMessage(props.meeting.meeting_type, 'vote_n_single_instruction', props.messages).format(props.question.max_marks)
                        ) : (
                            getMessage(props.meeting.meeting_type, 'vote_k_to_n_single_instruction', props.messages).format(props.question.min_marks, props.question.max_marks)
                        )
                    )
                )}
            </Typography>

            {props.question.config.searchable === true && (<Typography className={classes.paragraph}
                                                                       variant={"h6"}>{getMessage(props.meeting.meeting_type, 'searchable_text', props.messages)}: <TextField
                id="standard-basic"
                inputRef={filtroIn}
                InputProps={{
                    endAdornment: (filtro.length > 0 && <InputAdornment position="end"><Button
                        onClick={() => clearFiltro()}><Clear/></Button></InputAdornment>),
                }}
                onChange={filtrar}></TextField></Typography>)}


            <Container className={classes.option}>

                <form className={classes.form} noValidate>
                    <Grid container spacing={2} alignItems={"center"}>
                        {representative_question.map(k => (
                            <Grid key={k} item xs={12} sm={divide1} md={divide2}>
                                {(props.meeting.shares[Object.keys(props.meeting.shares)[0]].rights !== 1 || representative_question.length > 1) && (
                                    <Container>
                                        <Typography variant={"h6"}>
                                            {props.shares[k].name}
                                        </Typography>
                                    </Container>)}


                                <FormGroup aria-label="option">
                                    {props.question.options.sort((a, b) => {
                                        return (a.order - b.order);
                                    }).map(o => (

                                        <FormControlLabel key={o._id}
                                                          control={
                                                              <Checkbox
                                                                  onClick={() => handleOptions(k, o._id, o.name)}
                                                                  inputRef={check_refs[k][o._id]}
                                                                  checked={marked[k][o._id].length > 0}
                                                                  icon={iconUnchecked}
                                                                  checkedIcon={iconChecked}
                                                              />
                                                          }
                                                          label={(props.holders.length > 1 ? "(" + marked[k][o._id].length + ") " : "") + o.name}
                                                          className={aplicaFiltro(o)?classes.unhide:classes.hide}
                                        />


                                    ))}
                                    {props.question.config.explicit_blank && <FormControlLabel
                                        control={
                                            <Checkbox
                                                onClick={() => handleOptions(k, 'blanks', getMessage(props.meeting.meeting_type, 'blank', props.messages))}
                                                inputRef={check_refs[k]['blanks']}
                                                checked={marked[k]['blanks'].length > 0}
                                                icon={iconUnchecked}
                                                checkedIcon={iconChecked}
                                            />
                                        }
                                        label={(props.holders.length > 1 ? "(" + marked[k]['blanks'].length + ") " : "") + getMessage(props.meeting.meeting_type, 'blank', props.messages)}
                                    />}
                                </FormGroup>
                            </Grid>
                        ))}

                    </Grid>

                    <Container className={classes.option}>

                        <Button variant="contained" color={"primary"} onClick={votar}>
                            {getMessage(props.meeting.meeting_type, 'vote', props.messages)}
                        </Button>
                    </Container>


                </form>
            </Container>
        </Fragment>


    )
}

export default OptionsSingle;