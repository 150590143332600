import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import React from "react";
import Typography from "@material-ui/core/Typography";
import {getMessage} from "../../util";

const Blocked = (props) => {
    return (<Dialog
        open={props.open}
        scroll="body"
        onClose={props.handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
    >
        <DialogTitle id="alert-dialog-title">{getMessage(props.meeting.meeting_type,'blocked_title',props.messages)}</DialogTitle>
        <DialogContent>
            <Typography id="alert-dialog-description">
                {getMessage(props.meeting.meeting_type,'blocked_message',props.messages)}
            </Typography>
        </DialogContent>
        <DialogActions>
            <Button variant="contained" onClick={props.handleClose} color="primary" autoFocus>
                {getMessage(props.meeting.meeting_type,'close',props.messages)}
            </Button>
        </DialogActions>
    </Dialog>)
}

export default Blocked;