import React, {Fragment, useEffect, useState} from "react";
import HTTPClient from "../../HTTPClient";
import Loading from "../Loading";
import Typography from "@material-ui/core/Typography";
import Header from "../Header";
import SearchAnwers from "./SearchAnswers";
import Container from "@material-ui/core/Container";
import MeetingTitle from "../meeting/MeetingTitle";
import Box from "@material-ui/core/Box";
import BackButton from "../BackButton";
import {getMessage} from "../../util";
import Footer from "../Footer";
import * as axios from "axios";

const loadQuestion = async (meeting_id,question_id) => {
    const question = await HTTPClient({
        method: "get",
        url:
            process.env.REACT_APP_API_URL +
            "/meetings/" + meeting_id + "/questions/" + question_id
    });
    return(question.data);
}

export const loadMeeting = async (meeting_id,question_id) => {
    const meeting = await HTTPClient({
        method: "get",
        url:
            process.env.REACT_APP_API_URL +
            "/meetings/" + meeting_id
    });

    //check if published
    if (!meeting.data.questions.map(q => q.slug).includes(question_id)) {
        window.location = '/meeting/' + meeting.data.slug
    }



    return (meeting.data);
}


const QuestionAnswers = (props) => {

    const [loading, setLoading] = useState(true);
    const [question, setQuestion] = useState(null);
    const [meeting, setMeeting] = useState(null);
    const [meeting_id] = useState('76w8xLOm');
    const [question_id] = useState(props.match.params.question_id);
    const [representative, setRepresentative] = useState(null);
    const [messages, setMessages] = useState(null);
    const [hash, setHash] = useState("none");
    const [shares, setShares] = useState(null);
    const resetLanguage = (event) => {
        localStorage.setItem('eholder-lang-'+meeting_id, event.target.value);
        messages.lang = event.target.value;
        setMessages({...messages});
    }

    // effect to reload meeting when changed hash
    useEffect( () => {
        (async () => {
            try {
                const meeting = await loadMeeting(meeting_id,question_id);
                setMeeting(meeting);
            } catch(error) {
                switch (error.response.status) {
                    case 401:
                        window.location = '/login/' + meeting_id;
                        break;
                    case 403:
                        window.location = '/meeting/'+meeting_id+'/pending';
                        break;

                    default:
                        setLoading(false);
                        break;
                }
            }
        })()
    },[meeting_id,question_id,hash]);

    // effect only once
    useEffect( () => {
        let intervalId = null;

        (async () => {
            try {
                // load the meeting the first time
                const meeting = await loadMeeting(meeting_id,question_id);

                // load messages
                if (meeting.messages) {
                    const load_messages = await axios({
                        method: "get",
                        url: meeting.messages
                    });
                    const messages = load_messages.data;
                    const nav_lang = navigator.language.split('-')[0];

                    messages.lang = messages.languages.length > 1 ? (localStorage.getItem('eholder-lang-' + meeting_id) || nav_lang) : nav_lang;
                    setMessages(messages);
                }


                //load user
                const me = await HTTPClient({
                    method: "get",
                    url:
                        process.env.REACT_APP_API_URL +
                        "/meetings/" + meeting_id + "/me"
                });

                const shares = await HTTPClient({
                    method: "get",
                    url:
                        process.env.REACT_APP_API_URL +
                        "/meetings/" + meeting_id + "/me/shares"
                });

                setShares(shares.data);


                const question = await loadQuestion(meeting_id,question_id);

                setMeeting(meeting);
                setQuestion(question);
                setRepresentative(me.data);
                setLoading(false);

                intervalId = setInterval(() => {
                    (async () => {
                        const got_hash = await HTTPClient({
                            method: "get",
                            url:
                                process.env.REACT_APP_API_URL +
                                "/meetings/" + meeting_id + "/hash"
                        });

                        setHash(got_hash.data);

                        const question = await loadQuestion(meeting_id,question_id);
                        setQuestion(question);
                    })();
                }, 3000);


            } catch(error) {

                switch (error.response.status) {
                    case 401:
                        window.location = '/login/' + meeting_id;
                        break;
                    case 403:
                        window.location = '/meeting/'+meeting_id+'/pending';
                        break;

                    default:
                        setLoading(false);
                        break;
                }
            }
        })();
        return (() => {
            clearInterval(intervalId);
        });
    }, [meeting_id,question_id]);

    return (
        <Fragment>
            {loading ? (
                <Loading/>
            ) : ((meeting && question && representative && shares) ? (
                <Fragment>
                    <Header messages={messages} user={representative} logo={meeting.logo}  resetLanguage={resetLanguage} meeting={meeting} shares={shares} />
                    <hr/>

                    <MeetingTitle messages={messages} meeting={meeting}/>

                    <BackButton meeting={meeting} messages={messages} href={"/meeting/" + meeting.slug + "/question/" + question_id}/>

                    <Box mb={2}>

                        <Typography variant={"h6"}>
                            {question.name}
                        </Typography>
                    </Box>

                    {(typeof question.acclamation !== 'undefined') ? (
                        <Container>
                            {question.acclamation ? (
                                <Typography variant={"h5"}>
                                    {getMessage(props.meeting.meeting_type,'approved_by_acclamation',props.messages)}
                                </Typography>
                            ) : (
                                <Typography variant={"h5"}>
                                    {getMessage(props.meeting.meeting_type,'refuse_by_acclamation',props.messages)}
                                </Typography>
                            )}


                        </Container>

                    ) : (
                        <SearchAnwers messages={messages} meeting={meeting} question={question} shares={shares}/>

                    )}
                    <Footer messages={messages} meeting={meeting}/>

                </Fragment>
            ) : (
                <Typography>
                    {getMessage('default', 'meeting_not_found', null)}
                </Typography>
            ))}


        </Fragment>

    )
}

export default QuestionAnswers;