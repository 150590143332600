import React from "react";
import Container from "@material-ui/core/Container";
import {getMessage} from "../../util";
import {makeStyles} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles(theme => ({
    top: {
        marginTop: theme.spacing(2)
    }
}));

const QuestionForbidden = (props) => {
    const classes = useStyles();

    return (
        <Container className={classes.top}>
            <Typography variant={"h6"}>
                {getMessage(props.meeting.meeting_type,'question_forbidden',props.messages)}

            </Typography>
        </Container>


    )
}

export default QuestionForbidden;