import React, {Component, Fragment} from "react";
import TableRow from "@material-ui/core/TableRow";
import {TableCell} from "@material-ui/core";
import Chip from "@material-ui/core/Chip";
import config from "../../config.json";
import {getMessage} from "../../util";


class RepresentativeItem extends Component {

    render() {
        const number = {
            fontFamily: "Montserrat"
        };

        return (
            <Fragment>
                <TableRow>
                    <TableCell>

                        {this.props.representative.identity}
                    </TableCell>
                    <TableCell>
                        {this.props.representative.name ? this.props.representative.name : 'no name'}
                    </TableCell>
                    <TableCell>
                        {Object.keys(this.props.representative.shares).map(s => (
                            <Fragment key={s}>
                                <span>{this.props.representative.shares[s].name}: <span style={number}>{this.props.representative.shares[s].shares.toLocaleString(navigator.language, {
                                    maximumFractionDigits: config.precision
                                })}</span></span>
                                <br/>
                            </Fragment>

                        ))}
                    </TableCell>
                    <TableCell>
                        {this.props.representative.represented_by.name ? this.props.representative.represented_by.name : 'no name'} <br/>
                        ({this.props.representative.represented_by.identity})
                    </TableCell>
                    <TableCell>
                        {this.props.representative.logged_before_quorum && (
                            <Chip label={getMessage(this.props.meeting.meeting_type, 'attendance_present_label', this.props.messages)}/>
                        )
                        }
                    </TableCell>
                </TableRow>
            </Fragment>
        );
    }
}


export default RepresentativeItem;