import Grid from "@material-ui/core/Grid";
import React, {Fragment} from "react";
import {makeStyles} from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import config from "../../config.json";
import {getMessage} from "../../util";

const useStyles = makeStyles(theme => ({
    paper: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        display: 'flex',
        alignItems: 'left',
    },
    number: {
        fontFamily: "Montserrat",
        "font-size":"1.3rem"
    }
}));

const sep = (() => {
    const n = 1.1;
    return(n.toLocaleString(navigator.language).substring(1, 2));
})();

const Shares = (props) => {
    const classes = useStyles();

    const total = Object.keys(props.meeting.shares).map(k => {
        if (props.meeting.shares[k].attendance) {
            return (props.meeting.shares[k].rights * props.meeting.shares[k].total);
        } else {
            return(0);
        }
    }).reduce((acc, elem) => {
        return (acc + elem);
    }, 0)

    const represented = Object.keys(props.meeting.shares).map(k => {
        if (props.meeting.shares[k].attendance) {
            return (props.meeting.shares[k].rights * props.meeting.shares[k].represented);
        } else {
            return(0);
        }
    }).reduce((acc, elem) => {
        return (acc + elem);
    }, 0);

    const represented_percentage = (100 * represented / total);
    const represented_percentage_k = Object.keys(props.meeting.shares).reduce((acc,k) => {
        acc[k] = (100*props.meeting.shares[k].represented / props.meeting.shares[k].total);
        return(acc);
    },{});


    let nines = "99".concat(sep);
    for (let i = 0; i < config.precision; i++) {
        nines = nines.concat("9")
    }
    nines = nines.concat("...");

    return (<Grid container spacing={1} className={classes.paper}>

        <Grid item xs={12} sm={4}>
            <Card variant="outlined">
                <CardContent>
                    <Typography  gutterBottom>
                        {getMessage(props.meeting.meeting_type,'total_attendance_shares',props.messages)}
                    </Typography>

                        <Fragment>
                            <Typography variant="h5" className={classes.number}>{props.meeting.meeting_type==="funding_sources"?"$":""}{total.toLocaleString(navigator.language, {
                                maximumFractionDigits: config.precision
                            })} </Typography>


                            {(props.meeting.shares[Object.keys(props.meeting.shares)[0]].rights !== 1 || Object.keys(props.meeting.shares).filter(k => props.meeting.shares[k].attendance).length > 1) && Object.keys(props.meeting.shares).map(k => (
                                (props.meeting.shares[k].attendance && <span key={k}> {getMessage(props.meeting.meeting_type,'shares',props.messages)} {props.meeting.shares[k].name}: <span className={classes.number}>{props.meeting.shares[k].total.toLocaleString(navigator.language, {
                                    maximumFractionDigits: config.precision
                                })}</span><br/></span>)
                            ))}
                        </Fragment>



                </CardContent>
            </Card>


        </Grid>
        <Grid item xs={12} sm={4}>
            <Card variant="outlined">
                <CardContent>
                    <Typography  gutterBottom>
                        {getMessage(props.meeting.meeting_type,'shares_present',props.messages)}
                    </Typography>

                        <Fragment>
                            <Typography variant="h5" className={classes.number}>{props.meeting.meeting_type==="funding_sources"?"$":""}{represented.toLocaleString(navigator.language, {
                                maximumFractionDigits: config.precision
                            })} </Typography>

                            {(props.meeting.shares[Object.keys(props.meeting.shares)[0]].rights !== 1 || Object.keys(props.meeting.shares).filter(k => props.meeting.shares[k].attendance).length > 1)  && Object.keys(props.meeting.shares).map(k => (
                                (props.meeting.shares[k].attendance &&
                                <span key={k} > {getMessage(props.meeting.meeting_type,'shares',props.messages)} {props.meeting.shares[k].name}: <span className={classes.number}>{props.meeting.shares[k].represented.toLocaleString(navigator.language, {
                                    maximumFractionDigits: config.precision
                                })}</span><br/></span>)
                            ))}
                        </Fragment>



                </CardContent>
            </Card>
        </Grid>
        <Grid item xs={12} sm={4}>

        <Card variant="outlined">
            <CardContent>
                <Typography  gutterBottom>
                    {getMessage(props.meeting.meeting_type,'shares_percentage',props.messages)}
                </Typography>

                    <Fragment>
                        <Typography variant="h5" className={classes.number}>{(represented_percentage > (100-1/Math.pow(10,config.precision)) && represented_percentage < 100)?nines:represented_percentage.toLocaleString(navigator.language, {
                            maximumFractionDigits: config.precision, minimumFractionDigits: 0
                        })}% </Typography>

                        {(props.meeting.shares[Object.keys(props.meeting.shares)[0]].rights !== 1 || Object.keys(props.meeting.shares).filter(k => props.meeting.shares[k].attendance).length > 1)  && Object.keys(props.meeting.shares).map(k => (
                            (props.meeting.shares[k].attendance && <span key={k}> {getMessage(props.meeting.meeting_type,'shares',props.messages)} {props.meeting.shares[k].name}: <span className={classes.number}>{(represented_percentage_k[k] > (100-1/Math.pow(10,config.precision)) && represented_percentage_k[k] < 100)?nines:represented_percentage_k[k].toLocaleString(navigator.language, {
                                maximumFractionDigits: config.precision
                            })}%</span><br/></span>)
                        ))}
                    </Fragment>



            </CardContent>
        </Card>
        </Grid>
    </Grid>);
}

export default Shares;