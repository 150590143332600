import React, {Component, Fragment} from "react";
import HTTPClient from "../../HTTPClient";
import Loading from "../Loading";
import RepresentativeList from "./RepresentativeList";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import {getMessage, loadMessages} from "../../util";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";

class Search extends Component {
    constructor(props) {
        super(props);
        this.state = {
            result: {
                total: 0,
                matches: 0,
                holders: []
            },
            readyState: false,
            disabledState: false,
            votedState: false,
            safeConduct: false,
            filter: null,
            page: 0,
            size: 25,
            messages: null,
            alert: null,
            loading: false,
            logged: false
        };
    }

    componentDidMount() {
        this.loadData(() => {
            if (this.state.meeting) {
                loadMessages(this.state.meeting.messages, (error, messages) => this.setState({messages: messages}));
            }
        });
    }

    doSearch = (event) => {
        event.preventDefault();
        this.loadData();
    };

    setPage = (page) => {
        this.setState({page: page}, this.loadData);
    };

    loadData = (cb) => {


        const data = {
            page: this.state.page,
            size: this.state.size,
            filter: this.state.filter === '' ? null : this.state.filter,
            safeConduct: this.state.safeConduct,
            logged: this.state.logged,
            unlogged: false,
            verified: false,
            unverified: false

        };

        this.setState({
                loading: true, voter: null, result: {
                    total: 0,
                    matches: 0,
                holders: []
                }, error: null
            }, () =>
                HTTPClient({
                    method: "post",
                    data: data,
                    url:
                        process.env.REACT_APP_API_URL +
                        "/meetings/" +
                        this.props.meeting.slug +
                        "/holders"
                }).then(response => {
                    console.log(response.data.holders);
                    this.setState({loading: false, result: response.data}, cb);
                })
                    .catch(error => {
                        if (error.response) {
                            if (error.response.status === 404) {
                                this.setState({
                                    loading: false,
                                    error: "Usuario " + this.state.voter_id.replace(/[^0-9kK-]/g, "").toUpperCase() + " no encontrado"
                                }, cb);
                            } else {
                                this.setState({loading: false, error: getMessage('default', 'unexpected_error', null)}, cb);
                            }
                        } else {
                            this.setState({
                                loading: false,
                                error: getMessage('default', 'backend_connect_error', null)
                            }, cb);
                        }
                    })
        );
    };

    checkInputChange = event => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value.replace(/-/g, "");
        const name = target.name;


        this.setState({
            [name]: value
        }, () => {
            this.loadData();
        });
    };

    handleInputChange = event => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value.replace(/-/g, "");
        const name = target.name;


        this.setState({
            [name]: value
        });
    };


    render() {

        return (
            <Fragment>

                {this.state.loading ? (
                    <Loading/>
                ) : (
                     <Fragment>
                        {this.state.error && (
                            <div className="alert alert-warning" role="alert">
                                <strong>{this.state.error}</strong>
                            </div>
                        )}

                         <Grid container spacing={1} >
                             <Grid item xs={12} sm={4}>
                                 <FormControlLabel control={<Checkbox name="logged" checked={this.state.logged}
                                                                      onChange={this.checkInputChange}/>}
                                                   label={getMessage(this.props.meeting.meeting_type, 'attendance_filter_label', this.props.messages)}/>
                             </Grid>
                             <Grid item xs={12} sm={8}>
                                 <TextField
                                     defaultValue={this.state.filter}
                                     variant="outlined"
                                     size="small"
                                     name="filter"
                                     onChange={this.handleInputChange}
                                 /> &nbsp;
                                 <Button variant="contained" color="primary" onClick={this.doSearch}>
                                     {getMessage(this.props.meeting.meeting_type, 'search', this.props.messages)}
                                 </Button>
                             </Grid>
                         </Grid>

                         <br/>

                        <Typography>
                            {getMessage(this.props.meeting.meeting_type, 'total', this.props.messages)}: {this.state.result.total.toLocaleString(navigator.language, {
                            minimumFractionDigits: 0
                        })}, &nbsp;
                            {getMessage(this.props.meeting.meeting_type, 'search_matched', this.props.messages)}: {this.state.result.matches.toLocaleString(navigator.language, {
                            minimumFractionDigits: 0
                        })}, &nbsp;
                            {getMessage(this.props.meeting.meeting_type, 'search_showing', this.props.messages).format(
                                (this.state.page * this.state.size + 1).toLocaleString(navigator.language, {
                                    minimumFractionDigits: 0
                                }),
                                (this.state.page * this.state.size + this.state.result.holders.length).toLocaleString(navigator.language, {
                                    minimumFractionDigits: 0
                                })
                            )}

                            &nbsp; &nbsp;

                            {this.state.page > 0 &&
                            <Button variant="outlined" size="small" color="primary"
                                    onClick={() => this.setPage(this.state.page - 1)}>&lt;&lt; {getMessage(this.props.meeting.meeting_type, 'search_before', this.props.messages)}</Button>
                            }

                            {((this.state.page + 1) * this.state.size) < this.state.result.matches &&
                            <Button variant="outlined" size="small" color="primary"
                                    onClick={() => this.setPage(this.state.page + 1)}>{getMessage(this.props.meeting.meeting_type, 'search_next', this.props.messages)} &gt;&gt;</Button>
                            }
                        </Typography>


                        <RepresentativeList
                            roles={this.props.roles}
                            result={this.state.result}
                            meeting={this.props.meeting}
                            messages={this.props.messages}
                            update={this.loadData}
                            drop_in_box={this.props.drop_in_box}

                        />
                    </Fragment>

                )}
            </Fragment>

        );
    }
}

export default Search;