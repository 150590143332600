import React from "react";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import VideoConference from "./VideoConferece";
import {makeStyles} from "@material-ui/core/styles";
import {getMessage} from "../../util";

const useStyles = makeStyles(theme => ({
    paper: {
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'left',
    },
    paragraph: {
        margin: theme.spacing(2)
    }
}));

const MeetingReady = (props) => {
    const classes = useStyles();

    return (
        <Container>
            <Typography className={classes.paragraph}>
                {getMessage(props.meeting.meeting_type,'meeting_ready',props.messages)}

            </Typography>
            {(props.meeting.video_conf_url || props.user.video_conf_url) && (
                <VideoConference messages={props.messages} url={props.user.video_conf_url?props.user.video_conf_url:props.meeting.video_conf_url} meeting={props.meeting} />
            )}


        </Container>
    )
}

export default MeetingReady;