import CircularProgress from "@material-ui/core/CircularProgress";
import React from "react";
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
    paper: {
        marginTop: theme.spacing(4),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    }
}));

const Loading = () => {
    const classes = useStyles();

    return (<Container component="main" maxWidth="xs">
            <CssBaseline/>
            <div className={classes.paper}>
                <CircularProgress color="inherit"/>
            </div>
        </Container>

    )

}

export default Loading;