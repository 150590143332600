import React, {Fragment, useEffect, useState} from "react";
import HTTPClient from "../../HTTPClient";
import Loading from "../Loading";
import Typography from "@material-ui/core/Typography";
import QuestionReady from "./QuestionReady";
import QuestionOpen from "./QuestionOpen";
import QuestionFinishedMultiserie from "./QuestionFinishedMultiserie";
import Header from "../Header";
import Shares from "../meeting/Shares";
import MeetingTitle from "../meeting/MeetingTitle";
import {getMessage} from "../../util";
import Footer from "../Footer";
import * as axios from "axios";
import VideoConference from "../meeting/VideoConferece";

const loadQuestion = async (meeting_id, question_id) => {
    const question = await HTTPClient({
        method: "get",
        url:
            process.env.REACT_APP_API_URL +
            "/meetings/" + meeting_id + "/questions/" + question_id
    });
    return (question.data);
}

export const loadMeeting = async (meeting_id, question_id) => {
    const meeting = await HTTPClient({
        method: "get",
        url:
            process.env.REACT_APP_API_URL +
            "/meetings/" + meeting_id
    });

    //check if published
    if (!meeting.data.questions.map(q => q.slug).includes(question_id)) {
        window.location = '/meeting/' + meeting.data.slug
    }

    // check if open question
    const open_questions = meeting.data.questions.filter(q => {
        return (!q.url && q.state === 'open')
    });

    if (open_questions.length > 0) {
        window.location = '/meeting/' + meeting.data.slug + '/questions';
    }

    return (meeting.data);
}


const loadHolders = async (meeting_id) => {

    const holders = await HTTPClient({
        method: "get",
        url:
            process.env.REACT_APP_API_URL +
            "/meetings/" + meeting_id + "/me/holders"
    });

    return (holders.data);

}

const Question = (props) => {

    const [loading, setLoading] = useState(true);
    const [question, setQuestion] = useState(null);
    const [meeting, setMeeting] = useState(null);
    const [meeting_id] = useState('76w8xLOm');
    const [question_id] = useState(props.match.params.question_id);
    const [representative, setRepresentative] = useState(null);
    const [messages, setMessages] = useState(null);
    const [hash, setHash] = useState("none");
    const [shares, setShares] = useState(null);
    const [holders, setHolders] = useState([]);
    const resetLanguage = (event) => {
        localStorage.setItem('eholder-lang-'+meeting_id, event.target.value);
        messages.lang = event.target.value;
        setMessages({...messages});
    }

    // effect to reload meeting when changed hash
    useEffect(() => {
        (async () => {
            try {
                const meeting = await loadMeeting(meeting_id, question_id);
                setMeeting(meeting);
            } catch (error) {
                switch (error.response.status) {
                    case 401:
                        window.location = '/login/' + meeting_id;
                        break;
                    case 403:
                        window.location = '/meeting/' + meeting_id + '/pending';
                        break;
                    default:
                        break;
                }
            }
        })()
    }, [meeting_id, question_id, hash]);

    // effect only once
    useEffect(() => {
            let intervalId = null;

            (async () => {
                    try {
                        // load the meeting the first time
                        const meeting = await loadMeeting(meeting_id, question_id);

                        // load messages
                        if (meeting.messages) {
                            const load_messages = await axios({
                                method: "get",
                                url: meeting.messages
                            });
                            const messages = load_messages.data;
                            const nav_lang = navigator.language.split('-')[0];

                            messages.lang = messages.languages.length > 1 ? (localStorage.getItem('eholder-lang-' + meeting_id) || nav_lang) : nav_lang;
                            setMessages(messages);
                        }


                        //load user
                        const me = await HTTPClient({
                            method: "get",
                            url:
                                process.env.REACT_APP_API_URL +
                                "/meetings/" + meeting_id + "/me"
                        });

                        const shares = await HTTPClient({
                            method: "get",
                            url:
                                process.env.REACT_APP_API_URL +
                                "/meetings/" + meeting_id + "/me/shares"
                        });

                        setShares(shares.data);

                        const question = await loadQuestion(meeting_id, question_id);

                        setMeeting(meeting);
                        setQuestion(question);
                        setRepresentative(me.data);

                        const holders = await loadHolders(meeting_id);
                        setHolders(holders);

                        setLoading(false);

                        intervalId = setInterval(() => {
                                (async () => {
                                    try {
                                        const got_hash = await HTTPClient({
                                            method: "get",
                                            url:
                                                process.env.REACT_APP_API_URL +
                                                "/meetings/" + meeting_id + "/hash"
                                        });

                                        setHash(got_hash.data);

                                        const question = await loadQuestion(meeting_id, question_id);
                                        setQuestion(question);
                                    } catch (error) {
                                        switch (error.response.status) {
                                            case 401:
                                                window.location = '/login/' + meeting_id;
                                                break;
                                            case 403:
                                                window.location = '/meeting/' + meeting_id + '/pending';
                                                break;
                                            default:
                                                setLoading(false);
                                                break;
                                        }
                                    }
                                })();
                            }
                            ,
                            3000
                        );


                    } catch
                        (error) {

                        switch (error.response.status) {
                            case 401:
                                window.location = '/login/' + meeting_id;
                                break;
                            case 403:
                                window.location = '/meeting/' + meeting_id + '/pending';
                                break;
                            default:
                                setLoading(false);
                                break;
                        }
                    }
                }
            )
            ();
            return (() => {
                clearInterval(intervalId);
            });
        },
        [meeting_id, question_id]
    )
    ;


    const getState =
        () => {
            switch (question.state) {

                case 'open':
                    return (
                        <QuestionOpen
                            question={question}
                            user={representative}
                            meeting={meeting}
                            shares={shares}
                            messages={messages}
                            holders={holders}
                            onTime={() => {
                                (async () => setQuestion(await loadQuestion(meeting_id, question_id)))()
                            }}
                        />);
                case 'finished':
                    return (


                        <QuestionFinishedMultiserie messages={messages} question={question} user={representative}
                                                    meeting={meeting} volver={true}/>


                    );
                default:
                    return (
                        <QuestionReady messages={messages} question={question} user={representative} meeting={meeting}
                                       shares={shares}/>);
            }
        }

    return (
        <Fragment>
            {loading ? (
                <Loading/>
            ) : ((meeting && question && representative && shares) ? (
                <Fragment>
                    <Header messages={messages} user={representative} logo={meeting.logo} meeting={meeting}  resetLanguage={resetLanguage}
                            shares={shares} hide_logout={representative.voted.includes(question.slug) && question.state === 'open'}/>
                    <hr/>


                    <MeetingTitle messages={messages} meeting={meeting}/>

                    <Shares messages={messages} meeting={meeting}/>
                    {(meeting && representative && (meeting.video_conf_url || representative.video_conf_url)) && (
                        <VideoConference messages={messages}
                                         url={representative.video_conf_url ? representative.video_conf_url : meeting.video_conf_url}
                                         meeting={meeting}/>
                    )}
                    {meeting && representative && shares && getState()}

                    <Footer messages={messages} meeting={meeting}/>

                </Fragment>
            ) : (
                <Typography>
                    {getMessage('default', 'meeting_not_found', null)}
                </Typography>
            ))}


        </Fragment>

    )
}

export default Question;