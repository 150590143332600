import React, {Component, Fragment} from "react";
import {TableCell} from "@material-ui/core";
import Chip from "@material-ui/core/Chip";
import config from "../../config.json";
import {getMessage} from "../../util";

class AnswerItem extends Component {

    getState = (state) => {
        switch (state) {
            case 'open':
                return (
                    <Chip label={getMessage(this.props.meeting.meeting_type, 'question_open', this.props.messages)}/>);
            case 'finished':
                return (<Chip
                    label={getMessage(this.props.meeting.meeting_type, 'question_finished', this.props.messages)}/>);
            default:
                return (
                    <Chip label={getMessage(this.props.meeting.meeting_type, 'question_ready', this.props.messages)}/>);

        }
    }


    render() {

        const voted = this.props.representative.answers[this.props.question.slug] ? true : false;

        const options = voted ? this.props.representative.answers[this.props.question.slug].options.reduce((acc, elem) => {
            acc[elem._id] = elem;
            return (acc);
        }, {}) : {};

        const number = {
            fontFamily: "Montserrat",
            fontSize: "1.3rem"
        };

        const ColSpan = this.props.question.nullable ? 2 : 1;
        return (
            <Fragment>
                <TableCell>
                    {this.props.representative.name ? this.props.representative.name : 'no name'} ({this.props.representative.identity})
                    {this.props.representative.answers[this.props.question.slug] && (
                        <p>
                            {getMessage(this.props.meeting.meeting_type, 'voted_by', this.props.messages)}: {this.props.representative.answers[this.props.question.slug].voted_by.name ? this.props.representative.answers[this.props.question.slug].voted_by.name : "no name"} ({this.props.representative.answers[this.props.question.slug].voted_by.identity})
                        </p>
                    )

                    }

                </TableCell>
                {this.props.common_shares.length > 0 ? (
                        voted ? (
                            <Fragment>
                                {this.props.question.options.sort((a, b) => {
                                    return (a.order - b.order);
                                }).map(o => (
                                    <TableCell key={o._id}>
                                        {options[o._id] && Object.keys(options[o._id].votes).map(s => (
                                            <Fragment key={s}>
                                                <span>{this.props.representative.shares[s].name}: </span>
                                                <span
                                                    style={number}> {options[o._id].votes[s].shares.toLocaleString(navigator.language, {
                                                    maximumFractionDigits: config.precision
                                                })}</span>
                                                <br/>
                                            </Fragment>
                                        ))}
                                    </TableCell>
                                ))}
                                {this.props.question.nullable && <TableCell>
                                    {this.props.common_shares.map(s => (
                                        <Fragment key={s}>
                                            <span>{this.props.representative.shares[s].name}: </span>
                                            <span
                                                style={number}> {(this.props.representative.answers[this.props.question.slug].nulls[s].shares).toLocaleString(navigator.language, {
                                                maximumFractionDigits: config.precision
                                            })}</span>
                                            <br/>
                                        </Fragment>
                                    ))}
                                </TableCell>}
                                {this.props.question.max_marks !== this.props.question.min_marks && <TableCell>
                                    {this.props.common_shares.map(s => (
                                        <Fragment key={s}>
                                            <span>{this.props.representative.shares[s].name}: </span>
                                            <span
                                                style={number}> {(this.props.representative.answers[this.props.question.slug].blanks[s].shares).toLocaleString(navigator.language, {
                                                maximumFractionDigits: config.precision
                                            })}</span>
                                            <br/>
                                        </Fragment>
                                    ))}
                                </TableCell>}
                            </Fragment>
                        ) : (<Fragment>
                                <TableCell colSpan={this.props.question.options.length + ColSpan}>
                                    No votó
                                </TableCell>

                            </Fragment>
                        )
                    ) : (
                        <TableCell colSpan={this.props.question.options.length + ColSpan}>
                            {getMessage(this.props.meeting.meeting_type, 'question_no_right', this.props.messages)}
                        </TableCell>
                    )}

            </Fragment>
        );
    }
}


export default AnswerItem;