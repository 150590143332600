import React, {Component, Fragment} from "react";
import {TableCell} from "@material-ui/core";
import {Done} from "@material-ui/icons";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import {getMessage} from "../../util";


class AnswerStateItem extends Component {


    render() {

        const voted = this.props.representative.answers[this.props.question.slug] ? true : false;

        return (
            <Fragment>
                <TableCell>
                    {this.props.representative.identity}
                </TableCell>
                <TableCell>
                    {this.props.representative.name ? this.props.representative.name : 'no name'}
                </TableCell>
                <TableCell>
                    {this.props.representative.represented_by.name ? this.props.representative.represented_by.name : 'no name'} {(this.props.representative.represented_by.identity)}
                </TableCell>
                {(this.props.representative.logged_before_quorum || voted)?(
                        this.props.common_shares.length > 0 ? (
                    voted ? (
                            <TableCell>
                                <ListItemIcon>
                                    <Done/>
                                </ListItemIcon> {getMessage(this.props.meeting.meeting_type, 'vote_sent', this.props.messages)}
                                {this.props.representative.represented_by.identity !== this.props.representative.answers[this.props.question.slug].voted_by.identity && (<p>
                                    {getMessage(this.props.meeting.meeting_type, 'voted_by', this.props.messages)}: {this.props.representative.answers[this.props.question.slug].voted_by.name?this.props.representative.answers[this.props.question.slug].voted_by.name:"no name"} ({this.props.representative.answers[this.props.question.slug].voted_by.identity})
                                </p>)}
                            </TableCell>
                    ) : (this.props.question.state === 'finished'?(
                            <TableCell>
                                {getMessage(this.props.meeting.meeting_type, 'no_voted', this.props.messages)}
                            </TableCell>
                    ):(
                        <TableCell>
                            {getMessage(this.props.meeting.meeting_type, 'vote_pending', this.props.messages)}
                        </TableCell>
                    ))
                ):(
                    <TableCell>
                        {getMessage(this.props.meeting.meeting_type, 'question_no_right', this.props.messages)}
                    </TableCell>
                )):(
                    <TableCell >
                        {getMessage(this.props.meeting.meeting_type, 'question_no_attendance', this.props.messages)}
                    </TableCell>
                )}

            </Fragment>
        );
    }
}


export default AnswerStateItem;