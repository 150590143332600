import React, { useState} from "react";
import Container from "@material-ui/core/Container";
import BadAnswered from "./responses/BadAnswered";
import Blocked from "./responses/Blocked";
import Unknown from "./responses/Unknown";
import CssBaseline from "@material-ui/core/CssBaseline";
import Button from "@material-ui/core/Button";
import {makeStyles} from "@material-ui/core/styles";
import HTTPClient from "../HTTPClient";
import Loading from "./Loading";
import NotInMeetingRoll from "./responses/NotInMeetingRoll";
import Late from "./responses/Late";
import AskPhone from "./responses/AskPhone";
import {getMessage} from "../util";
import {FormControl, FormControlLabel, FormLabel, Radio, RadioGroup} from "@material-ui/core";
import AnotherDevice from "./responses/AnotherDevice";
import Recaptcha from "./responses/Recaptcha";

const useStyles = makeStyles(theme => ({
        paper: {
            marginTop: theme.spacing(4),
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        },
        question: {
            marginTop: theme.spacing(3),
            marginBottom: theme.spacing(1)
        },
        grid: {
            alignItems: 'center',
        },
        form: {
            width: '100%', // Fix IE 11 issue.
            marginTop: theme.spacing(1)
        },
        submit: {
            margin: theme.spacing(3, 0, 2),
        },
        title: {
            fontSize: '1.2rem',
            color: 'rgba(0, 0, 0, 0.54)',
            marginBottom: theme.spacing(2)
        },
        option: {
            fontSize: '1.2rem',
            color: "black"
        },
        option_check: {
            marginLeft: theme.spacing(2)
        }
    }
));

const Challenges = (props) => {
    const classes = useStyles();

    const [responses, setResponses] = useState(new Map());
    const [status, setStatus] = useState('ok');
    const [loading, setLoading] = useState(false);
    const [reLoading, setReloading] = useState(false);
    const [jwt, setJwt] = useState(null);
    const [reason, setReason] = useState(null);
    const [myMistake, setMyMistake] = useState(false);
    const [wrongCounter, setWrongCounter] = useState(0);

    const login = () => {
        setStatus('ok');

        const connect = (data) =>HTTPClient({
            method: "post",
            data: data,
            url:
                process.env.REACT_APP_API_URL +
                "/login/get_questions"
        })
            .then(response => {
                setReloading(false);
                switch (response.data.status) {
                    case 'ok':
                        document.getElementById("theform").reset();
                        props.setChallenges({
                            rut: props.challenges.rut,
                            serial: props.challenges.serial,
                            challenges: response.data
                        });
                        return;
                    case 'wrong':
                        setJwt(response.data.message);
                        setReason("segundo intento con serial malo en respuestas");
                        setStatus('askphone');
                        setMyMistake(true);
                        // setStatus('wrongSerial');
                        return;
                    case 'blocked':
                        setJwt(response.data.message);
                        setReason("bloqueado");
                        setStatus('askphone');
                        //setMyMistake(true);
                        //setStatus('blocked');
                        return;
                    case 'temporarily_blocked':
                        setJwt(response.data.message);
                        setReason("temporalmente bloqueeado");
                        setStatus('askphone');
                        //setStatus('temporarily_blocked');
                        return;
                    case 'noquestions':
                        setJwt(response.data.message);
                        setReason("no pudo generar preguntas");
                        setStatus('askphone');
                        //setStatus('noquestions');
                        return;
                    case 'recaptcha':
                        setStatus('recaptcha');
                        return;
                    case 'onlypeople':
                        setStatus('onlypeople');
                        return;
                    case 'safeconduct':
                        window.location = props.meeting ? '/meeting/' + props.meeting.slug : '/';
                        return;
                    default:
                        setJwt(response.data.message);
                        setReason("error desconocido");
                        setStatus('askphone');
                        // setStatus('unknown');
                        return;
                }
            })
            .catch((error) => {
                if (error.response)
                    switch (error.response.status) {
                        case 403:
                            setStatus('another');
                            setLoading(false);
                            return;
                        default:
                            break;
                    }
                setStatus('unknown');
                setLoading(false);

            });


        setReloading(true);

        if (process.env.REACT_APP_RECAPTCHA_ENABLED !== "false") {
            window.grecaptcha.enterprise.ready(async () => {
                window.grecaptcha.enterprise.execute(process.env.REACT_APP_RECAPTCHA_SITE, {action: 'ask_data'}).then(token => {
                    const data = {
                        identity: props.challenges.rut,
                        password: props.challenges.serial,
                        meeting_id: props.meeting.slug,
                        gRecaptchaResponse: token
                    }
                    connect(data);
                })
            })
        } else {
            const data = {
                identity: props.challenges.rut,
                password: props.challenges.serial,
                meeting_id: props.meeting.slug
            }

            connect(data);
        }

    };

    const switchDevice = () => {
        setLoading(true);
        HTTPClient({
            method: "get",
            url:
                process.env.REACT_APP_API_URL +
                "/switch/"+props.meeting.slug
        })
            .then(response => {
                setStatus('ok');
                window.location = props.meeting ? '/meeting/' + props.meeting.slug : '/';
            })
            .catch((error) => {
                setStatus('unknown');
                setLoading(false);
            });
    }

    const answer = (event) => {
        event.preventDefault();

        if (!props.challenges.challenges.questions.questions.reduce((acc, elem) => {
            return (acc && responses.has(elem.id));
        }, true)) {
            alert(getMessage(props.meeting.meeting_type, 'challenges_must_answer', props.messages));
            return;
        }

                const connect = (data) => HTTPClient({
            method: "post",
            data: data,
            url:
                process.env.REACT_APP_API_URL +
                "/login/verify_answers"
        })
            .then(response => {
                setLoading(false);
                switch (response.data.status) {
                    case 'ok':
                        window.location =  '/';
                        return;
                    case 'wrong':
                        if (wrongCounter > 0) {
                            setJwt(response.data.message);
                            setReason("segundo intento con respuestas malas");
                            setStatus('askphone');

                        } else {
                            setWrongCounter(wrongCounter + 1);
                            setStatus('wrongAnswered');
                        }
                        return;
                    case 'blocked':
                        setJwt(response.data.message);
                        setReason("segundo intento con serial malo");
                        setStatus('askphone');
                        //setMyMistake(true);

                        //setStatus('blocked');
                        return;
                    case 'notfound':
                        setStatus('notfound');
                        return;
                    case 'recaptcha':
                        setStatus('recaptcha');
                        return;
                    case 'quorum':
                        setStatus('late');
                        return;
                    default:
                        setJwt(response.data.message);
                        setReason("segundo intento con serial malo");
                        setStatus('askphone');
                        setMyMistake(true);
                        //setStatus('unknown');
                        return;
                }
            })
            .catch((error) => {
                if (error.response)
                    switch (error.response.status) {
                        case 403:
                            setStatus('another');
                            setLoading(false);
                            return;
                        default:
                            break;
                    }
                setStatus('unknown');
                setLoading(false);

            });

        setLoading(true);

        if (process.env.REACT_APP_RECAPTCHA_ENABLED !== "false") {
            window.grecaptcha.enterprise.ready(async () => {
                window.grecaptcha.enterprise.execute(process.env.REACT_APP_RECAPTCHA_SITE, {action: 'verify_answers'}).then(token => {
                    const data = {
                        "jwt": props.challenges.challenges.message,
                        "idchallenge": props.challenges.challenges.questions.idchallenge,
                        "answers": Array.from(responses.keys()).map(key => {
                            return ({name: key, value: responses.get(key)});
                        }),
                        gRecaptchaResponse: token
                    }

                    connect(data);
                })
            })
        } else {
            const data = {
                "jwt": props.challenges.challenges.message,
                "idchallenge": props.challenges.challenges.questions.idchallenge,
                "answers": Array.from(responses.keys()).map(key => {
                    return ({name: key, value: responses.get(key)});
                })
            }

            connect(data);
        }
    }

    const handleClose = () => {
        setStatus('ok');
        props.setChallenges(null);
    }

    const handleChange = (event) => {
        setResponses((prev) => new Map(responses).set(event.target.name,event.target.value));
    }

    return (
        <Container component="main" maxWidth="xs">
            {status === 'wrongAnswered' &&
            <BadAnswered meeting={props.meeting} messages={props.messages} open={status === 'wrongAnswered'}
                         handleClose={() => login()}/>}
            {status === 'blocked' &&
            <Blocked meeting={props.meeting} messages={props.messages} open={status === 'blocked'}
                     handleClose={handleClose}/>}
            {status === 'unknown' &&
            <Unknown meeting={props.meeting} messages={props.messages} open={status === 'unknown'}
                     handleClose={handleClose}/>}
            {status === 'notfound' &&
            <NotInMeetingRoll messages={props.messages} open={status === 'notfound'} handleClose={handleClose}
                              meeting={props.meeting}/>}
            {status === 'late' && <Late messages={props.messages} open={status === 'late'} handleClose={handleClose}
                                        meeting={props.meeting}/>}
            {status === 'askphone' &&
            <AskPhone meeting={props.meeting} messages={props.messages} reason={reason} jwt={jwt} my_mistake={myMistake}
                      handleClose={handleClose}/>}
            {status === 'another' && <AnotherDevice messages={props.messages} open={true} handleClose={() => setStatus('ok')} meeting={props.meeting} switchDevice={switchDevice}/>}
            {status === 'recaptcha' &&
            <Recaptcha meeting={props.meeting} messages={props.messages} open={status === 'recaptcha'}
                       handleClose={() => setStatus('ok')}/>}
            <CssBaseline/>
            <div className={classes.paper}>

                <div variant="h5" className={classes.title}>
                    {getMessage(props.meeting.meeting_type, 'challenges_answer', props.messages)}
                </div>
                {reLoading?(
                    <Loading/>
                ):(<form className={classes.form} noValidate id={"theform"}>
                    <input type="hidden" name="idchallenge" value={props.challenges.challenges.questions.idchallenge}/>
                    {props.challenges.challenges.questions.questions.map(q => (
                        <FormControl key={q.id} component="fieldset" className={classes.question}>
                            <FormLabel className={classes.option}>{q.question}</FormLabel>
                            <RadioGroup aria-label="gender" name={q.id}  onChange={handleChange}>
                                {q.options.map(o => (
                                    <FormControlLabel key={o.id}  control={<Radio value={o.id} className={classes.option_check}/>} label={o.option}/>
                                ))}
                            </RadioGroup>
                        </FormControl>

                    ))}


                    {loading ? (
                        <Loading/>
                    ) : (
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                            onClick={answer}
                        >
                            {getMessage(props.meeting.meeting_type, 'challenges_answer_button', props.messages)}
                        </Button>
                    )}

                    <Button
                        type="submit"
                        variant="outlined"
                        onClick={() => {
                            props.setChallenges(null);
                        }
                        }
                    >
                        {getMessage(props.meeting.meeting_type, 'cancel', props.messages)}
                    </Button>
                </form>)}
            </div>


        </Container>

    );
}

export default Challenges;