import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import React from "react";
import {getMessage} from "../util";

const BackButton = (props) => {
    return( <Box mb={2} mt={2}>
        <Button variant="contained" color={"default"}
                href={props.href}>{getMessage(props.meeting.meeting_type,'back',props.messages)}</Button>
    </Box>);
}

export default BackButton