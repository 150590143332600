import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import {HowToVote} from "@material-ui/icons";
import ListItemText from "@material-ui/core/ListItemText";
import React from "react";
import Chip from "@material-ui/core/Chip";
import {getMessage} from "../../util";
import Grid from "@material-ui/core/Grid";

const QuestionItem = (props) => {

    const getState = (state) => {
        switch (state) {
            case 'open':
                return (<Chip label={getMessage(props.meeting.meeting_type, 'question_open', props.messages)}/>);
            case 'finished':
                return (<Chip label={getMessage(props.meeting.meeting_type, 'question_finished_results', props.messages)}/>);
            default:
                return (<Chip label={getMessage(props.meeting.meeting_type, 'question_ready', props.messages)}/>);

        }
    }

    function ListItemLink(props) {
        return <ListItem button component="a" {...props} />;
    }

    return ((typeof props.question.acclamation !== 'undefined') ? (
                <ListItemLink>
                    <ListItemIcon>
                        <HowToVote/>
                    </ListItemIcon>
                    <ListItemText
                        primary={
                            <Grid
                                justify="space-between"
                                container
                            >
                                <Grid item>
                                    {props.question.name}
                                </Grid>

                                <Grid item>
                                    <Chip label={getMessage(props.meeting.meeting_type, props.question.acclamation?'approved_by_acclamation':'refuse_by_acclamation', props.messages)}/>
                                </Grid>
                            </Grid>}
                    />

                </ListItemLink>
            ) : (
                <ListItemLink href={"/meeting/" + props.meeting.slug + "/question/" + props.question.slug}>
                    <ListItemIcon>
                        <HowToVote/>
                    </ListItemIcon>
                    <ListItemText
                        primary={
                            <Grid
                                justify="space-between"
                                container
                            >
                                <Grid item xs={12} sm={9}>
                                    {props.question.name}
                                </Grid>

                                <Grid item xs={12} sm={3}>
                                    {getState(props.question.state)}
                                </Grid>
                            </Grid>}
                    />
                </ListItemLink>
            ))
}

export default QuestionItem;