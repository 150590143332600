import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Button from "@material-ui/core/Button";
import React, {Fragment} from "react";
import {makeStyles} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import Chip from "@material-ui/core/Chip";
import config from "../../config.json";
import {getMessage} from "../../util";

const useStyles = makeStyles(theme => ({
        paper: {
            marginTop: theme.spacing(4),
            marginBottom: theme.spacing(2),
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'left',
        },
        paragraph: {
            margin: theme.spacing(2)
        },
        cardGrid: {
            paddingTop: theme.spacing(8),
            paddingBottom: theme.spacing(8),
        },
        card: {
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
        },
        cardActions: {
            justifyContent: 'center',
        },
        cardContent: {
            flexGrow: 1,
        },
        option: {
            margin: theme.spacing(3),
            display: 'flex',
            flexDirection: 'column',

        },
        label: {
            alignItems: 'left',
        },
        textField: {
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(1),
            width: '25ch',
        },
        number: {
            fontFamily: "monospace"
        }

    }
));

const QuestionReady = (props) => {
    const classes = useStyles();

    return (
                <Card variant="outlined" className={classes.card}>
                    <CardHeader title={props.question.name}/>
                    <CardContent className={classes.cardContent}>
                        <Grid container spacing={1}>
                            <Grid item xs={12} sm={6} md={4}>

                                <Typography>{getMessage(props.meeting.meeting_type,'shares_to_vote',props.messages)}:<br/>

                                    {Object.keys(props.question.blanks).map(k => (
                                        <Fragment key={k}>
                                            {props.question.blanks[k].name}: <span> {props.question.blanks[k].shares.toLocaleString(navigator.language, {
                                            maximumFractionDigits: config.precision
                                        })}</span>
                                            <br/>
                                        </Fragment>
                                    ))}

                                    </Typography>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                                {getMessage(props.meeting.meeting_type,'state',props.messages)}: <Chip label={getMessage(props.meeting.meeting_type,'question_ready',props.messages)}/>
                            </Grid>
                        </Grid>

                        <Typography  variant={"h5"}>
                            {getMessage(props.meeting.meeting_type,'options',props.messages)}:
                        </Typography>
                        <form className={classes.form} noValidate>
                            <Grid container spacing={1} alignItems={"center"}>

                                {props.question.options.sort((a,b) => {return(a.order-b.order);}).map(o => (
                                    <Container key={o._id} className={classes.option}>

                                        <Typography  key={o._id}>
                                            {o.name}
                                        </Typography>

                                    </Container>


                                ))}
                            </Grid>

                        </form>
                    </CardContent>
                    <CardActions className={classes.cardActions}>
                        <Button variant="contained" color={"default"} href={"/meeting/"+props.meeting.slug}>
                            {getMessage(props.meeting.meeting_type,'back',props.messages)}
                        </Button>
                    </CardActions>
                </Card>
    )
}

export default QuestionReady;