import React, {useRef, useState} from "react";
import {getDv, getMessage} from "../util";
import Container from "@material-ui/core/Container";
import WrongSerial from "./responses/WrongSerial";
import Blocked from "./responses/Blocked";
import Unknown from "./responses/Unknown";
import NoQuestions from "./responses/NoQuestions";
import CssBaseline from "@material-ui/core/CssBaseline";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import carnet_nuevo from "../images/ci_new.png";
import carnet_viejo from "../images/ci_old.png";
import {makeStyles} from "@material-ui/core/styles";
import HTTPClient from "../HTTPClient";
import Loading from "./Loading";
import NoPerson from "./responses/NoPerson";
import TemporalBlocked from "./responses/TemporalBlocked";
import NotInMeetingRoll from "./responses/NotInMeetingRoll";
import Late from "./responses/Late";

import AskPhone from "./responses/AskPhone";
import AnotherDevice from "./responses/AnotherDevice";
import Recaptcha from "./responses/Recaptcha";


const useStyles = makeStyles(theme => ({
        paper: {
            marginTop: theme.spacing(4),
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        },
        question: {
            marginTop: theme.spacing(4),
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        },
        checkboxLabel: {
            fontSize: '1.5rem'
        },
        grid: {
            alignItems: 'center',
        },

        carnet: {
            width: '100%',
            maxWidth: '200px',
            display: 'block',
            'margin-left':
                'auto',
            'margin-right':
                'auto',
        },
        form: {
            width: '100%', // Fix IE 11 issue.
            marginTop: theme.spacing(1)
        },
        submit: {
            margin: theme.spacing(3, 0, 2),
        },
    }
));

export const RutSerial = (props) => {
    const classes = useStyles();

    const rutRef = useRef();
    const serialRef = useRef();

    const [rutText, setRutText] = useState('');
    const [serialText, setSerialText] = useState('');
    const [status, setStatus] = useState('ok');
    const [jwt, setJwt] = useState(null);
    const [reason, setReason] = useState(null);
    const [myMistake, setMyMistake] = useState(false);
    const [wrongCounter, setWrongCounter] = useState(0);
    const [loading, setLoading] = useState(false);


    const login = (event) => {
        event.preventDefault();

        const rutNoFormat = rutRef.current.value.replace(/[^0-9kK]/g, "").toUpperCase();
        const serialNoFormat = serialRef.current.value.replace(/[^0-9a-zA-Z]/g, "").toUpperCase();

        const dv = rutNoFormat.charAt(rutNoFormat.length - 1);

        const rutInvalid = !rutNoFormat || (dv.toUpperCase() !== getDv(rutNoFormat.substring(0, rutNoFormat.length - 1)).toString().toUpperCase());
        const serialInvalid = !(serialNoFormat && (/^(C[A-NP-Z1-9]{5})$/.test(serialNoFormat) || /^(A0\d{8})$/.test(serialNoFormat) || /^(\d{9})$/.test(serialNoFormat)));

        if (rutInvalid || serialInvalid) {
            setRutText((!rutNoFormat) ? getMessage(props.meeting.meeting_type,'must_put_rut',props.messages) : (rutInvalid ? getMessage(props.meeting.meeting_type,'wrong_input_rut',props.messages) : ""));
            setSerialText((!serialNoFormat) ? getMessage(props.meeting.meeting_type,'must_put_serial',props.messages) : (serialInvalid ? getMessage(props.meeting.meeting_type,'wrong_input_serial',props.messages) : ""));
            return;
        }

        const connect = (data) => HTTPClient({
            method: "post",
            data: data,
            url:
                process.env.REACT_APP_API_URL +
                "/login/get_questions"
        })
            .then(response => {
                setLoading(false);

                switch (response.data.status) {
                    case 'ok':
                        props.setChallenges({
                            rut: rutNoFormat,
                            serial: serialNoFormat,
                            challenges: response.data
                        });
                        return;
                    case 'wrong':
                        if (wrongCounter > 0) {
                            setJwt(response.data.message);
                            setReason("segundo intento con serial malo");
                            setStatus('askphone');
                        } else {
                            setWrongCounter(wrongCounter+1);
                            setStatus('wrongSerial');
                        }
                        return;
                    case 'blocked':
                        setJwt(response.data.message);
                        setReason("representante bloqueado");
                        setMyMistake(true);
                        setStatus('askphone');

                        // setStatus('blocked');
                        return;
                    case 'temporarily_blocked':
                        setJwt(response.data.message);
                        setReason("representante temporalmente bloqueado");
                        setStatus('askphone');
                        setMyMistake(true);
                        //setStatus('temporarily_blocked');
                        return;
                    case 'noquestions':
                        setJwt(response.data.message);
                        setReason("representante temporalmente bloqueado");
                        setMyMistake(true);
                        setStatus('askphone');
                        //setStatus('noquestions');
                        return;
                    case 'onlypeople':
                        setStatus('onlypeople');
                        return;
                    case 'notfound':
                        setStatus('notfound');
                        return;
                    case 'quorum':
                        setStatus('late');
                        return;
                    case 'recaptcha':
                        setStatus('recaptcha');
                        return;
                    case 'safeconduct':
                        window.location = '/';
                        return;
                    default:
                        setJwt(response.data.message);
                        setReason("razón desconocida");
                        setMyMistake(true);
                        setStatus('askphone');

                        //setStatus('unknown');
                        return;
                }
            })
            .catch((error) => {
                if (error.response)
                    switch (error.response.status) {
                        case 403:
                            setStatus('another');
                            setLoading(false);
                            return;
                        default:
                            break;
                    }
                setStatus('unknown');
                setLoading(false);

            });

        setLoading(true);

        if (process.env.REACT_APP_RECAPTCHA_ENABLED !== "false") {
            window.grecaptcha.enterprise.ready(async () => {
                window.grecaptcha.enterprise.execute(process.env.REACT_APP_RECAPTCHA_SITE, {action: 'ask_data'}).then(token => {
                    const data = {
                        identity: rutNoFormat,
                        password: serialNoFormat,
                        meeting_id: props.meeting.slug,
                        gRecaptchaResponse: token
                    }
                    connect(data);
                })
            })
        } else {
            const data = {
                identity: rutNoFormat,
                password: serialNoFormat,
                meeting_id: props.meeting.slug
            }
            connect(data);
        }


    }

    const setSerialInput = () => {
        /*
        if (isControlKey(event)) {
            return;
        }
         */

        const noFormat = serialRef.current.value.replace(/[^0-9a-zA-Z]/g, "").toUpperCase();
        let withFormat = '';
        switch (noFormat.charAt(0)) {
            case 'C':
                withFormat = noFormat.replace(/[^A-Z0-9]/g, "").toUpperCase();
                break;
            case 'A':
                withFormat = noFormat.replace(/[^A0-9]/g, "").toUpperCase();
                break;
            default:
                let tmp = noFormat.replace(/[^0-9]/g, "").toUpperCase();
                while (tmp.length > 3) {
                    withFormat = "." + tmp.substr(tmp.length - 3) + withFormat;
                    tmp = tmp.substring(0, tmp.length - 3);
                }
                withFormat = tmp + withFormat;
        }
        serialRef.current.value = withFormat;
        setSerialText((/^(C[A-NP-Z1-9]{5})$/.test(noFormat) || /^(A0\d{8})$/.test(noFormat) || /^(\d{9})$/.test(noFormat)) ? "" : " ")

    };

    const switchDevice = () => {
        setLoading(true);
        HTTPClient({
            method: "get",
            url:
                process.env.REACT_APP_API_URL +
                "/switch/"+props.meeting.slug
        })
            .then(response => {
                setStatus('ok');
                window.location =  '/';
            })
            .catch((error) => {
                setStatus('unknown');
                setLoading(false);
            });
    }

    const setInputRut = (event) => {

        const noFormat = rutRef.current.value.replace(/[^0-9kK]/g, "").toUpperCase();
        const dv = noFormat.charAt(noFormat.length - 1);
        let rut = noFormat.substring(0, noFormat.length - 1);
        let withFormat = '';
        while (rut.length > 3) {
            withFormat = "." + rut.substr(rut.length - 3) + withFormat;
            rut = rut.substring(0, rut.length - 3);
        }
        withFormat = rut + withFormat + "-" + dv.toUpperCase();

        rutRef.current.value = (noFormat.length > 1) ? withFormat : noFormat;
        setRutText((dv.toUpperCase() !== getDv(noFormat.substring(0, noFormat.length - 1)).toString().toUpperCase()) ? " " : "")

    };

    return (

        <Container component="main" maxWidth="xs">
            {status === 'wrongSerial' && <WrongSerial meeting={props.meeting} messages={props.messages} open={status === 'wrongSerial'} handleClose={() => setStatus('ok')}/>}
            {status === 'blocked' && <Blocked meeting={props.meeting} messages={props.messages} open={status === 'blocked'} handleClose={() => setStatus('ok')}/>}
            {status === 'unknown' && <Unknown meeting={props.meeting} messages={props.messages} open={status === 'unknown'} handleClose={() => setStatus('ok')}/>}
            {status === 'noquestions' && <NoQuestions meeting={props.meeting} messages={props.messages} open={status === 'noquestions'} handleClose={() => setStatus('ok')}/>}
            {status === 'onlypeople' && <NoPerson meeting={props.meeting} messages={props.messages} open={status === 'onlypeople'} handleClose={() => setStatus('ok')}/>}
            {status === 'temporarily_blocked' && <TemporalBlocked meeting={props.meeting} messages={props.messages} open={status === 'temporarily_blocked'} handleClose={() => setStatus('ok')}/>}
            {status === 'notfound' && <NotInMeetingRoll messages={props.messages} open={status === 'notfound'} handleClose={() => setStatus('ok')} meeting={props.meeting}/>}
            {status === 'late' && <Late messages={props.messages} open={status === 'late'} handleClose={() => setStatus('ok')} meeting={props.meeting}/>}
            {status === 'askphone' && <AskPhone meeting={props.meeting} reason={reason} jwt={jwt} my_mistake={myMistake} handleClose={() => setStatus('ok')} />}
            {status === 'another' && <AnotherDevice messages={props.messages} open={true} handleClose={() => setStatus('ok')} meeting={props.meeting} switchDevice={switchDevice}/>}
            {status === 'recaptcha' &&
            <Recaptcha meeting={props.meeting} messages={props.messages} open={status === 'recaptcha'}
                       handleClose={() => setStatus('ok')}/>}
            <CssBaseline/>
            <div className={classes.paper}>

                <Typography component="h1" variant="h5">
                    { getMessage(props.meeting.meeting_type,'welcome',props.messages).format(props.meeting.title)}
                </Typography>
                <form className={classes.form} noValidate>
                    <TextField
                        error={rutText !== ''}
                        helperText={rutText.trim()}
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="rut"
                        label={getMessage(props.meeting.meeting_type,'rut_label',props.messages)}
                        name="rut"
                        autoFocus
                        inputRef={rutRef}
                        onChange={() => setInputRut(rutRef, setRutText)}
                    />
                    <TextField
                        error={serialText !== ''}
                        helperText={serialText.trim()}
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        name="serial"
                        label={getMessage(props.meeting.meeting_type,'serial_label',props.messages)}
                        type="serial"
                        id="serial"
                        inputRef={serialRef}
                        onChange={() => setSerialInput(serialRef, setSerialText)}
                    />

                    {loading ? (
                        <Loading/>
                    ) : (
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                            onClick={login}
                        >
                            {getMessage(props.meeting.meeting_type,'button_verify_identity',props.messages)}
                        </Button>
                    )}
                </form>
            </div>
            <Grid container spacing={1}>
                <Grid item xs={12} sm={6} className={classes.grid}>
                    <img src={carnet_nuevo} alt={getMessage(props.meeting.meeting_type,'carnet_nuevo_alt',props.messages)} className={classes.carnet}/>

                </Grid>
                <Grid item xs={12} sm={6}>
                    <img src={carnet_viejo} alt={getMessage(props.meeting.meeting_type,'carnet_antiguo_alt',props.messages)} className={classes.carnet}/>

                </Grid>

            </Grid>

        </Container>

    );
}

export default RutSerial;
