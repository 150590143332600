import React from 'react';
import {Route, Switch} from "react-router-dom";
import NotFound from "./components/NotFound";
import Container from "@material-ui/core/Container";
import CssBaseline from "@material-ui/core/CssBaseline";
import Meeting from "./components/meeting/Meeting";
import MeetingSignIn from "./components/MeetingSignIn";
import Question from "./components/question/Question";
import MeetingSearch from "./components/meeting/MeetingSearch";
import QuestionAnswers from "./components/question/QuestionAnswers";
import PendingManualApprove from "./components/meeting/PendingManualApprove";
import MultipleQuestions from "./components/question/MultipleQuestions";

const App = () => {

    String.prototype.format = function () {
        let a = this;
        for (let k in arguments) {
            a = a.replace("{" + k + "}", arguments[k])
        }
        return a
    }

    return (
        <div className="App">
            <Container component="main" maxWidth="lg">
                <CssBaseline/>

                <Switch>
                    <Route path="/meeting/question/:question_id/answers" exact component={QuestionAnswers}/>
                    <Route path="/meeting/question/:question_id" exact component={Question}/>
                    <Route path="/meeting/search" exact component={MeetingSearch}/>
                    <Route path="/" exact component={Meeting}/>
                    <Route path="/meeting/questions" exact component={MultipleQuestions}/>
                    <Route path="/login" exact component={MeetingSignIn}/>
                    <Route component={NotFound}/>
                </Switch>

            </Container>
        </div>
    );
}

export default App;
