import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import React, {Fragment} from "react";
import {makeStyles} from "@material-ui/core/styles";
import {getMessage} from "../../util";
import TableContainer from "@material-ui/core/TableContainer";
import {Paper, TableCell} from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";


const useStyles = makeStyles(theme => ({
        paper: {
            marginTop: theme.spacing(4),
            marginBottom: theme.spacing(2),
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'left',
        },
        voted: {
            marginTop: theme.spacing(4),
        },
        paragraph: {
            margin: theme.spacing(2)
        },
        cardGrid: {
            paddingTop: theme.spacing(8),
            paddingBottom: theme.spacing(8),
        },
        card: {
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
        },
        cardActions: {
            justifyContent: 'center',
        },
        cardContent: {
            flexGrow: 1,
        },
        option: {
            margin: theme.spacing(3),
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',

        },
        label: {
            alignItems: 'left',
        },
        textField: {
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(1),
            width: '25ch',
        },

    }
));

const VotedSingle = (props) => {
    const classes = useStyles();


    const options = props.answers.reduce((acc,h) => {
        const option = h.answers[props.question.slug].options.reduce((acc, o) => {
            Object.keys(o.votes).forEach(k => {
                if (o.votes[k].shares > 0) {
                    if (!acc[k]) {
                        acc[k] = [];
                    }
                    acc[k].push(['option',o.name]);
                }
            });
            return (acc);

        }, Object.keys(h.answers[props.question.slug].blanks).reduce((acc,k) => {
            acc[k] = [];
            return(acc);
        },{}));

        Object.keys(h.answers[props.question.slug].nulls).forEach(k => {
            if (h.answers[props.question.slug].nulls[k].shares > 0) {
                option[k].push(['null',getMessage(props.meeting.meeting_type, 'null', props.messages)]);
            }
        });

        Object.keys(h.answers[props.question.slug].blanks).filter(k => h.answers[props.question.slug].blanks[k].shares > 0).forEach(k => {
            if (props.question.max_marks - props.question.min_marks > 1) {
                option[k].push(['blank', h.answers[props.question.slug].blanks[k].shares/h.shares[k].shares + " " +getMessage(props.meeting.meeting_type, h.answers[props.question.slug].blanks[k].shares>1?'blanks':'blank', props.messages)]);
            } else  {
                option[k].push(['blank', getMessage(props.meeting.meeting_type, 'blank', props.messages)]);
            }
        });


        acc[h.identity] = option;
        return (acc);
    },{});


    const question_shares = Object.keys(props.question.blanks)

    const representative_question = Object.keys(props.shares).filter((x) => {
        if (question_shares.indexOf(x) === -1)
            return false;
        else
            return true;
    });

    return(
        <Container className={classes.voted}>
            <Typography variant={"h6"} >
                {getMessage(props.meeting.meeting_type, 'voted_message', props.messages)}
            </Typography>


            <TableContainer component={Paper} className={classes.paper}>
                <Table className={classes.table} size="small" aria-label="a dense table">
                    <TableHead>
                        <TableRow>
                            <TableCell>{getMessage(props.meeting.meeting_type, 'holder', props.messages)}</TableCell>
                            {representative_question.map(s => (
                                <TableCell key={s} align="right">{getMessage(props.meeting.meeting_type, 'apoderado', props.messages)}</TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {props.holders.map(h => (
                            <TableRow key={h.identity}>
                                <TableCell component="th" scope="row">
                                    {h.name} ({h.identity})
                                </TableCell>
                                {representative_question.map(s => (
                                    <TableCell key={s} align={'right'}>
                                        {options[h.identity][s] ? (
                                            <span>
                                                {options[h.identity][s].map(m => (
                                                    (m[0] === 'blank' || m[0] === 'null')?(
                                                        <b key={m[1]}>{m[1]}<br/></b>
                                                    ):(<Fragment key={m[1]}>{m[1]}<br/></Fragment>)))}
                                            </span>) : "-"}
                                    </TableCell>
                                ))}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>


        </Container>
    )
}

export default VotedSingle;