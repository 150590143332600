import Button from "@material-ui/core/Button";
import React, {Fragment, useRef, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import InputLabel from "@material-ui/core/InputLabel";
import Box from "@material-ui/core/Box";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import HTTPClient from "../../HTTPClient";
import Loading from "../Loading";
import config from "../../config.json";
import {getMessage} from "../../util";
import {Accordion, AccordionDetails, AccordionSummary, InputAdornment, Link, Paper, TableCell} from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormGroup from "@material-ui/core/FormGroup";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {Clear} from "@material-ui/icons";


const useStyles = makeStyles(theme => ({
        paper: {
            marginTop: theme.spacing(4),
            marginBottom: theme.spacing(2),
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'left',
        },
        voted: {
            marginTop: theme.spacing(4),
        },
        paragraph: {
            margin: theme.spacing(2),
        },
        unhide: {
            margin: theme.spacing(2),
        },
        hide: {
            display: "none",
        },
        cardGrid: {
            paddingTop: theme.spacing(8),
            paddingBottom: theme.spacing(8),
        },
        number: {
            fontFamily: "Montserrat"
        },
        selector: {
            margin: theme.spacing(2)
        },
        card: {
            marginTop: theme.spacing(4),
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
        },
        cardActions: {
            justifyContent: 'center',
        },
        cardContent: {
            flexGrow: 1,
        },
        option: {
            marginTop: theme.spacing(3),
            marginBottom: theme.spacing(3),
            display: 'flex',
            flexDirection: 'column',

        },
        label: {
            alignItems: 'left',
            color: 'black'
        },
        textField: {
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(1),
            width: '25ch',
        },
        share: {
            textAlign: 'center',
        },
        holderList: {
            flexDirection: "column"
        },
        marginTop: {
            marginTop: theme.spacing(2)
        }

    }
));


const keyPress = (e) => {
    if (e.which !== 8 && e.which !== 0 && e.which !== 44 && e.which !== 46 && (e.which < 48 || e.which > 57))
        e.preventDefault();
    return;
}

const sep = (() => {
    const n = 1.1;
    return (n.toLocaleString(navigator.language).substring(1, 2));
})();

const getRealNumber = (s_value) => {

    if (typeof s_value === "number") {
        return (s_value);
    }

    if (sep === ",") {
        return (Number(s_value.replace(/[^,0-9-]/g, "").replace(/,/g, ".")) || 0);
    } else {
        return (Number(s_value.replace(/[^.0-9-]/g, "")) || 0);
    }
}

const roundTo = (value, precision) => {
    const exp = Math.pow(10, precision);
    return (Math.round(exp * value) / exp);
}

const OverflowOption = (props) => {
    return (<Dialog
        open={true}
        onClose={props.handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
    >
        <DialogTitle
            id="alert-dialog-title">{getMessage(props.meeting.meeting_type, 'answer_overflow_title', props.messages)}</DialogTitle>
        <DialogContent>
            <Typography>
                {getMessage(props.meeting.meeting_type, props.holders.length > 1?'answer_overflow_message':'answer_overflow_message_multi', props.messages)}            </Typography>

            {props.holders.length > 1 && (<ul>
                {props.holders.map(holder => (
                    <li key={holder.identity}>{holder.name} ({holder.identity}) </li>
                ))}
            </ul>)}

        </DialogContent>
        <DialogActions>
            <Button onClick={props.handleClose} color="primary" autoFocus>
                {getMessage(props.meeting.meeting_type, 'close', props.messages)}
            </Button>

        </DialogActions>
    </Dialog>)
}

const TooMany = (props) => {
    return (<Dialog
        open={true}
        scroll="body"
        onClose={props.handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
    >
        <DialogTitle
            id="alert-dialog-title">{getMessage(props.meeting.meeting_type, 'answer_too_much_title', props.messages)}</DialogTitle>
        <DialogContent>
            <DialogContentText id="alert-dialog-description">
                {getMessage(props.meeting.meeting_type, 'answer_too_much_message', props.messages)}

            </DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button onClick={props.handleClose} color="primary" autoFocus>
                {getMessage(props.meeting.meeting_type, 'close', props.messages)}
            </Button>
        </DialogActions>
    </Dialog>)
}

const Ballot = (props) => {
    const classes = useStyles();
    const forceUpdate = useForceUpdate();
    const [tooMany, setTooMany] = useState(false);
    const [overflowOption, setOverflowOption] = useState([]);


    const [options, setOptions] = useState(props.holders.filter(h => {
        // filter only the holders that vote in this questions
        return (props.value[h.identity][props.option[0]]);
    }).reduce((acc, h) => {
        acc[h.identity] = {
            value: Number(props.value[h.identity][props.option[0]][props.option[1]]),
            display_value: Number(props.value[h.identity][props.option[0]][props.option[1]]).toLocaleString(navigator.language, {
                maximumFractionDigits: config.precision
            }),
            display_percentage: roundTo(100 * props.value[h.identity][props.option[0]][props.option[1]] / (props.question.max_marks * h.shares[props.option[0]].shares), config.precision).toLocaleString(navigator.language, {
                maximumFractionDigits: config.precision
            }),
            total: props.question.max_marks * h.shares[props.option[0]].shares,
            holder: h,
            used_out: Object.keys(props.value[h.identity][props.option[0]]).reduce((acc, elem) => acc + props.value[h.identity][props.option[0]][elem], 0) - props.value[h.identity][props.option[0]][props.option[1]]
        };
        return (acc);
    }, {}));

    const setAll = () => {
        Object.keys(options).forEach(identity => {
            const free = (options[identity].total - options[identity].used_out)
            options[identity].value = (free > options[identity].total/props.question.max_marks)?options[identity].total/props.question.max_marks:free;
            options[identity].display_value = options[identity].value.toLocaleString(navigator.language, {
                maximumFractionDigits: config.precision
            });
            options[identity].percentage = roundTo(100 * options[identity].value / (options[identity].total), config.precision);
            options[identity].display_percentage = options[identity].percentage.toLocaleString(navigator.language, {
                maximumFractionDigits: config.precision
            });
            setOptions(options);
            forceUpdate();
        });
        forceUpdate();
    }

    const total = Object.keys(options).reduce((acc, identity) => {
        return (acc + options[identity].total);
    }, 0);


    const setNone = () => {
        Object.keys(options).forEach(identity => {
            options[identity].value = 0;
            options[identity].display_value = options[identity].value.toLocaleString(navigator.language, {
                maximumFractionDigits: config.precision
            });
            options[identity].percentage = roundTo(100 * options[identity].value / (options[identity].total), config.precision);
            options[identity].display_percentage = options[identity].percentage.toLocaleString(navigator.language, {
                maximumFractionDigits: config.precision
            });
            setOptions(options);
            forceUpdate();
        });
        forceUpdate();
    }

    const vote = () => {

        const value = props.value;
        Object.keys(options).forEach(identity => {
            value[identity][[props.option[0]]][props.option[1]] = options[identity].value;
        });

        props.setValue(value);
        props.handleClose();
    }

    const updatePercentage = (identity, event) => {

        let s_value = event.target.value;

        // TODO: decimales o no
        // I remove forbidden chars
        s_value = s_value.replace(/[^,.0-9]/g, "");

        if (s_value.length > 2 && s_value.substr(s_value.length - 2, 1) === sep && s_value.substr(s_value.length - 1) === "0") {
            // is putting zero after decimal separator, ignore
            return;
        }

        // I don't need to verify negatives because I replace everything different from number
        if (s_value.substr(s_value.length - 1) === "." || s_value.substr(s_value.length - 1) === ",") {
            // is putting decimal separator, ignore
            options[identity].display_percentage = s_value.substr(0, s_value.length - 1) + sep;
            setOptions(options);
            forceUpdate();
            return;
        }


        const percentage = getRealNumber(s_value);

        // ignore if puting 0 after decimals ignore
        if ((percentage % 1 !== 0) && s_value.substr(s_value.length - 1) === "0") {
            return
        }

        const value = props.meeting.decimals ? roundTo(percentage * options[identity].total / 100, config.precision) : roundTo(percentage * options[identity].total / 100, 0);


        // verify overflow for one option
        if (value > options[identity].total / props.question.max_marks) {
            setOverflowOption([options[identity].holder]);
            options[identity].value = 0;
            options[identity].display_value = 0;
            options[identity].percentage = 0;
            options[identity].display_percentage = 0;
            setOptions(options);
            forceUpdate();
            return;
        }

        // verify not overflow
        if ((value + options[identity].used_out) > options[identity].total) {
            // over max
            setTooMany(true);
            options[identity].value = 0;
            options[identity].display_value = 0;
            options[identity].percentage = 0;
            options[identity].display_percentage = 0;
            setOptions(options);
            forceUpdate();
            return;
        }

        const overload = props.meeting.decimals ? (1 / Math.pow(10, config.precision)) : 1

        // the roundign can give overflow by 1
        if (getRealNumber(options[identity].total) + overload === value + options[identity].used_out)
            options[identity].value = value - overload;
        else options[identity].value = value;


        options[identity].display_value = options[identity].value.toLocaleString(navigator.language, {
            maximumFractionDigits: config.precision
        });
        options[identity].display_percentage = percentage.toLocaleString(navigator.language, {
            maximumFractionDigits: config.precision
        });
        setOptions(options);
        forceUpdate();

    }


    const handleClick = (identity, event) => {
        if (event.target.checked) {
            const val = roundTo(options[identity].total / props.question.max_marks, 0);

            if ((val + options[identity].used_out) > options[identity].total) {
                // over max
                setTooMany(true);
                options[identity].value = 0;
                options[identity].display_value = 0;
                options[identity].percentage = 0;
                options[identity].display_percentage = 0;
                setOptions(options);
                forceUpdate();
                return;
            }
            options[identity].value = val;
            setOptions(options);
            forceUpdate();
        } else {
            options[identity].value = 0;
            setOptions(options);
            forceUpdate();
        }
    };


    const updateValues = (identity, event) => {
        let s_value = event.target.value;

        if (props.meeting.decimals) {
            // TODO: decimales o no
            // I remove forbidden chars
            s_value = s_value.replace(/[^,.0-9]/g, "");

            if (s_value.length > 2 && s_value.substr(s_value.length - 2, 1) === sep && s_value.substr(s_value.length - 1) === "0") {
                // is putting zero after decimal separator, ignore
                return;
            }

            // I don't need to verify negatives because I replace everything different from number
            if (s_value.substr(s_value.length - 1) === "." || s_value.substr(s_value.length - 1) === ",") {
                // is putting decimal separator, ignore
                options[identity].display_value = s_value.substr(0, s_value.length - 1) + sep;
                setOptions(options);
                forceUpdate();
                return;
            }


        } else {
            // In any other meeting type decimals are not allowed
            s_value = s_value.replace(/[^0-9]/g, "");
        }

        const value = getRealNumber(s_value);

        // ignore if puting 0 after decimals ignore
        if ((value % 1 !== 0) && s_value.substr(s_value.length - 1) === "0") {
            return
        }


        // verify overflow for one option
        if (value > options[identity].total / props.question.max_marks) {
            setOverflowOption([options[identity].holder]);
            options[identity].value = 0;
            options[identity].display_value = 0;
            options[identity].percentage = 0;
            options[identity].display_percentage = 0;
            setOptions(options);
            forceUpdate();
            return;
        }

        // verify not overflow
        if ((value + options[identity].used_out) > options[identity].total) {
            // over max
            setTooMany(true);
            options[identity].value = 0;
            options[identity].display_value = 0;
            options[identity].percentage = 0;
            options[identity].display_percentage = 0;
            setOptions(options);
            forceUpdate();
            return;
        }

        options[identity].value = value;
        options[identity].display_value = options[identity].value.toLocaleString(navigator.language, {
            maximumFractionDigits: config.precision
        });
        options[identity].percentage = roundTo(100 * options[identity].value / (options[identity].total), config.precision);
        options[identity].display_percentage = options[identity].percentage.toLocaleString(navigator.language, {
            maximumFractionDigits: config.precision
        });
        setOptions(options);
        forceUpdate();
    }

    const sum_assigned = Object.keys(options).reduce((acc, identity) => {
        return (acc + options[identity].value);
    }, 0);


    return (
        <Fragment>
            {tooMany && <TooMany meeting={props.meeting} handleClose={() => setTooMany(false)}/>}
            {overflowOption.length && <OverflowOption question={props.question} messages={props.messages} meeting={props.meeting} holders={overflowOption} handleClose={() => setOverflowOption([])}/>}

            <Dialog
                open={true}
                scroll="body"
                onClose={props.handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {props.question.name}<br/>
                    {getMessage(props.meeting.meeting_type, 'ballot_title_distributed', props.messages)}:
                    "{props.option[2]}" {getMessage(props.meeting.meeting_type, 'ballot_title_distributed_series', props.messages)}"
                    "{props.meeting.shares[props.option[0]].name}"
                </DialogTitle>

                <DialogContent>
                    <Button className={classes.selector} variant="outlined" onClick={setAll}
                    >{getMessage(props.meeting.meeting_type, 'assign_all_possible', props.messages)} </Button>
                    <Button className={classes.selector} variant="outlined" onClick={setNone}
                    >{getMessage(props.meeting.meeting_type, 'clean', props.messages)}</Button>

                    {/* only show holders with available actions */}
                    {/* TODO: except when null possible */}

                    <Typography variant={'h6'}>
                        {getMessage(props.meeting.meeting_type, 'total_for_option', props.messages)} : {sum_assigned.toLocaleString(navigator.language, {
                        maximumFractionDigits: config.precision
                    })} ({(100 * sum_assigned / total).toLocaleString(navigator.language, {
                        maximumFractionDigits: config.precision
                    })} %)
                    </Typography>
                    {props.holders.filter(h => (options[h.identity] && options[h.identity].used_out < options[h.identity].total)).map(h => (
                        <Fragment key={h.identity}>
                            {!(h.custodian || props.question.config.distributed) ? (
                                <Box className={classes.paragraph}>
                                    <FormGroup row key={h.identity}>
                                        <FormControlLabel control={<Checkbox value={h.identity}
                                                                             checked={options[h.identity].value > 0}
                                                                             onClick={(e) => handleClick(h.identity, e)}
                                        />} label={<InputLabel htmlFor={h.identity}
                                                               className={classes.label}>{h.name} ({h.identity}) <br/>
                                            ({getMessage(props.meeting.meeting_type, 'total', props.messages).toLowerCase()}: {options[h.identity].total.toLocaleString(navigator.language, {
                                                maximumFractionDigits: config.precision
                                            })}, {getMessage(props.meeting.meeting_type, 'used', props.messages)}: {Number(options[h.identity].used_out + options[h.identity].value).toLocaleString(navigator.language, {
                                                maximumFractionDigits: config.precision
                                            })}, {getMessage(props.meeting.meeting_type, 'dispo', props.messages)}: {(options[h.identity].total - Number(options[h.identity].used_out + options[h.identity].value)).toLocaleString(navigator.language, {
                                                maximumFractionDigits: config.precision
                                            })}) </InputLabel>}/>
                                    </FormGroup>
                                </Box>
                            ) : (
                                <Box className={classes.paragraph}>
                                    <InputLabel htmlFor={h.identity}
                                                className={classes.label}>{h.name} ({h.identity}) <br/>
                                        ({getMessage(props.meeting.meeting_type, 'total', props.messages).toLowerCase()}: {options[h.identity].total.toLocaleString(navigator.language, {
                                            maximumFractionDigits: config.precision
                                        })}, {getMessage(props.meeting.meeting_type, 'used', props.messages)}: {Number(options[h.identity].used_out + options[h.identity].value).toLocaleString(navigator.language, {
                                            maximumFractionDigits: config.precision
                                        })}, {getMessage(props.meeting.meeting_type, 'dispo', props.messages)}: {(options[h.identity].total - Number(options[h.identity].used_out + options[h.identity].value)).toLocaleString(navigator.language, {
                                            maximumFractionDigits: config.precision
                                        })}) </InputLabel>

                                    <TextField
                                        label={getMessage(props.meeting.meeting_type, 'shares', props.messages)}
                                        type="text"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        InputProps={{
                                            className: classes.number,
                                        }}
                                        id={"V-" + h.identity}
                                        className={classes.textField}
                                        margin="dense"
                                        variant="outlined"
                                        value={options[h.identity].display_value}
                                        onChange={(e) => updateValues(h.identity, e)}
                                        onKeyPress={keyPress}
                                    />
                                    <TextField
                                        label="%"
                                        type="text"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        InputProps={{
                                            className: classes.number,
                                        }}
                                        id={"P-" + h.identity}
                                        // inputRef={nulls[k].percentage}
                                        className={classes.textField}
                                        margin="dense"
                                        variant="outlined"
                                        value={options[h.identity].display_percentage}
                                        onChange={(e) => updatePercentage(h.identity, e)}
                                        onKeyPress={keyPress}
                                        //onBlur={() => updateValues(null, k)}
                                    />
                                </Box>
                            )}
                        </Fragment>


                    ))}

                </DialogContent>


                <DialogActions>
                    <Button onClick={vote} className={classes.selector} variant="outlined">
                        {getMessage(props.meeting.meeting_type, 'done', props.messages)}
                    </Button>
                </DialogActions>


            </Dialog>
        </Fragment>

    )

}

const Resume = (props) => {
    const classes = useStyles();
    const [loading, setLoading] = useState(false);

    const vote = () => {

        const data = props.answers.map(answer => {
            return ((props.question.config.distributed || answer.custodian) ? {
                identity: answer.identity,
                answers: answer.options.map(a => {
                    return ({
                        option_id: a._id, votes: Object.keys(a.votes).reduce((acc, share) => {
                                acc[share] = a.votes[share];
                                acc[share].shares = Number(Number(acc[share].shares).toFixed(config.precision));
                                return (acc);
                            }, {}
                        )
                    });
                }),
                nulls: Object.keys(answer.nulls).reduce((acc, share) => {
                        acc[share] = answer.nulls[share];
                        acc[share].shares = Number(Number(acc[share].shares).toFixed(config.precision));
                        return (acc);
                    }, {}
                ),
                blanks: Object.keys(answer.blanks).reduce((acc, share) => {
                        acc[share] = answer.blanks[share];
                        acc[share].shares = Number(Number(acc[share].shares).toFixed(config.precision));
                        return (acc);
                    }, {}
                )
            } : {
                identity: answer.identity,
                answers: answer.options.map(a => {
                    return ({
                        option_id: a._id, votes: (
                            Object.keys(a.votes).reduce((acc, share) => {
                                    acc[share] = a.votes[share];
                                    acc[share].shares = acc[share].shares > 0 ? 1 : 0;
                                    return (acc);
                                }, {}
                            )
                        )
                    });
                }),
                nulls: Object.keys(answer.nulls).reduce((acc, share) => {
                        acc[share] = answer.nulls[share];
                        acc[share].shares = acc[share].shares > 0 ? 1 : 0;
                        return (acc);
                    }, {}
                ),
                blanks: Object.keys(answer.blanks).reduce((acc, share) => {
                        acc[share] = answer.blanks[share];
                        acc[share].shares = acc[share].shares > 0 ? 1 : 0;
                        return (acc);
                    }, {}
                )
            });
        })


        setLoading(true);
        HTTPClient({
            method: "post",
            data: data,
            url:
                process.env.REACT_APP_API_URL +
                "/meetings/" + props.meeting.slug + "/questions/" + props.question.slug + "/vote"
        })
            .then(response => {
                if (response.data) {
                    props.onVote();
                } else {
                    window.alert(getMessage(props.meeting.meeting_type, 'answer_cannot_sent', props.messages));

                }

            }).catch((error) => {
            console.log(error);
            window.alert(getMessage(props.meeting.meeting_type, 'answer_cannot_sent', props.messages));
            setLoading(false);

        });

    }

    const question_shares = Object.keys(props.question.blanks)

    const representative_question = Object.keys(props.shares).filter((x) => {
        if (question_shares.indexOf(x) === -1)
            return false;
        else
            return true;
    });

    // get the total shares by serie to compute percentages
    const total_shares = props.holders.reduce((acc, h) => {
        representative_question.forEach(share => {
            acc[share] += props.question.max_marks * (h.shares[share] ? Number(h.shares[share].shares) : 0);
        });
        return (acc);
    }, representative_question.reduce((acc, elem) => {
        acc[elem] = 0;
        return (acc);
    }, {}));

    // get the sum by share and id of all the holders
    const totals = props.answers.reduce((acc, answer) => {
        Object.keys(answer.blanks).forEach(share => {
            answer.options.forEach(option => {
                acc[share][option._id] += option.votes[share].shares ? option.votes[share].shares : 0;
            });
        });
        return (acc);

    }, Object.keys(props.question.blanks).reduce((acc, share) => {
        acc[share] = props.question.options.reduce((acc, option) => {
            acc[option._id] = 0;
            return (acc);
        }, {});
        return (acc);
    }, {}));


    return (<Dialog
        open={true}
        scroll="body"
        onClose={props.handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
    >
        <DialogTitle id="alert-dialog-title">
            {getMessage(props.meeting.meeting_type, 'answer_confirm_title', props.messages)}
        </DialogTitle>
        <DialogContent>
            <Typography className={classes.paragraph}
                        variant={"h6"}>{getMessage(props.meeting.meeting_type, 'resume_total', props.messages)}</Typography>

            <TableContainer component={Paper}>
                <Table className={classes.table} size="small" aria-label="a dense table">
                    <TableHead>
                        <TableRow>
                            <TableCell></TableCell>
                            {representative_question.map(s => (
                                <TableCell key={s} align="right">{props.question.blanks[s].name}</TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {props.question.options.map(option => (
                            <TableRow key={option._id}>
                                <TableCell>
                                    {option.name}

                                </TableCell>
                                {representative_question.map(s => (
                                    <TableCell key={s}
                                               align="right">{totals[s][option._id].toLocaleString(navigator.language, {
                                        maximumFractionDigits: config.precision
                                    })}</TableCell>
                                ))}
                            </TableRow>
                        ))}
                        {props.question.max_marks !== props.question.min_marks && <TableRow>
                            <TableCell>
                                <b>{getMessage(props.meeting.meeting_type, 'blanks', props.messages)}</b>

                            </TableCell>
                            {representative_question.map(s => (
                                <TableCell key={s}
                                           align="right">{(total_shares[s] - Number(Object.keys(totals[s]).reduce((acc, elem) => acc + totals[s][elem], 0))).toLocaleString(navigator.language, {
                                    maximumFractionDigits: config.precision
                                })}</TableCell>
                            ))}
                        </TableRow>}
                    </TableBody>
                </Table>
            </TableContainer>

            {loading ? (
                <Loading/>

            ) : (
                <DialogActions>
                    <Button onClick={props.handleClose} autoFocus>
                        {getMessage(props.meeting.meeting_type, 'back', props.messages)}
                    </Button>
                    <Button variant="contained" color={"primary"} onClick={vote}>
                        {getMessage(props.meeting.meeting_type, 'answer_confirm_button', props.messages)}
                    </Button>
                </DialogActions>

            )}

            {props.holders.length > 1 && <Accordion>

                <AccordionSummary
                    expandIcon={<ExpandMoreIcon/>}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    className={classes.marginTop}
                >
                    <Typography>{getMessage(props.meeting.meeting_type, 'resume_detail', props.messages)}</Typography>
                </AccordionSummary>
                <AccordionDetails className={classes.holderList}>
                    {props.answers.map(answer => (
                        <TableContainer key={answer.identity} component={Paper} className={classes.marginTop}>
                            <Table size="small" aria-label="a dense table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell><b>{answer.name} ({answer.identity})</b></TableCell>
                                        {Object.keys(answer.blanks).map(s => (
                                            <TableCell key={s} align="right">{answer.blanks[s].name}</TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {((props.question.searchable===true)?(answer.options.filter(option => Object.keys(option.votes).reduce((acc,s) => {
                                        return(acc || option.votes[s].shares > 0)
                                    },false))):answer.options).map(option => (
                                        <TableRow key={option._id}>
                                            <TableCell>
                                                {option.name}

                                            </TableCell>
                                            {Object.keys(answer.blanks).map(s => (
                                                <TableCell key={s}
                                                           align="right">{option.votes[s].shares.toLocaleString(navigator.language, {
                                                    maximumFractionDigits: config.precision
                                                })}</TableCell>
                                            ))}
                                        </TableRow>
                                    ))}
                                    {props.question.max_marks !== props.question.min_marks && <TableRow>
                                        <TableCell>
                                            <b>{getMessage(props.meeting.meeting_type, 'blanks', props.messages)}</b>

                                        </TableCell>
                                        {Object.keys(answer.blanks).map(s => (
                                            <TableCell key={s}
                                                       align="right">{answer.blanks[s].shares.toLocaleString(navigator.language, {
                                                maximumFractionDigits: config.precision
                                            })}</TableCell>
                                        ))}
                                    </TableRow>}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    ))}

                    {loading ? (
                        <Loading/>

                    ) : (
                        <DialogActions>
                            <Button onClick={props.handleClose} autoFocus>
                                {getMessage(props.meeting.meeting_type, 'back', props.messages)}
                            </Button>
                            <Button variant="contained" color={"primary"} onClick={vote}>
                                {getMessage(props.meeting.meeting_type, 'answer_confirm_button', props.messages)}
                            </Button>
                        </DialogActions>

                    )}
                </AccordionDetails>
            </Accordion>}


        </DialogContent>


    </Dialog>);
}

const useForceUpdate = () => {
    const [value, setValue] = useState(0); // integer state
    return () => setValue(value => ++value); // update the state to force render
};


const UnderFlow = (props) => {
    const distributed = (props.holders.reduce((acc, holder) => {
        return (acc || holder.custodian);
    }, false) || props.question.config.distributed);

    return (<Dialog
        open={props.underflow.length > 0}
        onClose={props.close}
        scroll="body"
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
    >
        <DialogTitle id="alert-dialog-title">
            {getMessage(props.meeting.meeting_type, 'underflow_title', props.messages).format(props.question.min_marks)}
        </DialogTitle>

        <DialogContent>

            <Typography>
                {distributed ? (props.question.config.explicit_blank?getMessage(props.meeting.meeting_type, 'underflow_multi_all', props.messages):getMessage(props.meeting.meeting_type, 'underflow_multi', props.messages).format((100 * props.question.min_marks / props.question.max_marks).toLocaleString(navigator.language, {
                    maximumFractionDigits: config.precision
                }))) : getMessage(props.meeting.meeting_type, 'underflow', props.messages).format(props.question.min_marks+props.question.config.explicit_blank?1:0)}
            </Typography>

            <ul>
                {props.underflow.map(holder => (
                    <li key={holder.identity}>{holder.name} ({holder.identity}) {Object.keys(props.question.blanks).length > 1 &&
                    <span>
                        en {holder.underflows.map(s => props.shares[s].name).join(", ")}
                        </span>}</li>
                ))}
            </ul>

        </DialogContent>
        <DialogActions>
            <Button onClick={props.close} autoFocus variant="outlined">
                {getMessage(props.meeting.meeting_type, 'close', props.messages)}
            </Button>
        </DialogActions>
    </Dialog>)
}

const OptionsDistributed = (props) => {
    // const forceUpdate = useForceUpdate();

    const classes = useStyles();
    const [resume, setResume] = useState(false);
    const [option, setOption] = useState(null);

    const forceUpdate = useForceUpdate();

    const question_shares = Object.keys(props.question.blanks)


    const [tooMany, setTooMany] = useState(false);
    const [underflow, setUnderflow] = useState([]);
    const [overflowOption, setOverflowOption] = useState([]);
    const [filtro, setFiltro] = useState([]);

    const filtroIn = useRef();


    const [answers, setAnswers] = useState(props.holders.map(h => {
        const representative_question = Object.keys(h.shares).filter((x) => {
            if (question_shares.indexOf(x) === -1)
                return false;
            else
                return true;
        });

        return ({
            identity: h.identity,
            options: props.question.options.map(o => {
                const votes = representative_question.reduce((acc, k) => {
                    acc[k] = {
                        name: props.shares[k].name,
                        shares: 0
                    };
                    return (acc);
                }, {});

                return ({_id: o._id, name: o.name, order: o.order, votes: votes});
            }),
            blanks: representative_question.reduce((acc, k) => {
                acc[k] = {
                    name: props.shares[k].name,
                    shares: 0
                };
                return (acc);
            }, {}),
            nulls: representative_question.reduce((acc, k) => {
                acc[k] = {
                    name: props.shares[k].name,
                    shares: 0
                };
                return (acc);
            }, {})
        })

    }));

    const filtrar = (event) => {
        const value = event.target.value;
        setFiltro(value.trim().split(' ').map(w => w.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase()));
    }

    const clearFiltro = () => {
        setFiltro([]);
        filtroIn.current.value = "";
    }

    const representative_question = Object.keys(props.shares).filter((x) => {
        if (question_shares.indexOf(x) === -1)
            return false;
        else
            return true;
    });

    const [value, setValue] = React.useState(props.holders.reduce((accH, h) => {

        // get the series that the holder has and that are included in the question
        const representative_question = Object.keys(h.shares).filter((x) => {
            if (question_shares.indexOf(x) === -1)
                return false;
            else
                return true;
        });


        // setup to initial votes to 0
        accH[h.identity] = representative_question.reduce((acc, k) => {
            acc[k] = props.question.options.reduce((accO, o) => {
                accO[o._id] = 0;
                return (accO);
            }, {});
            acc[k]["blanks"] = 0;
            return (acc);
        }, {});

        return (accH);
    }, {}));


    // get the sum by share and id of all the holders
    const totals = Object.keys(value).reduce((acc, elem) => {
        representative_question.forEach(share => {
            props.question.options.forEach(option => {
                acc[share][option._id] += value[elem][share] ? value[elem][share][option._id] : 0;
            });
            acc[share]["blanks"] += value[elem][share] ? value[elem][share]["blanks"] : 0;
        });
        return (acc);

    }, representative_question.reduce((acc, share) => {
        acc[share] = props.question.options.reduce((acc, option) => {
            acc[option._id] = 0;
            return (acc);
        }, {});
        acc[share]["blanks"] = 0;
        return (acc);
    }, {}));

    // get the total shares by serie to compute percentages
    const total_shares = props.holders.reduce((acc, h) => {
        representative_question.forEach(share => {
            acc[share] += props.question.max_marks * (h.shares[share] ? Number(h.shares[share].shares) : 0);
        });
        return (acc);
    }, representative_question.reduce((acc, elem) => {
        acc[elem] = 0;
        return (acc);
    }, {}));


    const vote = () => {

        const answers = props.holders.map(holder => {

            const representative_question = Object.keys(holder.shares).filter((x) => {
                if (question_shares.indexOf(x) === -1)
                    return false;
                else
                    return true;
            });


            // get total & sum of the shares asigned by serie
            const total_shares = representative_question.reduce((acc, share) => {
                acc[share] += Number(holder.shares[share].shares);
                return (acc);
            }, representative_question.reduce((acc, share) => {
                acc[share] = 0;
                return (acc);
            }, {}));


            const sum_shares = representative_question.reduce((acc, share) => {
                props.question.options.forEach(option => {
                    acc[share] += Number(value[holder.identity][share][option._id]);
                });
                acc[share] += Number(value[holder.identity][share]["blanks"]);
                return (acc);
            }, representative_question.reduce((acc, share) => {
                acc[share] = 0;
                return (acc);
            }, {}));

            const underflow_shares = representative_question.filter(k => {
                return (sum_shares[k] < (props.question.min_marks + props.question.config.explicit_blank?1:0) * total_shares[k]);
            });


            const votes = props.question.options.map(o => {
                const no = {};
                no._id = o._id;
                no.name = o.name;
                no.order = o.order;
                no.votes = representative_question.reduce((acc, k) => {
                    acc[k] = {
                        name: props.shares[k].name,
                        // in distributed nulls are explicit, so I guess values are ok, no need to check
                        shares: Number(value[holder.identity][k][o._id])
                    }
                    return (acc);
                }, {})

                return (no)
            });

            // no need to setup nulls since there are always explicit in distributed
            const nulls = representative_question.reduce((acc, k) => {
                acc[k] = {
                    name: props.shares[k].name,
                    //TODO: nulls not supported yet
                    shares: 0
                }
                return (acc);
            }, {});


            const blanks = representative_question.reduce((acc, k) => {
                acc[k] = {
                    name: props.shares[k].name,
                    shares: Number((props.question.max_marks) * total_shares[k] - sum_shares[k]+value[holder.identity][k]["blanks"])
                }
                return (acc);
            }, {});

            return ({
                identity: holder.identity,
                custodian: holder.custodian,
                name: holder.name,
                options: votes,
                nulls: nulls,
                blanks: blanks,
                underflows: underflow_shares
            });


        });

        const the_underflows = answers.filter(a => a.underflows.length > 0);
        if (the_underflows.length > 0) {
            setUnderflow(the_underflows);
        } else {
            setAnswers(answers);
            setResume(true);
        }

    }


    const [options, setOptions] = useState(
        representative_question.reduce((acc, share) => {
            acc[share] = props.question.options.reduce((acc, option) => {
                acc[option._id] = totals[share][option._id] > 0 ? {
                    display_value: Number(totals[share][option._id]).toLocaleString(navigator.language, {
                        maximumFractionDigits: config.precision
                    }),
                    display_percentage: roundTo(100 * totals[share][option._id] / (total_shares[share]), config.precision).toLocaleString(navigator.language, {
                        maximumFractionDigits: config.precision
                    })
                } : {
                    display_value: '',
                    display_percentage: ''
                };
                return (acc);
            }, {});
            acc[share]["blanks"] = totals[share]["blanks"] > 0 ? {
                display_value: Number(totals[share]["blanks"]).toLocaleString(navigator.language, {
                    maximumFractionDigits: config.precision
                }),
                display_percentage: roundTo(100 * totals[share]["blanks"] / (total_shares[share]), config.precision).toLocaleString(navigator.language, {
                    maximumFractionDigits: config.precision
                })
            } : {
                display_value: '',
                display_percentage: ''
            };
            return (acc);
        }, {}));

    const updatePercentage = (share, option_id, event) => {
        // const identity = props.holders[0].identity;
        let s_value = event.target.value;

        // TODO: decimales o no
        // I remove forbidden chars
        s_value = s_value.replace(/[^,.0-9]/g, "");

        if (s_value.indexOf(sep) > -1 && s_value.substr(s_value.length - 1) === "0") {
            // is putting zero after decimal separator, ignore
            options[share][option_id].display_percentage = s_value;
            setOptions(options);
            forceUpdate();
            return;
        }

        // I don't need to verify negatives because I replace everything different from number
        if (s_value.substr(s_value.length - 1) === "." || s_value.substr(s_value.length - 1) === ",") {
            // is putting decimal separator, ignore
            options[share][option_id].display_percentage = s_value.substr(0, s_value.length - 1) + sep;
            setOptions(options);
            forceUpdate();
            return;
        }


        const percentage = getRealNumber(s_value);

        // ignore if puting 0 after decimals ignore
        if ((percentage % 1 !== 0) && s_value.substr(s_value.length - 1) === "0") {
            return
        }

        const used_by_holder = props.holders.filter(h => typeof h.shares[share] != "undefined" ).reduce((acc, holder) => {
            acc[holder.identity] += Object.keys(value[holder.identity][share]).filter(o => o !== option_id).reduce((acc, o) => {
                return (acc + value[holder.identity][share][o]);
            }, 0);
            return(acc);
        },props.holders.filter(h => typeof h.shares[share] != "undefined" ).reduce((acc, holder) => {
            acc[holder.identity] = 0;
            return(acc);
        },{}));

        const used = Object.keys(used_by_holder).reduce((acc, identity) => {
            return(acc + used_by_holder[identity]);
        },0)



        const the_value = props.meeting.decimals ? roundTo(percentage * (total_shares[share]) / 100, config.precision) : roundTo(percentage * (total_shares[share]) / 100, 0);

        if (the_value + used > total_shares[share]) {
            // over max
            setTooMany(true);
            props.holders.filter(h => typeof h.shares[share] != "undefined" ).forEach(holder => {
                value[holder.identity][share][option_id] = 0;
            });
            options[share][option_id].display_value = 0;
            options[share][option_id].display_percentage = 0;
            setOptions(options);
            setValue(value);
            forceUpdate();
            return;
        }

        const overload = props.meeting.decimals ? (1 / Math.pow(10, config.precision)) : 1

        // the roundign can give overflow by 1
        const fixed_value = (getRealNumber(total_shares[share]) + overload === the_value) ? (the_value - overload) : the_value

        const overfullOptionforHolders = [];

        const assigned = props.holders.filter(h => typeof h.shares[share] != "undefined" ).reduce((acc, holder) => {
            // verify not overflow in one option
            const final_value = ((total_shares[share]-used) > 0)?roundTo(fixed_value * (props.question.max_marks * holder.shares[share].shares - used_by_holder[holder.identity]) / (total_shares[share]-used), props.meeting.decimals ? config.precision : 0):0;

            if (final_value > holder.shares[share].shares)
                overfullOptionforHolders.push(holder);

            // check if round or not
            value[holder.identity][share][option_id] = final_value;
            return (acc + value[holder.identity][share][option_id]);
        }, 0);

        if (overfullOptionforHolders.length > 0) {
            setOverflowOption(overfullOptionforHolders);
            props.holders.filter(h => typeof h.shares[share] != "undefined" ).forEach(holder => {
                value[holder.identity][share][option_id] = 0;
            });
            options[share][option_id].display_value = 0;
            options[share][option_id].display_percentage = 0;
            setOptions(options);
            setValue(value);
            forceUpdate();
            return;
        }

        //adjust if rouding give differences
        value[props.holders.filter(h => typeof h.shares[share] != "undefined" )[0].identity][share][option_id] += (fixed_value - assigned);

        options[share][option_id].display_value = the_value.toLocaleString(navigator.language, {
            maximumFractionDigits: config.precision
        });
        options[share][option_id].display_percentage = percentage.toLocaleString(navigator.language, {
            maximumFractionDigits: config.precision
        })


        setOptions(options);
        setValue(value);
        forceUpdate();

    }


    const updateValues = (share, option_id, event) => {
        let s_value = event.target.value;

        if (props.meeting.decimals) {
            // TODO: decimales o no
            // I remove forbidden chars
            s_value = s_value.replace(/[^,.0-9]/g, "");


            // I don't need to verify negatives because I replace everything different from number
            if (s_value.indexOf(sep) > -1 && s_value.substr(s_value.length - 1) === "0") {
                // is putting decimal separator, ignore
                props.holders.filter(h => typeof h.shares[share] != "undefined" ).forEach(holder => {
                    options[holder.identity][share][option_id].display_value = s_value.substr(0, s_value.length - 1) + sep;
                })
                setOptions(options);
                forceUpdate();
                return;
            }


        } else {
            // In any other meeting type decimals are not allowed
            s_value = s_value.replace(/[^0-9]/g, "");
        }

        const the_value = getRealNumber(s_value);

        // ignore if puting 0 after decimals ignore
        if ((the_value % 1 !== 0) && s_value.substr(s_value.length - 1) === "0") {
            return
        }


        // verify not overflow (sum more than allowed)
        //         if ((value + options[identity].used_out) > options[identity].total) {

        const used_by_holder = props.holders.filter(h => typeof h.shares[share] != "undefined" ).reduce((acc, holder) => {

            acc[holder.identity] += Object.keys(value[holder.identity][share]).filter(o => o !== option_id).reduce((acc, o) => {
                return (acc + value[holder.identity][share][o]);
            }, 0);
            return(acc);
        },props.holders.filter(h => typeof h.shares[share] != "undefined" ).reduce((acc, holder) => {
            acc[holder.identity] = 0;
            return(acc);
        },{}));


        const used = Object.keys(used_by_holder).reduce((acc, identity) => {
            return(acc + used_by_holder[identity]);
        },0)


        if (the_value + used > total_shares[share]) {
            // over max
            setTooMany(true);
            props.holders.filter(h => typeof h.shares[share] != "undefined" ).forEach(holder => {
                value[holder.identity][share][option_id] = 0;
            });
            options[share][option_id].display_value = 0;
            options[share][option_id].display_percentage = 0;
            setOptions(options);
            setValue(value);
            forceUpdate();
            return;
        }

        //
        const overfullOptionforHolders = [];

        const assigned = props.holders.filter(h => typeof h.shares[share] != "undefined" ).reduce((acc, holder) => {

            // verify not overflow in one option
            const final_value = ((total_shares[share]-used) > 0)?roundTo(the_value * (props.question.max_marks * holder.shares[share].shares - used_by_holder[holder.identity]) / (total_shares[share]-used), props.meeting.decimals ? config.precision : 0):0;


            if (final_value > holder.shares[share].shares)
                overfullOptionforHolders.push(holder);

            // check if round or not
            // I should assign in proportion to what is left
            value[holder.identity][share][option_id] = final_value;
            return (acc + value[holder.identity][share][option_id]);
        }, 0);

        if (overfullOptionforHolders.length > 0) {
            setOverflowOption(overfullOptionforHolders);
            props.holders.filter(h => typeof h.shares[share] != "undefined" ).forEach(holder => {
                value[holder.identity][share][option_id] = 0;
            });
            options[share][option_id].display_value = 0;
            options[share][option_id].display_percentage = 0;
            setOptions(options);
            setValue(value);
            forceUpdate();
            return;
        }
        //adjust if rounding gives differences

        value[props.holders.filter(h => typeof h.shares[share] != "undefined" )[0].identity][share][option_id] += (the_value - assigned);

        options[share][option_id].display_value = the_value.toLocaleString(navigator.language, {
            maximumFractionDigits: config.precision
        });
        options[share][option_id].display_percentage = roundTo(100 * the_value / (total_shares[share]), config.precision).toLocaleString(navigator.language, {
            maximumFractionDigits: config.precision
        })


        setOptions(options);
        setValue(value);
        forceUpdate();
    }

    const handleOptions = (share, option_id, option_name, kind) => {
        setOption([share, option_id, option_name]);
    }

    const ballotUpdateValue = (value) => {

        const totals = Object.keys(value).reduce((acc, elem) => {
            representative_question.forEach(share => {
                props.question.options.forEach(option => {
                    acc[share][option._id] += value[elem][share] ? value[elem][share][option._id] : 0;
                });
                acc[share]["blanks"] += value[elem][share] ? value[elem][share]["blanks"] : 0;
            });
            return (acc);

        }, representative_question.reduce((acc, share) => {
            acc[share] = props.question.options.reduce((acc, option) => {
                acc[option._id] = 0;
                return (acc);
            }, {});
            acc[share]["blanks"] = 0;
            return (acc);
        }, {}));


        setOptions(representative_question.reduce((acc, share) => {
            acc[share] = props.question.options.reduce((acc, option) => {
                acc[option._id] = totals[share][option._id] > 0 ? {
                    display_value: Number(totals[share][option._id]).toLocaleString(navigator.language, {
                        maximumFractionDigits: config.precision
                    }),
                    display_percentage: roundTo(100 * totals[share][option._id] / (total_shares[share]), config.precision).toLocaleString(navigator.language, {
                        maximumFractionDigits: config.precision
                    })
                } : {
                    display_value: '',
                    display_percentage: ''
                };
                return (acc);
            }, {});
            acc[share]["blanks"] = totals[share]["blanks"] > 0 ? {
                display_value: Number(totals[share]["blanks"]).toLocaleString(navigator.language, {
                    maximumFractionDigits: config.precision
                }),
                display_percentage: roundTo(100 * totals[share]["blanks"] / (total_shares[share]), config.precision).toLocaleString(navigator.language, {
                    maximumFractionDigits: config.precision
                })
            } : {
                display_value: '',
                display_percentage: ''
            };
            return (acc);
        }, {}));
        setValue(value);
        forceUpdate();
    }

    const aplicaFiltro = (o) => {
            const words = o.name.split(' ').map(w => w.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase());
            return (filtro.length < 1 || !filtro[0] || filtro.reduce((acc, w) => {
                return (acc && words.reduce((acc, p) => {
                    return (acc || p.startsWith(w));
                }, false));
            }, true));
    }


    return (

        <Fragment>
            {tooMany && <TooMany meeting={props.meeting} handleClose={() => setTooMany(false)}/>}
            {overflowOption.length && <OverflowOption question={props.question} messages={props.messages} meeting={props.meeting} holders={overflowOption} handleClose={() => setOverflowOption([])}/>}
            {option && <Ballot holders={props.holders} option={option} meeting={props.meeting}
                               question={props.question} value={value} setValue={ballotUpdateValue}
                               handleClose={() => setOption(null)}/>}
            {resume && <Resume answers={answers} question={props.question} meeting={props.meeting}
                               holders={props.holders} messages={props.messages} shares={props.shares}
                               handleClose={() => setResume(false)} onVote={props.onVote}/>}
            {underflow.length > 0 && <UnderFlow question={props.question} meeting={props.meeting}
                                                shares={props.shares} messages={props.messages}
                                                underflow={underflow}
                                                close={() => setUnderflow([])}
                                                holders={props.holders}/>}
            <Typography className={classes.paragraph}>
                {getMessage(props.meeting.meeting_type, 'vote_instruction', props.messages)}
            </Typography>

            <Grid container spacing={1}>
                <Grid item xs={12} sm={4} md={3} className={classes.state}>

                    <Typography className={classes.paragraph}>
                        {getMessage(props.meeting.meeting_type, 'shares_to_vote', props.messages)}
                        <br/>

                        {representative_question.map(k => (
                            <Fragment key={k}>
                                {props.shares[k].name}: <span
                                className={classes.number}>{(total_shares[k]).toLocaleString(navigator.language, {
                                maximumFractionDigits: config.precision
                            })}</span>
                                <br/>
                            </Fragment>
                        ))}
                    </Typography>


                </Grid>


                <Grid item xs={12} sm={4} md={3} className={classes.state}>


                    <Typography className={classes.paragraph}>
                        {getMessage(props.meeting.meeting_type, 'shares_voting', props.messages)}
                        <br/>
                        <span>{representative_question.map(k => (
                            <Fragment key={k}>
                                {props.shares[k].name}: <span
                                className={classes.number}>{Number(Object.keys(totals[k]).reduce((acc, elem) => acc + totals[k][elem], 0)).toLocaleString(navigator.language, {// shares[k].toLocaleString(navigator.language, {
                                maximumFractionDigits: config.precision
                            })}</span>
                                <br/>
                            </Fragment>
                        ))}</span>

                    </Typography>


                </Grid>


                <Grid item xs={12} sm={4} md={3} className={classes.state}>

                    <Typography className={classes.paragraph}>
                        {getMessage(props.meeting.meeting_type, props.question.config.explicit_blank?'shares_for_voting':'shares_blanks', props.messages)}
                        <br/>
                        <span>{representative_question.map(k => (
                            <Fragment key={k}>
                                {props.shares[k].name}: <span
                                className={classes.number}>{((total_shares[k]) - Number(Object.keys(totals[k]).reduce((acc, elem) => acc + totals[k][elem], 0))).toLocaleString(navigator.language, {
                                maximumFractionDigits: config.precision
                            })}</span>
                                <br/>
                            </Fragment>
                        ))}</span>


                    </Typography>


                </Grid>


            </Grid>

            {props.question.config.searchable === true && (<Typography className={classes.paragraph} variant={"h6"}>{getMessage(props.meeting.meeting_type, 'searchable_text', props.messages)}: <TextField id="standard-basic"
                                                                                                                                                                    inputRef={filtroIn}
                                                                                                                                                                    InputProps={{
                                                                                                                                                                        endAdornment: (filtro.length > 0 && <InputAdornment position="end"><Button  onClick={() => clearFiltro()}><Clear/></Button></InputAdornment>),
                                                                                                                                                                    }}
                                                                                                                                                                    onChange={filtrar}></TextField></Typography>)}


            <form noValidate>
                <Grid container>


                    {representative_question.map(k => (
                        <Grid item xs key={k}>


                            <Typography variant={"h6"} className={classes.share}>
                                {props.meeting.shares[k].name}
                            </Typography>

                            {props.question.options.sort((a, b) => {
                                return (a.order - b.order);
                            }).map(o => (

                                <Box key={o._id} className={aplicaFiltro(o)?classes.unhide:classes.hide}>


                                    <InputLabel htmlFor={o._id}
                                                className={classes.label}>{o.name} </InputLabel>

                                    <TextField
                                        label={getMessage(props.meeting.meeting_type, 'shares', props.messages)}
                                        type="text"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        InputProps={{
                                            className: classes.number
                                        }}

                                        id={"V-" + k + "-" + o._id}
                                        className={classes.textField}
                                        margin="dense"
                                        variant="outlined"
                                        onChange={(e) => updateValues(k, o._id, e)}
                                        value={options[k][o._id].display_value}

                                        onKeyPress={keyPress}
                                    />


                                    <TextField
                                        label="%"
                                        type="text"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        InputProps={{
                                            className: classes.number
                                        }}
                                        id={"P-" + k + "-" + o._id}
                                        className={classes.textField}
                                        value={options[k][o._id].display_percentage}
                                        margin="dense"
                                        variant="outlined"
                                        onChange={(e) => updatePercentage(k, o._id, e)}
                                        onKeyPress={keyPress}
                                    />
                                    {props.holders.length > 1 && <Typography>
                                        <Link
                                            onClick={() => handleOptions(k, o._id, o.name)}>{getMessage(props.meeting.meeting_type, 'detail_by_holder', props.messages)}</Link>
                                    </Typography>}
                                </Box>


                            ))}
                            {props.question.config.explicit_blank && (
                            <Box  className={classes.paragraph}>


                                <InputLabel htmlFor={"V-" + k + "-blanks"}
                                            className={classes.label}>{getMessage(props.meeting.meeting_type, 'blank', props.messages)} </InputLabel>

                                <TextField
                                    label={getMessage(props.meeting.meeting_type, 'shares', props.messages)}
                                    type="text"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    InputProps={{
                                        className: classes.number
                                    }}

                                    id={"V-" + k + "-blanks" }
                                    className={classes.textField}
                                    margin="dense"
                                    variant="outlined"
                                    onChange={(e) => updateValues(k, "blanks", e)}
                                    value={options[k]["blanks"].display_value}

                                    onKeyPress={keyPress}
                                />


                                <TextField
                                    label="%"
                                    type="text"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    InputProps={{
                                        className: classes.number
                                    }}
                                    id={"P-" + k + "-blanks"}
                                    className={classes.textField}
                                    value={options[k]["blanks"].display_percentage}
                                    margin="dense"
                                    variant="outlined"
                                    onChange={(e) => updatePercentage(k, "blanks", e)}
                                    onKeyPress={keyPress}
                                />
                                {props.holders.length > 1 && <Typography>
                                    <Link
                                        onClick={() => handleOptions(k, "blanks", getMessage(props.meeting.meeting_type, 'blank', props.messages))}>{getMessage(props.meeting.meeting_type, 'detail_by_holder', props.messages)}</Link>
                                </Typography>}
                            </Box>)}

                            {props.question.nullable && <Container className={classes.option}>


                                <Box>
                                    <InputLabel htmlFor={"nulos"}
                                                className={classes.label}>{getMessage(props.meeting.meeting_type, 'nulls', props.messages)} </InputLabel>

                                    <TextField
                                        label={getMessage(props.meeting.meeting_type, 'shares', props.messages)}
                                        type="text"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        InputProps={{
                                            className: classes.number,
                                        }}
                                        id={"V-nulos"}
                                        className={classes.textField}
                                        margin="dense"
                                        variant="outlined"
                                        // onChange={() => updateValues(null, k)}
                                        //onKeyPress={keyPress}
                                    />
                                    <TextField
                                        label="%"
                                        type="text"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        InputProps={{
                                            className: classes.number,
                                        }}
                                        id={"P-nulos"}

                                        className={classes.textField}
                                        margin="dense"
                                        variant="outlined"
                                        //onChange={() => updatePercentage(null, k)}
                                        //onKeyPress={keyPress}
                                        //onBlur={() => updateValues(null, k)}
                                    />
                                </Box>


                            </Container>}

                        </Grid>
                    ))}


                    <Container className={classes.option}>

                        <Button variant="contained" color={"primary"} onClick={vote}>
                            {getMessage(props.meeting.meeting_type, 'vote', props.messages)}
                        </Button>
                    </Container>


                </Grid>
            </form>

        </Fragment>


    )
}

export default OptionsDistributed;