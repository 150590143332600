import React, {Fragment, useEffect, useState} from 'react';
import Challenges from "./Challenges";
import RutSerial from "./RutSerial";
import Container from "@material-ui/core/Container";
import {makeStyles} from "@material-ui/core/styles";
import logo from "../images/logo.svg";
import logo_small from "../images/logo_small.svg";
import Loading from "./Loading";
import Typography from "@material-ui/core/Typography";
import RutSerialOnly from "./RutSerialOnly";
import IDPassword from "./IDPassword";
import {getLanguages, getMessage, loadMessages, useCaptcha} from "../util";
import Footer from "./Footer";
import Grid from "@material-ui/core/Grid";
import {MenuItem, Select} from "@material-ui/core";
import HTTPClient from "../HTTPClient";
import CssBaseline from "@material-ui/core/CssBaseline";


const useStyles = makeStyles(theme => ({
        paper: {
            marginTop: theme.spacing(4),
            display: 'flex',
            alignItems: 'left',
        },
        meeting_logo: {
            width: '100%'
        },
        logo: {
            marginTop: '20px',
            flexDirection: 'inherit'
        },
        right: {
            float: 'right',
            clear: 'right',
            width: '20px'
        }
    }
));

export default function MeetingSignIn(props) {

    useCaptcha()

    const meeting_id = '76w8xLOm';

    const [challenges, setChallenges] = useState(null);
    const [loading, setLoading] = useState(true);
    const [meeting, setMeeting] = useState(null);
    const [messages, setMessages] = useState(null);


    const classes = useStyles();

    const loadMeeting = () => {

        HTTPClient({
            method: "get",
            url:
                process.env.REACT_APP_API_URL +
                "/meetings/" + meeting_id + "/base"
        })
            .then(response => {

                const data = response.data;
                data.start_date = new Date(data.start_date);
                data.start_date = new Date(data.end_date);
                loadMessages(data.messages, (error, messages) => {
                    const nav_lang = navigator.language.split('-')[0];
                    if (messages) {
                        messages.lang = messages.languages.length > 1 ? (localStorage.getItem('eholder-lang-' + meeting_id) || nav_lang) : nav_lang;
                    }
                    setMessages(messages);
                    setMeeting(data);
                    setLoading(false);
                });


            })
            .catch((error) => {
                console.log('ERROR', error);
                setLoading(false);
            });

    }

    useEffect(loadMeeting, []);

    const resetLanguage = (event) => {
        localStorage.setItem('eholder-lang-' + meeting_id, event.target.value);
        messages.lang = event.target.value;
        setMessages({...messages});
    }

    return (
        <Fragment>
            {loading ? (
                <Loading/>
            ) : (meeting ? (
                    <Fragment>

                        <Container component="main" maxWidth="xs">
                            <br/>
                            <Grid container>
                                <Grid item xs={11}>
                                    {getLanguages(messages).length > 1 &&
                                    <Select value={messages ? messages.lang : getLanguages(messages)[0][0]}
                                            onChange={resetLanguage}>
                                        {getLanguages(messages).map(l => (
                                            <MenuItem key={l[0]} value={l[0]}>{l[1]}</MenuItem>
                                        ))}
                                    </Select>}
                                </Grid>
                                <Grid item xs={1} className={classes.right}>
                                    <img src={logo_small} alt="logo"/>
                                </Grid>
                            </Grid>

                            <div className={classes.paper}>
                                <img src={meeting.logo} alt="logo" className={classes.meeting_logo}/>
                            </div>
                        </Container>

                        {meeting.authentication === 'serial' && <RutSerialOnly meeting={meeting} messages={messages}/>}
                        {(meeting.authentication === 'password' || meeting.authentication === 'rut_password') &&
                        <IDPassword meeting={meeting} messages={messages}/>}

                        {meeting.authentication === 'questions' && (challenges ? (
                            <Challenges setChallenges={setChallenges} meeting={meeting} challenges={challenges}
                                        messages={messages}
                            />
                        ) : (
                            <RutSerial setChallenges={setChallenges} meeting={meeting} messages={messages}/>
                        ))
                        }


                        <Footer messages={messages} meeting={meeting}/>

                    </Fragment>
                ) : (
                    <Typography variant="h5" component="h2">
                        <Container component="main" maxWidth="xs">
                            <div className={classes.paper}>
                                <img src={logo} alt="logo"/>
                            </div>
                        </Container>
                        {getMessage('default', 'meeting_not_found', messages)}
                    </Typography>
                )


            )}


        </Fragment>
    );
}