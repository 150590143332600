import React, {Fragment, useEffect, useState} from "react";
import HTTPClient from "../../HTTPClient";
import Header from "../Header";
import Loading from "../Loading";
import Typography from "@material-ui/core/Typography";
import MeetingOpen from "./MeetingOpen";
import MeetingFinished from "./MeetingFinished";
import MeetingReady from "./MeetingReady";
import Question from "../question/Question";
import Shares from "./Shares";
import Button from "@material-ui/core/Button";
import MeetingTitle from "./MeetingTitle";
import {getMessage} from "../../util";
import Footer from "../Footer";
import * as axios from "axios";
import TableContainer from "@material-ui/core/TableContainer";
import {Paper, TableCell} from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import config from "../../config.json";
import Container from "@material-ui/core/Container";


const loadMeeting = async (meeting_id) => {
    const meeting = await HTTPClient({
        method: "get",
        url:
            process.env.REACT_APP_API_URL +
            "/meetings/" + meeting_id
    });

    // check if open question
    const open_questions = meeting.data.questions.filter(q => {
        return (!q.url && q.state === 'open')
    });

    if (open_questions.length > 0) {
        window.location = '/meeting/questions';
    }

    return (meeting.data);
}

const Meeting = (props) => {
    const [loading, setLoading] = useState(false);
    const [meeting, setMeeting] = useState(null);
    const meeting_id = '76w8xLOm';
    const [question, setQuestion] = useState(null);
    const [representative, setRepresentative] = useState(null);
    const [messages, setMessages] = useState(null);
    const [hash, setHash] = useState("none");
    const [shares, setShares] = useState(null);
    const [holders, setHolders] = useState([]);

    const resetLanguage = (event) => {
        localStorage.setItem('eholder-lang-'+meeting_id, event.target.value);
        messages.lang = event.target.value;
        setMessages({...messages});
    }

    // effect to reload meeting when changed hash
    useEffect(() => {
        (async () => {
            try {
                const meeting = await loadMeeting(meeting_id);
                setMeeting(meeting);
            } catch (error) {
                console.log(error);

                switch (error.response.status) {
                    case 401:
                        window.location = '/login';
                        break;
                    default:
                        setLoading(false);
                        break;
                }
            }
        })()
    }, [meeting_id, hash]);

    // effect only once
    useEffect(() => {
        setLoading(true);

        (async () => {
            try {
                // load the meeting the first time
                const meeting = await loadMeeting(meeting_id);


                // load messages
                if (meeting.messages) {
                    const load_messages = await axios({
                        method: "get",
                        url: meeting.messages
                    });
                    const messages = load_messages.data;
                    const nav_lang = navigator.language.split('-')[0];

                    messages.lang = messages.languages.length > 1 ? (localStorage.getItem('eholder-lang-' + meeting_id) || nav_lang) : nav_lang;
                    setMessages(messages);
                }

                //load user
                const me = await HTTPClient({
                    method: "get",
                    url:
                        process.env.REACT_APP_API_URL +
                        "/meetings/"+meeting_id+"/me"
                });

                const holders = await HTTPClient({
                    method: "get",
                    url:
                        process.env.REACT_APP_API_URL +
                        "/meetings/"+meeting_id+"/me/holders"
                });

                const shares = await HTTPClient({
                    method: "get",
                    url:
                        process.env.REACT_APP_API_URL +
                        "/meetings/"+meeting_id+"/me/shares"
                });

                setShares(shares.data);
                setHolders(holders.data);
                setMeeting(meeting);
                setRepresentative(me.data);

                setLoading(false);


                const intervalId = setInterval(() => {
                    (async () => {
                        try {
                            const got_hash = await HTTPClient({
                                method: "get",
                                url:
                                    process.env.REACT_APP_API_URL +
                                    "/meetings/"+meeting_id+"/hash"
                            });

                            setHash(got_hash.data);
                        } catch (error) {
                            switch (error.response.status) {
                                case 401:
                                    window.location = '/login';
                                    break;
                                case 403:
                                    window.location = '/meeting/pending';
                                    break;

                                default:
                                    setLoading(false);
                                    break;
                            }
                        }
                    })();
                }, 3000);
                return (() => {
                    clearInterval(intervalId);
                });


            } catch (error) {
                console.log(error);


                switch (error.response.status) {
                    case 401:
                        window.location = '/login';
                        break;
                    case 403:
                        window.location = '/meeting/pending';
                        break;
                    default:
                        setLoading(false);
                        break;
                }
            }
        })();
    }, [meeting_id]);


    const getState = (meeting) => {


        switch (meeting.state) {
            case 'open':
                return (<MeetingOpen user={representative} messages={messages} meeting={meeting}
                                     setQuestion={setQuestion}/>);
            case 'finished':
                return (<MeetingFinished user={representative} messages={messages} meeting={meeting}
                                         setQuestion={setQuestion}/>);
            default:
                return (<MeetingReady user={representative} messages={messages} meeting={meeting}
                                      setQuestion={setQuestion}/>);

        }
    }

    const number = {
        fontFamily: "Montserrat"
    };

    return (
        <Fragment>
            {loading ? (
                <Loading/>
            ) : ((meeting && representative && shares) ? (
                    <Fragment>
                        <Header messages={messages} user={representative} logo={meeting.logo} meeting={meeting}
                                shares={shares} resetLanguage={resetLanguage}/>
                        <hr/>

                        <MeetingTitle messages={messages} meeting={meeting}/>


                        <Shares messages={messages} meeting={meeting}/>
                        {representative.observer &&
                        <Button variant="contained" color={"default"} href={"/meeting/" + meeting.slug + "/search"}>
                            {getMessage(meeting.meeting_type, 'watch_attendance', messages)}
                        </Button>}

                        {question ? (
                            <Question messages={messages} question={question} meeting={meeting}
                                      setQuestion={setQuestion}
                                      user={props.user}/>
                        ) : (
                            getState(meeting)
                        )}


                        {meeting && holders.length > 0 && (

                            <Container>
                                <br/>
                                <br/>
                                <Typography variant={"h6"}>
                                    {getMessage(meeting.meeting_type, 'holders', messages)}
                                </Typography>
                                <TableContainer component={Paper}>
                                    <Table aria-label="representados">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>{getMessage(meeting.meeting_type, 'search_id', messages)}</TableCell>
                                                <TableCell>{getMessage(meeting.meeting_type, 'search_name', messages)}</TableCell>
                                                <TableCell>{getMessage(meeting.meeting_type, 'shares', messages)}</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {holders.sort((a, b) => a.order - b.order).map(h => (
                                                <TableRow key={h.identity}>
                                                    <TableCell component="th" scope="row">
                                                        {h.identity}
                                                    </TableCell>
                                                    <TableCell component="th" scope="row">
                                                        {h.name}
                                                    </TableCell>
                                                    <TableCell>
                                                        {Object.keys(h.shares).map(s => (
                                                            <Fragment key={s}>
                                <span>{h.shares[s].name}: <span
                                    style={number}>{h.shares[s].shares.toLocaleString(navigator.language, {
                                    maximumFractionDigits: config.precision
                                })}</span></span>
                                                                <br/>
                                                            </Fragment>

                                                        ))}
                                                    </TableCell>

                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Container>
                        )}


                        <Footer messages={messages} meeting={meeting}/>

                    </Fragment>
                ) : (
                    <Typography variant="h5" component="h2">
                        {getMessage('default', 'meeting_not_found', null)}
                    </Typography>
                )

            )}


        </Fragment>

    );
}

export default Meeting;