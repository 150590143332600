import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Button from "@material-ui/core/Button";
import React, {Fragment} from "react";
import {makeStyles} from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import Typography from "@material-ui/core/Typography";
import config from "../../config.json"
import {getMessage} from "../../util";

const useStyles = makeStyles(theme => ({
        paper: {
            marginTop: theme.spacing(4),
            marginBottom: theme.spacing(2),
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'left',
        },
        number: {
            fontFamily: "Montserrat",
            "font-size": "1.3rem"
        },
        paragraph: {
            margin: theme.spacing(2)
        },
        cardGrid: {
            paddingTop: theme.spacing(8),
            paddingBottom: theme.spacing(8),
        },
        card: {
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
        },
        cardActions: {
            justifyContent: 'center',
        },
        cardContent: {
            flexGrow: 1,
        },
        option: {
            margin: theme.spacing(3),
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',

        },
        label: {
            alignItems: 'left',
        },
        textField: {
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(1),
            width: '25ch',
        },
        table: {
            width: '90%',
        },
        thead: {
            textTransform: "uppercase"
        }

    }
));

const QuestionFinishedMultiserie = (props) => {
    const classes = useStyles();

    const question_shares = Object.keys(props.question.blanks)
    const ordered_shares = Object.keys(props.question.quorum).filter((x) => {
        if (question_shares.indexOf(x) === -1)
            return false;
        else
            return true;
    });

    const weighted = ordered_shares.reduce((acc, k) => acc || props.question.quorum[k].rights !== 1, false);


    const represented = Object.keys(props.question.blanks).map(k => {
        return (props.question.quorum[k].rights * props.question.quorum[k].represented);
    }).reduce((acc, elem) => {
        return (acc + elem);
    }, 0) * props.question.max_marks;

    const represented_total = Object.keys(props.question.blanks).map(k => {
        return (props.question.quorum[k].rights * props.question.quorum[k].total);
    }).reduce((acc, elem) => {
        return (acc + elem);
    }, 0) * props.question.max_marks;



    const unvoted = ordered_shares.map(k => {
        return (props.meeting.shares[k].rights * (props.question.max_marks * props.question.unvoted[k].shares));
    }).reduce((acc, elem) => {
        return (acc + elem);
    }, 0);


    const blanks = ordered_shares.map(k => {
        return (props.question.quorum[k].rights * (props.question.blanks[k].shares));
    }).reduce((acc, elem) => {
        return (acc + elem);
    }, 0);

    const nulls = ordered_shares.map(k => {
        return (props.question.quorum[k].rights * props.question.nulls[k].shares);
    }).reduce((acc, elem) => {
        return (acc + elem);
    }, 0);

    const total = props.question.options.reduce((acc, elem) => {
        Object.keys(elem.votes).forEach(k => {
            acc[elem._id] = (acc[elem._id] ? acc[elem._id] : 0) + elem.votes[k].shares * props.question.quorum[k].rights;
        })
        return (acc);
    }, {});

    const total_all = Object.keys(total).reduce((acc, elem) => {
        return (acc + total[elem]);
    }, 0);


    const total_by_serie = props.question.options.reduce((acc, o) => {
        Object.keys(o.votes).forEach(k => {
            acc[k] = (acc[k] ? acc[k] : 0) + o.votes[k].shares;
        });
        return (acc);
    }, {});

    const order = props.question.options.sort((a, b) => {
        return (total[b._id] - total[a._id]);
    });


    return (
        <Card variant="outlined" className={classes.card}>
            <CardHeader title={props.question.name} titleTypographyProps={{variant: 'h6'}}/>


            {props.question.config.display_results ? (

                <Container>
                    {(typeof props.question.acclamation !== 'undefined') ? (
                        <Container className={classes.option}>
                            {props.question.acclamation ? (
                                <Typography variant={"h5"}>
                                    {getMessage(props.meeting.meeting_type, 'approved_by_acclamation', props.messages)}
                                </Typography>
                            ) : (
                                <Typography variant={"h5"}>
                                    {getMessage(props.meeting.meeting_type, 'refuse_by_acclamation', props.messages)}
                                </Typography>
                            )}


                        </Container>

                    ) : (
                        <CardContent className={classes.cardContent} variant={"h6"}>
                            {getMessage(props.meeting.meeting_type, 'results', props.messages)}:
                            <TableContainer>
                                <Table className={classes.table} aria-label="simple table">
                                    <TableHead className={classes.thead}>
                                        {(weighted || Object.keys(props.question.quorum).length > 1) && (
                                            <TableRow>
                                                <TableCell>Opción</TableCell>
                                                {ordered_shares.map(k => (
                                                    <TableCell colSpan={2} key={k}
                                                               align="center">{props.question.quorum[k].name}</TableCell>
                                                ))}

                                                {(weighted || ordered_shares.length > 1) &&
                                                <TableCell colSpan={2}
                                                           align="center">{getMessage(props.meeting.meeting_type, weighted ? 'total_weighted' : 'total', props.messages)}</TableCell>}
                                            </TableRow>
                                        )}
                                        <TableRow>
                                            <TableCell/>
                                            {(weighted || ordered_shares.length > 1) && ordered_shares.map(k => (
                                                <Fragment key={k}>
                                                    <TableCell
                                                        align="right">{getMessage(props.meeting.meeting_type, 'shares', props.messages)}</TableCell>
                                                    <TableCell align="right">%</TableCell>
                                                </Fragment>
                                            ))}
                                            <TableCell
                                                align="right">{getMessage(props.meeting.meeting_type, 'total', props.messages)}</TableCell>
                                            {props.question.config.percentage_valid && <TableCell
                                                align="right">{getMessage(props.meeting.meeting_type, 'percentage_valid', props.messages)}</TableCell>}
                                            {props.question.config.percentage_attendees && <TableCell
                                                align="right">{getMessage(props.meeting.meeting_type, 'percentage_attendees', props.messages)}</TableCell>}
                                            {props.question.config.percentage_total && <TableCell
                                                align="right">{getMessage(props.meeting.meeting_type, 'percentage_total', props.messages)}</TableCell>}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {order.map(o => (
                                            <TableRow key={o._id}>
                                                <TableCell component="th" scope="row">
                                                    {o.name}
                                                </TableCell>
                                                {(weighted || ordered_shares.length > 1) && ordered_shares.map(k => (
                                                    <Fragment key={k}>
                                                        <TableCell align="right" className={classes.number}>
                                                            {o.votes[k].shares.toLocaleString(navigator.language, {
                                                                maximumFractionDigits: config.precision
                                                            })}
                                                        </TableCell>
                                                        <TableCell className={classes.number}
                                                                   align="right">{(props.question.quorum[k].represented ? (100 * o.votes[k].shares / (props.question.max_marks * props.question.quorum[k].represented)) : 0).toLocaleString(navigator.language, {
                                                            maximumFractionDigits: config.precision,
                                                            minimumFractionDigits: 2
                                                        })}

                                                        </TableCell>
                                                    </Fragment>
                                                ))}
                                                <TableCell className={classes.number}
                                                           align="right">{total[o._id].toLocaleString(navigator.language, {
                                                    maximumFractionDigits: config.precision
                                                })}</TableCell>
                                                {props.question.config.percentage_valid && <TableCell
                                                    align="right"
                                                    className={classes.number}>{(total_all ? (100 * total[o._id] / total_all) : 0).toLocaleString(navigator.language, {
                                                    maximumFractionDigits: config.precision, minimumFractionDigits: 2
                                                })}</TableCell>}
                                                {props.question.config.percentage_attendees && <TableCell
                                                    align="right"
                                                    className={classes.number}>{(represented ? (100 * total[o._id] / represented) : 0).toLocaleString(navigator.language, {
                                                    maximumFractionDigits: config.precision, minimumFractionDigits: 2
                                                })}
                                                </TableCell>}
                                                {props.question.config.percentage_total && <TableCell
                                                    align="right"
                                                    className={classes.number}>{(represented_total ? (100 * total[o._id] / represented_total) : 0).toLocaleString(navigator.language, {
                                                    maximumFractionDigits: config.precision, minimumFractionDigits: 2
                                                })}</TableCell>}
                                            </TableRow>
                                        ))}
                                        {props.question.max_marks !== props.question.min_marks && <TableRow>
                                            <TableCell component="th" scope="row">
                                                {getMessage(props.meeting.meeting_type, 'blanks', props.messages)}
                                            </TableCell>
                                            {(weighted || ordered_shares.length > 1) && ordered_shares.map(k => (
                                                <Fragment key={k}>
                                                    <TableCell align="right" className={classes.number}>
                                                        {(props.question.blanks[k].shares+(props.question.config.add_unvoted_to_blanks?props.question.unvoted[k].shares:0)).toLocaleString(navigator.language, {
                                                            maximumFractionDigits: config.precision
                                                        })}
                                                    </TableCell>
                                                    <TableCell
                                                        align="right"
                                                        className={classes.number}>{(props.question.quorum[k].represented ? (100 * (props.question.blanks[k].shares+(props.question.config.add_unvoted_to_blanks?props.question.unvoted[k].shares:0)) / (props.question.max_marks * props.question.quorum[k].represented)) : 0).toLocaleString(navigator.language, {
                                                        maximumFractionDigits: config.precision,
                                                        minimumFractionDigits: 2
                                                    })}</TableCell>
                                                </Fragment>
                                            ))}
                                            <TableCell align="right"
                                                       className={classes.number}>{(blanks+(props.question.config.add_unvoted_to_blanks?unvoted:0)).toLocaleString(navigator.language, {
                                                maximumFractionDigits: config.precision
                                            })}</TableCell>
                                            {props.question.config.percentage_valid && <TableCell
                                                align="right" className={classes.number}> - </TableCell>}
                                            {props.question.config.percentage_attendees && <TableCell
                                                align="right"
                                                className={classes.number}>{(represented ? (100 * (blanks+(props.question.config.add_unvoted_to_blanks?unvoted:0)) / represented) : 0).toLocaleString(navigator.language, {
                                                maximumFractionDigits: config.precision, minimumFractionDigits: 2
                                            })}</TableCell>}
                                            {props.question.config.percentage_total && <TableCell
                                                align="right"
                                                className={classes.number}>{(represented_total ? (100 * (blanks+(props.question.config.add_unvoted_to_blanks?unvoted:0))/ represented_total) : 0).toLocaleString(navigator.language, {
                                                maximumFractionDigits: config.precision, minimumFractionDigits: 2
                                            })}</TableCell>}
                                        </TableRow>}
                                        {!props.question.config.add_unvoted_to_blanks && <TableRow>
                                            <TableCell component="th" scope="row">
                                                {getMessage(props.meeting.meeting_type, 'unvoted', props.messages)}
                                            </TableCell>
                                            {(weighted || ordered_shares.length > 1) && ordered_shares.map(k => (
                                                <Fragment key={k}>
                                                    <TableCell align="right" className={classes.number}>
                                                        {(props.question.max_marks * props.question.unvoted[k].shares).toLocaleString(navigator.language, {
                                                            maximumFractionDigits: config.precision
                                                        })}
                                                    </TableCell>
                                                    <TableCell
                                                        align="right"
                                                        className={classes.number}>{(props.meeting.shares[k].represented ? (100 * (props.question.max_marks * props.question.unvoted[k].shares) / (props.question.max_marks * props.meeting.shares[k].represented)) : 0).toLocaleString(navigator.language, {
                                                        maximumFractionDigits: config.precision,
                                                        minimumFractionDigits: 2
                                                    })}</TableCell>
                                                </Fragment>
                                            ))}
                                            <TableCell align="right"
                                                       className={classes.number}>{unvoted.toLocaleString(navigator.language, {
                                                maximumFractionDigits: config.precision
                                            })}</TableCell>
                                            {props.question.config.percentage_valid && <TableCell
                                                align="right" className={classes.number}> - </TableCell>}
                                            {props.question.config.percentage_attendees && <TableCell
                                                align="right"
                                                className={classes.number}>{(represented ? (100 * unvoted / represented) : 0).toLocaleString(navigator.language, {
                                                maximumFractionDigits: config.precision, minimumFractionDigits: 2
                                            })}</TableCell>}
                                            {props.question.config.percentage_total && <TableCell
                                                align="right"
                                                className={classes.number}>{(represented_total ? (100 * unvoted / represented_total) : 0).toLocaleString(navigator.language, {
                                                maximumFractionDigits: config.precision, minimumFractionDigits: 2
                                            })}</TableCell>}
                                        </TableRow>}
                                        {props.question.nullable && <TableRow>
                                            <TableCell component="th" scope="row">
                                                {getMessage(props.meeting.meeting_type, 'nulls', props.messages)}
                                            </TableCell>
                                            {(weighted || ordered_shares.length > 1) && ordered_shares.map(k => (
                                                <Fragment key={k}>
                                                    <TableCell align="right" className={classes.number}>
                                                        {props.question.nulls[k].shares.toLocaleString(navigator.language, {
                                                            maximumFractionDigits: config.precision
                                                        })}
                                                    </TableCell>
                                                    <TableCell
                                                        align="right"
                                                        className={classes.number}>{(props.question.quorum[k].represented ? (100 * props.question.nulls[k].shares / (props.question.max_marks * props.question.quorum[k].represented)) : 0).toLocaleString(navigator.language, {
                                                        maximumFractionDigits: config.precision,
                                                        minimumFractionDigits: 2
                                                    })}</TableCell>
                                                </Fragment>
                                            ))}
                                            <TableCell align="right"
                                                       className={classes.number}>{nulls.toLocaleString(navigator.language, {
                                                maximumFractionDigits: config.precision
                                            })}</TableCell>
                                            {props.question.config.percentage_valid && <TableCell
                                                align="right" className={classes.number}> - </TableCell>}
                                            {props.question.config.percentage_attendees && <TableCell
                                                align="right"
                                                className={classes.number}>{(represented ? (100 * nulls / represented) : 0).toLocaleString(navigator.language, {
                                                maximumFractionDigits: config.precision, minimumFractionDigits: 2
                                            })}</TableCell>}
                                            {props.question.config.percentage_total && <TableCell
                                                align="right"
                                                className={classes.number}>{(represented_total ? (100 * nulls / represented_total) : 0).toLocaleString(navigator.language, {
                                                maximumFractionDigits: config.precision, minimumFractionDigits: 2
                                            })}</TableCell>}
                                        </TableRow>}
                                        <TableRow>
                                            <TableCell component="th" scope="row">
                                                {getMessage(props.meeting.meeting_type, 'total', props.messages)}
                                            </TableCell>
                                            {(weighted || ordered_shares.length > 1) && ordered_shares.map(k => (
                                                <Fragment key={k}>
                                                    <TableCell align="right" className={classes.number}>
                                                        {(total_by_serie[k] + (props.question.blanks[k].shares + props.question.max_marks * props.question.unvoted[k].shares) + props.question.nulls[k].shares).toLocaleString(navigator.language, {
                                                            maximumFractionDigits: config.precision
                                                        })}
                                                    </TableCell>
                                                    <TableCell align="right" className={classes.number}>100</TableCell>
                                                </Fragment>
                                            ))}
                                            <TableCell align="right"
                                                       className={classes.number}>{(total_all + blanks + nulls + unvoted).toLocaleString(navigator.language, {
                                                maximumFractionDigits: config.precision
                                            })}</TableCell>
                                            {props.question.config.percentage_valid && <TableCell align="right"
                                                                                                  className={classes.number}>{total_all ? 100 : 0}</TableCell>}
                                            {props.question.config.percentage_attendees &&
                                            <TableCell align="right" className={classes.number}>100</TableCell>}
                                            {props.question.config.percentage_total && <TableCell align="right"
                                                                                                  className={classes.number}>{(represented_total ? (100 * (total_all + blanks + nulls + unvoted) / represented_total) : 0).toLocaleString(navigator.language, {
                                                maximumFractionDigits: config.precision, minimumFractionDigits: 2
                                            })}</TableCell>}
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </CardContent>


                    )}

                </Container>

            ) : (
                <Container>
                    <Typography variant={"h6"}>
                        {getMessage(props.meeting.meeting_type, 'question_to_display', props.messages)}
                    </Typography>
                </Container>

            )}


            <CardActions className={classes.cardActions}>

                {(props.user.observer && (typeof props.question.acclamation === 'undefined')) &&
                (props.goDetails ? (
                    <Button size="small" variant="outlined" color={"default"}
                            onClick={props.goDetails}>
                        {getMessage(props.meeting.meeting_type, 'watch_voting_detail', props.messages)}</Button>
                ) : (<Button variant="contained" color={"default"}
                             href={"/meeting/" + props.meeting.slug + "/question/" + props.question.slug + "/answers"}>
                    {getMessage(props.meeting.meeting_type, 'watch_voting_detail', props.messages)}</Button>))}

                {props.volver && <Button variant="contained" color={"default"} href={"/meeting/" + props.meeting.slug}>
                    {getMessage(props.meeting.meeting_type, 'back', props.messages)}
                </Button>}
            </CardActions>
        </Card>
    )
}

export default QuestionFinishedMultiserie;