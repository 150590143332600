import default_messages from "./messages.json";
import * as axios from "axios";
import {useEffect} from "react";

export const getLanguages = (messages) => {
    if (messages && messages.languages) return(messages.languages);
    return(default_messages.languages);
}

export const getMessage = (type, key, messages) => {
const language = (messages && messages.lang) || navigator.language;

    const getDefaultMessage = (type, key) => {

        if (!default_messages[type]) {

            if (!default_messages.default[language]) {
                if (!default_messages.default.default[key]) {
                    return (default_messages.default.default.nokey);
                }
                return (default_messages.default.default[key]);
            } else {
                if (!default_messages.default[language][key]) {
                    if (!default_messages.default.default[key]) {
                        return (default_messages.default.default.nokey);
                    }
                    return (default_messages.default.default[key]);
                } else {
                    return (default_messages.default[language][key]);
                }
            }
        } else {
            if (!default_messages[type][language]) {
                if (!default_messages[type].default || !default_messages[type].default[key]) {
                    if (!default_messages.default.default[key]) {
                        return (default_messages.default.default.nokey);
                    }
                    return (default_messages.default.default[key]);
                }
                return (default_messages[type].default[key]);
            } else {
                if (!default_messages[type][language][key]) {
                    if (!default_messages[type].default[key]) {
                        return (default_messages[type].default.nokey);
                    }
                    return (default_messages[type].default[key]);
                } else {
                    return (default_messages[type][language][key]);
                }
            }

        }
    }


    if (messages && messages[type] && messages[type][language] && messages[type][language][key]) {
        return (messages[type][language][key]);
    }

    if (messages && messages[type] && messages[type].default && messages[type].default[key]) {

        return (messages[type].default[key]);
    }

    if (messages && messages.default && messages.default[language] && messages.default[language][key]) {

        return ( messages.default[language][key]);
    }

    if (messages && messages.default && messages.default.default && messages.default.default[key]) {

        return ( messages.default.default[key]);
    }


    return (getDefaultMessage(type, key));

}

export const loadMessages = (url, cb) => {

        axios({
            method: "get",
            url: url
        })
            .then(response => {
                    cb(null, response.data);
                }
            )
            .catch((error) => {
                cb(error, null);
            });
}

export const getDv = (rut) => {
    const chars = rut.split('');
    const suma = 11 - chars.reduceRight((acc, elem, index) => {
        return (acc + elem * (2 + (rut.length - 1 - index) % 6)) % 11;
    }, 0);
    switch (suma) {
        case 10:
            return 'K';
        case 11:
            return 0;
        default:
            return suma;
    }

};


export const useCaptcha = () => {
    useEffect(() => {
        if (process.env.REACT_APP_RECAPTCHA_ENABLED) {

            const script = document.createElement('script');

            script.src = 'https://www.google.com/recaptcha/enterprise.js?render='+process.env.REACT_APP_RECAPTCHA_SITE;
            script.async = true;

            document.body.appendChild(script);

            return () => {
                document.body.removeChild(script);
            }
        }
    }, []);
};

