import React from "react";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import {makeStyles} from "@material-ui/core/styles";
import {getMessage} from "../../util";

const useStyles = makeStyles(theme => ({
    paper: {
        marginTop: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    paragraph: {
        margin: theme.spacing(2)
    }
}));

const VideoConference = (props) => {
    const classes = useStyles();
    return (

        <Container className={classes.paper}>
            <Typography>
                {getMessage(props.meeting.meeting_type,'videoconference_message',props.messages)}
            </Typography>
            <Button variant="contained" href={props.url} color="primary" className={classes.paragraph}  target="_blank" rel="noopener">
                {getMessage(props.meeting.meeting_type,'videoconference_button',props.messages)}
            </Button>
        </Container>
    );
}

export default VideoConference;
