import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import config from "../../config.json";
import {getMessage} from "../../util";
import TableContainer from "@material-ui/core/TableContainer";
import {Accordion, AccordionDetails, AccordionSummary, Paper, TableCell} from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

const useStyles = makeStyles(theme => ({
        paper: {
            marginTop: theme.spacing(4),
            marginBottom: theme.spacing(2),
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'left',
        },
        voted: {
            marginTop: theme.spacing(4),
        },
        paragraph: {
            margin: theme.spacing(2)
        },
        cardGrid: {
            paddingTop: theme.spacing(8),
            paddingBottom: theme.spacing(8),
        },
        card: {
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
        },
        cardActions: {
            justifyContent: 'center',
        },
        cardContent: {
            flexGrow: 1,
        },
        option: {
            margin: theme.spacing(3),
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',

        },
        label: {
            alignItems: 'left',
        },
        textField: {
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(1),
            width: '25ch',
        },
        number: {
            fontFamily: "monospace"
        },
        holderList: {
            flexDirection: "column"
        },
        marginTop: {
            marginTop: theme.spacing(2)
        }

    }
));

const VotedDistributed = (props) => {
    const classes = useStyles();

    const totals = props.answers.reduce((acc, answer) => {
        Object.keys(answer.answers[props.question.slug].blanks).forEach(share => {
            answer.answers[props.question.slug].options.forEach(option => {
                acc[share].options[option._id] += option.votes[share].shares ? option.votes[share].shares : 0;
            });
            acc[share].blanks += answer.answers[props.question.slug].blanks[share].shares;

        });
        return (acc);

    }, Object.keys(props.question.blanks).reduce((acc, share) => {
        acc[share] = {
            options: props.question.options.reduce((acc, option) => {
                acc[option._id] = 0;
                return (acc);
            }, {}),
            blanks: 0
        };
        return (acc);
    }, {}));


    const question_shares = Object.keys(props.question.blanks)

    const representative_question = Object.keys(props.shares).filter((x) => {
        if (question_shares.indexOf(x) === -1)
            return false;
        else
            return true;
    });

    return (
    <Container className={classes.voted}>
        <Typography variant={"h6"}>
            {getMessage(props.meeting.meeting_type, 'voted_message', props.messages)}
        </Typography>

            <Typography className={classes.paragraph}
                        variant={"h6"}>{getMessage(props.meeting.meeting_type, 'resume_total', props.messages)}</Typography>

            <TableContainer component={Paper}>
                <Table className={classes.table} size="small" aria-label="a dense table">
                    <TableHead>
                        <TableRow>
                            <TableCell></TableCell>
                            {representative_question.map(s => (
                                <TableCell key={s} align="right">{props.question.blanks[s].name}</TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {props.question.options.map(option => (
                            <TableRow key={option._id}>
                                <TableCell>
                                    {option.name}

                                </TableCell>
                                {representative_question.map(s => (
                                    <TableCell key={s}
                                               align="right">{totals[s].options[option._id].toLocaleString(navigator.language, {
                                        maximumFractionDigits: config.precision
                                    })}</TableCell>
                                ))}
                            </TableRow>
                        ))}
                        {props.question.max_marks !== props.question.min_marks && <TableRow>
                            <TableCell>
                                <b>{getMessage(props.meeting.meeting_type, 'blanks', props.messages)}</b>

                            </TableCell>
                            {representative_question.map(s => (
                                <TableCell key={s}
                                           align="right">{totals[s].blanks.toLocaleString(navigator.language, {
                                    maximumFractionDigits: config.precision
                                })}</TableCell>
                            ))}
                        </TableRow>}
                    </TableBody>
                </Table>
            </TableContainer>

            {props.holders.length > 1 && <Accordion>

                <AccordionSummary
                    expandIcon={<ExpandMoreIcon/>}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    className={classes.marginTop}
                >
                    <Typography>{getMessage(props.meeting.meeting_type, 'resume_detail', props.messages)}</Typography>
                </AccordionSummary>
                <AccordionDetails className={classes.holderList}>

                    {props.answers.map(answer => (
                        <TableContainer key={answer.identity} component={Paper} className={classes.marginTop}>
                            <Table className={classes.table} size="small" aria-label="a dense table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell><b>{answer.name} ({answer.identity})</b></TableCell>
                                        {Object.keys(answer.answers[props.question.slug].blanks).map(s => (
                                            <TableCell key={s}
                                                       align="right">{answer.answers[props.question.slug].blanks[s].name}</TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {answer.answers[props.question.slug].options.map(option => (
                                        <TableRow key={option._id}>
                                            <TableCell>
                                                {option.name}

                                            </TableCell>
                                            {Object.keys(answer.answers[props.question.slug].blanks).map(s => (
                                                <TableCell key={s}
                                                           align="right">{option.votes[s].shares.toLocaleString(navigator.language, {
                                                    maximumFractionDigits: config.precision
                                                })}</TableCell>
                                            ))}
                                        </TableRow>
                                    ))}
                                    {props.question.max_marks !== props.question.min_marks && <TableRow>

                                        <TableCell>
                                            <b>{getMessage(props.meeting.meeting_type, 'blanks', props.messages)}</b>

                                        </TableCell>
                                        {Object.keys(answer.answers[props.question.slug].blanks).map(s => (
                                            <TableCell key={s}
                                                       align="right">{answer.answers[props.question.slug].blanks[s].shares.toLocaleString(navigator.language, {
                                                maximumFractionDigits: config.precision
                                            })}</TableCell>
                                        ))}
                                    </TableRow>}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    ))}
                </AccordionDetails>
            </Accordion>}


        </Container>
    )
}

export default VotedDistributed;