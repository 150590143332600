import logo from "../images/logo.svg";
import React, {Fragment} from "react";
import {makeStyles} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import HTTPClient from "../HTTPClient";

import config from "../config.json";
import {getLanguages, getMessage} from "../util";
import {MenuItem, Select} from "@material-ui/core";

const useStyles = makeStyles(theme => ({
        paper: {
            marginTop: theme.spacing(4),
            marginBottom: theme.spacing(2),
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        },
        logo: {
            width: '100%',
            height: 'auto',
            maxWidth: '200px',
            display: 'block'
        },
        logo_small: {
            width: '20px',
            float: 'right'
        },
        number: {
            fontFamily: "Montserrat"
        }

    }
));


const Header = (props) => {
    const classes = useStyles();


    const total = Object.keys(props.shares).map(k => {
        if (props.meeting.shares[k].attendance) {
            return (props.meeting.shares[k].rights * props.shares[k].shares);
        } else {
            return (0);
        }
    }).reduce((acc, elem) => {
        return (acc + elem);
    }, 0);


    const logout = () => {

        HTTPClient({
            method: "get",
            url:
                process.env.REACT_APP_API_URL +
                "/logout/" + props.meeting.slug
        })
            .then(() => {
                localStorage.removeItem('evoting-front-user');
                window.location = '/login/' + props.meeting.slug;

            })
            .catch(error => {
                console.log('cannot logout', error);
                alert(getMessage(props.meeting.meeting_type, 'cannot_close_session', props.messages));
            })

    };


    return (


        <Grid item xs={12} className={classes.paper}>
            <Grid container>
                <Grid item xs={12} sm={4}>
                    <img src={props.logo ? props.logo : logo} alt="logo" className={classes.logo}/>

                </Grid>
                <Grid item xs={8} sm={5}>
                    <Typography>
                        {props.user.data.run} {props.user.data.name} <br/>
                    </Typography>

                    {Object.keys(props.shares).length > 0 && (
                        <Fragment>
                            <Typography
                                variant="h5">{getMessage(props.meeting.meeting_type, 'total_representative_shares', props.messages)}: <span
                                className={classes.number}>{total.toLocaleString(navigator.language, {
                                maximumFractionDigits: config.precision, minimumFractionDigits: 0
                            })} </span>
                            </Typography>

                        </Fragment>
                    )}

                </Grid>
                <Grid item xs={4} sm={2} align="right">

                    {(props.meeting && props.hide_logout !== true) && (
                        <Button variant="outlined"
                                onClick={logout}>{getMessage(props.meeting.meeting_type, 'logout', props.messages)}</Button>
                    )}
                    <br/>

                    {getLanguages(props.messages).length > 1 && <Select value={props.messages?props.messages.lang:getLanguages(props.messages)[0][0]}
                            onChange={props.resetLanguage}>
                        {getLanguages(props.messages).map(l => (
                            <MenuItem key={l[0]} value={l[0]}>{l[1]}</MenuItem>
                        ))}
                    </Select>}
                </Grid>

            </Grid>

        </Grid>

    );
};

export default Header;
