import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import {HowToVote} from "@material-ui/icons";
import ListItemText from "@material-ui/core/ListItemText";
import React, {useState} from "react";
import Chip from "@material-ui/core/Chip";
import {getMessage} from "../../util";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Typography from "@material-ui/core/Typography";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";

const NotOpen = (props) => {
    return (<Dialog
        open={true}
        scroll="body"
        onClose={props.handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
    >

        {props.state === 'ready' && <DialogTitle id="alert-dialog-title">
            {getMessage(props.meeting.meeting_type,'external_question_ready_title',props.messages)}
        </DialogTitle>}
        {props.state === 'finished' && <DialogTitle id="alert-dialog-title">
            {getMessage(props.meeting.meeting_type,'external_question_finished_title',props.messages)}
        </DialogTitle>}


        <DialogContent>


            {props.state === 'ready' && <Typography variant={"h6"} align={"center"}>
                {getMessage(props.meeting.meeting_type,'external_question_ready',props.messages)}
            </Typography>}

            {props.state === 'finished' && <Typography variant={"h6"} align={"center"}>
                {getMessage(props.meeting.meeting_type,'external_question_finished',props.messages)}

            </Typography>}

        </DialogContent>

        <DialogActions>
            <Button onClick={props.handleClose} autoFocus>
                {getMessage(props.meeting.meeting_type,'close',props.messages)}
            </Button>
        </DialogActions>


    </Dialog>);
}

const ExternalQuestionItem = (props) => {

    const [notOpen, setNotOpen] = useState(false);

    const getState = (state) => {
        switch (state) {
            case 'open':
                return (<Chip label={getMessage(props.meeting.meeting_type,'question_open',props.messages)}/>);
            case 'finished':
                return (<Chip label={getMessage(props.meeting.meeting_type,'question_finished',props.messages)}/>);
            default:
                return (<Chip label={getMessage(props.meeting.meeting_type,'question_ready',props.messages)}/>);

        }
    }
    function ListItemLink(props) {
        return <ListItem button component="a" {...props} />;
    }

    return (
        <ListItem button>
            {notOpen && <NotOpen meeting={props.meeting} state={props.question.state} handleClose={() => setNotOpen(false)}/>}
            <ListItemIcon>
                <HowToVote/>
            </ListItemIcon>
            {props.question.state === 'open'?(
            <ListItemLink href={props.question.url} target="_blank" rel="noopener">

                <ListItemText
                    primary={props.question.name}
                />
                <ListItemIcon>
                    {getState(props.question.state)}
                </ListItemIcon>
            </ListItemLink>
            ):(
            <ListItemLink onClick={() => setNotOpen(true)}>

                <ListItemText
                    primary={props.question.name}
                />
                <ListItemIcon>
                    {getState(props.question.state)}
                </ListItemIcon>
            </ListItemLink>
            )}

        </ListItem>
    )
}

export default ExternalQuestionItem;